
import { Box, Checkbox, Flex, Input, Text } from "@chakra-ui/react";
import { Controller, FieldValues, Path, useFormContext } from "react-hook-form";
import FormTitle from "../../layout/FormTitle";
import MinMaxDate from "../../inputs/MinMaxDate";
import { DateTime } from "luxon";

const DateRangeForm = () => {
    return <Flex flexDir='column'>
        <Flex mt={4} flexDir='column'>
            <FormTitle title='Min date' />
            <MinMaxDate title='Select minimum date' name='minDate' defaultValue='2000-01-01' />
        </Flex>
        <Flex mt={4} flexDir='column'>
            <FormTitle title='Max date' />
            <MinMaxDate title='Select maximum date' name='maxDate' defaultValue={DateTime.now().toFormat('yyyy-MM-dd')} />
        </Flex>
    </Flex>;
}

export default DateRangeForm;