import { Box, Flex } from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useAppSelector } from "@hooks";
import { reportPageValidator } from "common/validators";
import { FormProvider, useForm } from "react-hook-form";
import AutoSave from "src/blueprint/pages/editor/components/AutoSave";
import { useCanvas } from "src/blueprint/pages/editor/EditorContext";
import { useSetComponentConfig } from "src/hooks/useSetComponentConfig";
import { selectComponentById } from "src/redux/features/blueprint/bluePrintSlice";
import { deepMerge } from "src/utils/theme";
import { z } from "zod";
import { FormAccordion, FormAccordionItem } from "../../Accordion";
import ColorForm from "../../forms/ColorForm";
import PalletForm from "../../forms/PalletForm";
import PositionForm from "../../forms/PositionForm";

const formValidator = reportPageValidator.baseComponent.extend({
    properties: z.object({
        style: z.object({
        }),
        buttonStyle: z.object({
            color: z.string().optional(),
            "&:hover": z.object({
                borderColor: z.string().optional(),
                backgroundColor: z.string().optional(),
            }).optional(),
            backgroundColor: z.string().optional(),
            borderColor: z.string().optional(),
        }).optional(),
        pickerStyle: z.object({
            color: z.string().optional(),
        }).optional(),
        omitPickerStyle: z.object({
            color: z.string().optional(),
        }).optional(),
        submitButtonStyle: z.object({
            color: z.string().optional(),
            "&:hover": z.object({
                backgroundColor: z.string().optional(),
            }).optional(),
            backgroundColor: z.string().optional(),
        }).optional(),
        popoverContentStyle: z.object({
            color: z.string().optional(),
            borderColor: z.string().optional(),
        }).optional(),
        staticRangeLabelStyle: z.object({
            color: z.string().optional(),
        }).optional(),
        rangeColors: z.array(z.string()).optional(),
    })
})

type FormData = z.infer<typeof formValidator>

const defaultValues: Pick<FormData, 'properties'> = {
    properties: {
        style: {
        },
        buttonStyle: {
            color: "#2b3674",
            "&:hover": {
                borderColor: "transparent",
                backgroundColor: "white"
            },
            borderColor: "transparent",
            backgroundColor: "white"
        },
        rangeColors: [
            "rgb(225, 234, 243)"
        ],
        pickerStyle: {
            color: "black"
        },
        omitPickerStyle: {
            color: "black"
        },
        submitButtonStyle: {
            color: "white",
            "&:hover": {
                backgroundColor: "#2b3674"
            },
            backgroundColor: "#2b3674"
        },
        popoverContentStyle: {
            color: "black",
            borderColor: "#2b3674"
        },
        staticRangeLabelStyle: {
            color: "black"
        }
    }
}

const DateRangeStyle: React.FC = () => {
    const { state: { activeSelectedComponentId } } = useCanvas();
    const componentToEdit = useAppSelector(selectComponentById(activeSelectedComponentId));
    const setComponentConfig = useSetComponentConfig();

    const methods = useForm<FormData>({
        defaultValues: defaultValues,
        values: componentToEdit as any,
        resolver: zodResolver(formValidator),
        mode: 'onBlur',
    })

    const onSubmit = async () => {
        const values = methods.getValues();
        setComponentConfig(values as any);
    }

    return <Box>
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <PositionForm />
                <PalletForm<FormData> title="Range Colors" name="properties.rangeColors" />
                <FormAccordion>
                    <FormAccordionItem title="Control">
                        <Flex flexDir='column'>
                            <ColorForm<FormData> title='Color' name={"properties.buttonStyle.color"} />
                            <ColorForm<FormData> title='Background Color' name={"properties.buttonStyle.backgroundColor"} />
                            <ColorForm<FormData> title='Border Color' name={"properties.buttonStyle.backgroundColor"} />
                            <ColorForm<FormData> title='Hover Color' name={"properties.buttonStyle.&:hover.backgroundColor"} />
                            <ColorForm<FormData> title='Hover Background Color' name={"properties.buttonStyle.&:hover.backgroundColor"} />
                        </Flex>
                    </FormAccordionItem>
                    <FormAccordionItem title="Picker Style">
                        <Flex flexDir='column'>
                            <ColorForm<FormData> name={'properties.pickerStyle.color'} />
                        </Flex>
                    </FormAccordionItem>
                    <FormAccordionItem title="Omit Picker Style">
                        <Flex flexDir='column'>
                            <ColorForm<FormData> name={'properties.omitPickerStyle.color'} />
                        </Flex>
                    </FormAccordionItem>
                    <FormAccordionItem title="Submit Button Style">
                        <Flex flexDir='column'>
                            <ColorForm<FormData> title='Color' name={"properties.submitButtonStyle.color"} />
                            <ColorForm<FormData> title='Background Color' name={"properties.submitButtonStyle.backgroundColor"} />
                            <ColorForm<FormData> title='Hover Color' name={"properties.submitButtonStyle.&:hover.backgroundColor"} />
                        </Flex>
                    </FormAccordionItem>
                    <FormAccordionItem title="Popover Content Style">
                        <Flex flexDir='column'>
                            <ColorForm<FormData> title='Color' name={"properties.popoverContentStyle.color"} />
                            <ColorForm<FormData> title='Border Color' name={"properties.popoverContentStyle.borderColor"} />
                        </Flex>
                    </FormAccordionItem>
                    <FormAccordionItem title="Static Range Label Style">
                        <Flex flexDir='column'>
                            <ColorForm<FormData> name={'properties.staticRangeLabelStyle.color'} />
                        </Flex>
                    </FormAccordionItem>
                </FormAccordion>
                <input hidden type="submit" />
                <AutoSave onSubmit={onSubmit} defaultValues={deepMerge(defaultValues, componentToEdit)} />
            </form>
        </FormProvider>
    </Box>
}

export default DateRangeStyle;