// src/auth/AuthProvider.tsx
import React, { createContext, useState, ReactNode, useEffect, useCallback } from 'react';
import { useLazyGetUserMeQuery } from '@api';
import { User } from './auth/getUserInfo';
import * as authService from 'src/auth/authService';
import { toast } from 'react-toastify';

interface AuthContextProps {
    user: User | null;
    isAuthenticated: boolean;
    handleCallback: (code: string) => Promise<void>;
    refetchUser?: () => Promise<void>;
}

export const AuthContext = createContext<AuthContextProps>({
    isAuthenticated: false,
    user: null,
    handleCallback: async () => {}
});

const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [user, setUser] = useState<User | null>(authService.getUserFromStorage());

    const [fetchMe] = useLazyGetUserMeQuery(undefined);

    const handleCallback = async (code: string) => {
        try {
            await authService.handleCallback(code);
            const userInfo = await fetchMe({}).unwrap();
            setUser(userInfo);
            authService.saveUserToStorage(userInfo);
        } catch (error) {
            toast.error('An error occurred while logging in.');
            console.error('Error during token exchange', error);
        }
    };

    const refetchUser = useCallback(async () => {
        const userInfo = await fetchMe({}).unwrap();
        setUser(userInfo);
        authService.saveUserToStorage(userInfo);
    }, [fetchMe]);

    useEffect(() => {
        if (user && authService.getTokenResponseFromStorage()) {
            refetchUser();
        }
    }, [user, refetchUser]);

    return (
        <AuthContext.Provider
            value={{ user, handleCallback, isAuthenticated: !!user, refetchUser }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;
