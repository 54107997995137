const defaultPieChart = {
    properties: {
        style: {
            width: '100%',
            height: '100%',
            padding: '1rem',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
            borderRadius: '20px',
            backgroundColor: 'white'
        },
        component: {
            legend: {
                align: 'right',
                height: 20,
                layout: 'vertical',
                symbol: 'square',
                fontSize: 14,
                itemsAlign: 'left',
                verticalAlign: 'middle'
            },
            pallet: [
                '#2b3674',
                'rgb(255, 0, 6)',
                'rgb(97, 97, 97)',
                'rgb(26, 115, 232)',
                '#2f1ae8',
                '#343a40',
                '#bbabd6',
                '#7CB342'
            ],
            showLabel: true,
            innerRadius: 30,
            outerRadius: 110,
            showInnerLabels: false
        },
        labelStyle: {
            left: '30%',
            color: 'white',
            display: 'none',
            fontSize: '16px',
            textAlign: 'left',
            fontWeight: '400',
            lineHeight: '20px'
        }
    }
};
export default defaultPieChart;
