import {
    Box,
    Button,
    Popover,
    PopoverContent,
    PopoverTrigger,
    Portal
} from '@chakra-ui/react';

import { sk } from 'date-fns/locale';
import { DateRange, DateRangePicker, createStaticRanges } from 'react-date-range';

import { BlueprintDatePickerProperties } from '@types';
import { addDays, endOfYesterday, format, isFuture, isToday } from 'date-fns';
import { DateTime } from 'luxon';
import { useMemo, useState } from 'react';
import { staticRanges } from './staticRanges';
import { CalendarIcon, ChevronDownIcon } from '@chakra-ui/icons';

interface IDateRange {
    startDate: Date;
    endDate: Date;
    key: string;
}

interface Props {
    value: IDateRange;
    onChange: (dateRange: IDateRange) => void;
    properties?: BlueprintDatePickerProperties;
    minDate?: EpochTimeStamp;
    maxDate?: EpochTimeStamp;
}

export default function DatePicker2({
    onChange,
    value,
    properties,
    minDate,
    maxDate
}: Props) {
    let minimumDate = new Date();
    let maximumDate = new Date();
    if (minDate && maxDate) {
        minimumDate = new Date(minDate);
        maximumDate = new Date(maxDate);
        if (maximumDate < minimumDate) {
            minimumDate = addDays(new Date(), -7);
            maximumDate = endOfYesterday();
        }
    }
    if (!Boolean(minDate) && Boolean(maxDate)) {
        maximumDate = endOfYesterday();
        minimumDate = new Date(0);
    }
    if (Boolean(minDate) && !Boolean(maxDate)) {
        minimumDate = new Date(minDate ?? 0);
        maximumDate = endOfYesterday();
    }

    const [internalValue, setInternalValue] = useState({
        startDate: value?.startDate ?? addDays(new Date(), -7),
        endDate: value?.endDate ?? endOfYesterday(),
        key: 'selection'
    });

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleSelect = (ranges: any) => {
        setInternalValue(ranges.selection);
    };

    const value_ = value.startDate && value.endDate ? value : internalValue;

    const startDateString = DateTime.fromJSDate(value_.startDate).toFormat('dd.MM.yyyy');
    const endDateString = DateTime.fromJSDate(value_.endDate).toFormat('dd.MM.yyyy');

    const filteredStaticRanges = useMemo(() => {
        const min = addDays(new Date(minimumDate ?? '2023-01-01'), -1);
        const max = maximumDate ? addDays(new Date(maximumDate), 1) : endOfYesterday();
        if (!Boolean(minDate) && !Boolean(maxDate)) {
            return staticRanges;
        }
        let ranges = staticRanges.filter((r: any) => {
            const range = r.range();
            const rangeStart = range.startDate;
            const rangeEnd = range.endDate;
            return (
                rangeStart.getTime() >= min.getTime() &&
                rangeEnd.getTime() <= max.getTime()
            );
        });
        if (ranges.length < 5) {
            // if there are less than 5 ranges, add quartal ranges Q1, Q2, Q3, Q4 to today's date
            const currentYear = new Date().getFullYear();
            const previousYear = currentYear - 1;
            const quarterRanges = createStaticRanges([
                // add last year's Q1, Q2, Q3, Q4
                {
                    label: `Q1 ${previousYear.toString()}`,
                    range: () => ({
                        startDate: new Date(previousYear, 0, 1),
                        endDate: new Date(previousYear, 2, 31)
                    }),
                    hasCustomRendering: true
                },
                {
                    label: `Q2 ${previousYear.toString()}`,
                    range: () => ({
                        startDate: new Date(previousYear, 3, 1),
                        endDate: new Date(previousYear, 5, 30)
                    }),
                    hasCustomRendering: true
                },
                {
                    label: `Q3 ${previousYear.toString()}`,
                    range: () => ({
                        startDate: new Date(previousYear, 6, 1),
                        endDate: new Date(previousYear, 8, 30)
                    }),
                    hasCustomRendering: true
                },
                {
                    label: `Q4 ${previousYear.toString()}`,
                    range: () => ({
                        startDate: new Date(previousYear, 9, 1),
                        endDate: new Date(previousYear, 11, 31)
                    }),
                    hasCustomRendering: true
                },
                // add this year's Q1, Q2, Q3, Q4
                {
                    label: `Q1 ${currentYear.toString()}`,
                    range: () => ({
                        startDate: new Date(currentYear, 0, 1),
                        endDate: new Date(currentYear, 2, 31)
                    }),
                    hasCustomRendering: true
                },
                {
                    label: `Q2 ${currentYear.toString()}`,
                    range: () => ({
                        startDate: new Date(currentYear, 3, 1),
                        endDate: new Date(currentYear, 5, 30)
                    }),
                    hasCustomRendering: true
                },
                {
                    label: `Q3 ${currentYear.toString()}`,
                    range: () => ({
                        startDate: new Date(currentYear, 6, 1),
                        endDate: new Date(currentYear, 8, 30)
                    }),
                    hasCustomRendering: true
                },
                {
                    label: `Q4 ${currentYear.toString()}`,
                    range: () => ({
                        startDate: new Date(currentYear, 9, 1),
                        endDate: new Date(currentYear, 11, 31)
                    }),
                    hasCustomRendering: true
                }
            ]).filter((r: any) => {
                const range = r.range();
                const rangeStart = range.startDate;
                const rangeEnd = range.endDate;
                return (
                    rangeStart.getTime() >= min.getTime() &&
                    rangeEnd.getTime() <= max.getTime()
                );
            });
            ranges = ranges.concat(quarterRanges);
        }

        return ranges;
    }, [minimumDate, maximumDate]);

    return (
        <Popover placement="bottom-end">
            {({ onClose }) => (
                <>
                    <PopoverTrigger>
                        <Button
                            leftIcon={
                                properties?.showCalendarIcon ? (
                                    <CalendarIcon style={properties?.iconStyle} />
                                ) : undefined
                            }
                            rightIcon={
                                properties?.showDropdownIndicator ? (
                                    <ChevronDownIcon h={6} w={6} />
                                ) : undefined
                            }
                            height="100%"
                            variant="datepicker"
                            data-testid="date-picker-button"
                            style={properties?.buttonStyle}
                        >
                            {!startDateString && properties?.text
                                ? properties.text
                                : `${startDateString} - ${endDateString}`}
                        </Button>
                    </PopoverTrigger>
                    <Portal>
                        <Box zIndex={99999} position="relative">
                            <PopoverContent
                                w="490px"
                                h="420px"
                                data-testid="date-picker-popover"
                                position="relative"
                            >
                                {filteredStaticRanges.length > 0 ? (
                                    <DateRangePicker
                                        locale={sk}
                                        staticRanges={filteredStaticRanges}
                                        inputRanges={[]}
                                        minDate={
                                            minDate
                                                ? new Date(minimumDate ?? '2023-01-01')
                                                : undefined
                                        }
                                        maxDate={
                                            maxDate
                                                ? maximumDate
                                                    ? new Date(maximumDate)
                                                    : endOfYesterday()
                                                : undefined
                                        }
                                        ranges={[internalValue]}
                                        onChange={handleSelect}
                                        rangeColors={properties?.rangeColors}
                                        renderStaticRangeLabel={(range) => (
                                            <span
                                                style={properties?.staticRangeLabelStyle}
                                            >
                                                {range?.label}
                                            </span>
                                        )}
                                        dayContentRenderer={(day) => (
                                            <span
                                                data-testid={`date-picker-day-${format(day, 'd.M.yyyy')}`}
                                                style={
                                                    isFuture(day) || isToday(day)
                                                        ? properties?.omitPickerStyle
                                                        : properties?.pickerStyle
                                                }
                                            >
                                                {format(day, 'd')}
                                            </span>
                                        )}
                                    />
                                ) : (
                                    <DateRange
                                        locale={sk}
                                        minDate={new Date(minDate ?? '2023-01-01')}
                                        maxDate={
                                            maxDate ? new Date(maxDate) : endOfYesterday()
                                        }
                                        ranges={[internalValue]}
                                        onChange={handleSelect}
                                        rangeColors={properties?.rangeColors}
                                        data-testid="date-range-picker"
                                        dayContentRenderer={(day) => (
                                            <span
                                                data-testid={`date-picker-day-${format(day, 'd.M.yyyy')}`}
                                                style={
                                                    isFuture(day) || isToday(day)
                                                        ? properties?.omitPickerStyle
                                                        : properties?.pickerStyle
                                                }
                                            >
                                                {format(day, 'd')}
                                            </span>
                                        )}
                                    />
                                )}
                                <Box position="absolute" bottom={'1rem'} right="1.5rem">
                                    <Button
                                        variant="darkBrand"
                                        style={properties?.submitButtonStyle}
                                        onClick={() => {
                                            onChange(internalValue);
                                            onClose();
                                        }}
                                    >
                                        Submit
                                    </Button>
                                </Box>
                            </PopoverContent>
                        </Box>
                    </Portal>
                </>
            )}
        </Popover>
    );
}
