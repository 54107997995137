import { FormControl, Input, InputGroup, InputLeftElement, InputProps, Text, Tooltip } from "@chakra-ui/react";
import { forwardRef } from "react";
import { FieldError } from "react-hook-form";

interface FormNumberInputProps extends InputProps {
    label: string;
    error: FieldError | undefined;
}

const FormNumberInput = forwardRef<HTMLInputElement, FormNumberInputProps>(({ label, error, value, ...props }, ref) => {
    const widthBasedOnLabel = label.length <= 4 ? 32 : label.length * 8;
    const errorEmptyValue = value === '' ? 'Field is required' : error?.message;
    return (
        <FormControl isInvalid={Boolean(error?.message)}>
            <Tooltip label={errorEmptyValue} hasArrow isOpen={Boolean(error?.message)} placement='top' >
                <InputGroup size='xs'>
                    <InputLeftElement w={`${widthBasedOnLabel}px`}>
                        <Text fontSize='xs' color='#929292'>{label}</Text>
                    </InputLeftElement>
                    <Input variant='editor' style={{ paddingInlineStart: widthBasedOnLabel }} ref={ref} value={value} {...props} />
                </InputGroup>
            </Tooltip>
        </FormControl>
    );
});

export default FormNumberInput;