import { Box, Flex, FormControl, FormErrorMessage, Grid, GridItem, Switch, Text } from '@chakra-ui/react';
import { SourceIcon } from '@tasklogy/zircon-ui-components';
import { DataSourceIdentifier } from 'common/enums';
import { ReportPage } from 'common/types';
import React from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { ClientConnectedSource } from 'src/redux/types/api';
import { PathNames } from 'src/router/router';
import { Report } from 'src/redux/types/api';
import { is } from 'date-fns/locale';

interface SidebarProps {
    pages: ReportPage[];
    clientConnectedSources: ClientConnectedSource[];
    clientId: number;
    template: Report;
}

const Sidebar: React.FC<SidebarProps> = ({ pages, clientId, template }) => {
    const { formState, control, setValue, trigger } = useFormContext();
    const { errors } = formState;
    const formConnectedSources = useWatch({
        control,
        name: 'connectedSources'
    });
    const navigate = useNavigate();
    const params = useParams();

    // Remove connected sources from form when page is removed, if they are not used in any other page
    const handleChangeSelectedDataSources = (formPages: number[]) => {
        // Extract unique data sources from all pages
        const allPagesDataSources = [
            ...new Set(pages.flatMap((page) => page.usedSources))
        ];

        // Filter pages that are in formPages
        const selectedPages = pages.filter((page) => formPages.includes(page.id));

        // Extract unique data sources from selected pages
        const unionDataSources = [
            ...new Set(selectedPages.flatMap((page) => page.usedSources))
        ];

        // Map all data sources to an object indicating if they are selected
        const dataSources = allPagesDataSources.map(
            (dataSource: DataSourceIdentifier) => ({
                dataSource,
                isSelected: unionDataSources.includes(dataSource)
            })
        );

        // Filter selected data sources
        const selectedConnectedSources = dataSources
            .filter((cs) => cs.isSelected)
            .map((cs) => cs.dataSource);

        // Reduce form connected sources to only include selected connected sources
        const newConnectedSources = Object.keys(formConnectedSources).reduce(
            (acc: Record<string, number>, key: string) => {
                if (selectedConnectedSources.includes(key as DataSourceIdentifier)) {
                    acc[key] = formConnectedSources[key];
                }
                return acc;
            },
            {}
        );

        setValue('connectedSources', newConnectedSources, {
            shouldValidate: true
        });
    };

    return (
        <Box mr="1rem">
            <Text fontSize="lg" fontWeight="bold" mb="1rem">
                Select pages:
            </Text>
            <Controller
                control={control}
                name="pages"
                render={({ field: { value, onChange } }) => (
                    <Flex dir='column'>
                        <FormControl isInvalid={Boolean(errors.pages)} >
                            <Grid
                                templateRows={`repeat(${pages.length}, 1fr)`}
                                templateColumns='repeat(3, 1fr)'
                                rowGap={2}
                            >
                                {pages?.map((page) => {
                                    const url = generatePath(
                                        PathNames.CLIENT_CREATE_REPORT_SIMPLE_TEMPLATE_REPORT_VIEW,
                                        {
                                            clientId: clientId.toString(),
                                            templateId: template.id.toString(),
                                            reportSlug: template.slug,
                                            viewSlug: page.slug
                                        }
                                    );

                                    const isPageViewed = params?.viewSlug === page.slug;

                                    return (
                                        <React.Fragment key={page.id}>
                                            <GridItem colSpan={1} filter={
                                                value?.includes(page.id) ? 'none' : 'grayscale(1)'
                                            }

                                            display='flex'
                                            alignItems='center'
                                            >
                                                <SourceIcon
                                                    selectedIcons={page.usedSources}
                                                />
                                            </GridItem>
                                            <GridItem colSpan={1}
                                                display='flex'
                                                alignItems='center'
                                                px='0.5rem'
                                                >
                                                <Text
                                                    fontSize={['7px', '9px', '11px']}
                                                    cursor="pointer"
                                                    onClick={() => navigate(url)}
                                                    textDecor="underline"
                                                    color={
                                                        isPageViewed ? 'brand.primary' : 'inherit'
                                                    }
                                                    fontWeight={isPageViewed ? 'bold' : 'normal'}
                                                    flexWrap='nowrap'
                                                >
                                                    {page.displayName}
                                                </Text>
                                            </GridItem>
                                            <GridItem colSpan={1} 
                                                display='flex'
                                                alignItems='center'>
                                                <Switch
                                                    isChecked={value?.includes(page.id)}
                                                    colorScheme="green"
                                                    onChange={() => {
                                                        if (value?.includes(page.id)) {
                                                            onChange(
                                                                value.filter(
                                                                    (id: number) => id !== page.id
                                                                )
                                                            );
                                                            handleChangeSelectedDataSources(
                                                                value.filter(
                                                                    (id: number) => id !== page.id
                                                                )
                                                            );
                                                        } else {
                                                            onChange([...value, page.id]);
                                                            trigger();
                                                        }
                                                    }}
                                                />
                                            </GridItem>
                                        </React.Fragment>
                                    );
                                })}
                            </Grid>
                            <FormErrorMessage>
                                {errors.pages?.message?.toString()}
                            </FormErrorMessage>
                        </FormControl>
                    </Flex>
                )
                }
            />
        </Box >
    );
};

export default Sidebar;
