import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const unstyled = defineStyle({
    tab: {
        borderBottomColor: '#E2E8F0',
        borderRadius: '5px 5px 0px 0px',
        borderWidth: 2,
        color: '#4B4C4D',
        _selected: {
            color: '#2B6CB0'
        },
        _focus: {
            outline: 'none'
        }
    }
});

const enclosed = defineStyle((props) => {
    const { colorScheme: c } = props;
    return {
        tab: {
            backgroundColor: '#EDEDED',
            borderColor: 'transparent',
            borderBottomColor: `${c}.700`,
            borderRadius: '0px 5px 0px 0px',
            borderWidth: 0,
            _selected: {
                backgroundColor: `${c}.700`,
                borderColor: `${c}.50`,
                color: `${c}.100`,
                borderRadius: '0px 0px 0px 0px',
                borderBottomColor: 'transparent'
            },
            _focus: {
                outline: 'none'
            },
            _hover: {
                borderColor: `${c}.700`,
                borderRadius: '5px 5px 0px 0px'
            },
            _first: {
                borderRadius: '5px 0px 0px 0px'
            },
            _last: {
                borderRadius: '0px 5px 0px 0px'
            }
        },
        tabpanel: {
            padding: 0,
            borderRadius: '0px 0px 5px 5px',
            borderWidth: 2,
            borderColor: `${c}.700`,
        }
    }
});

const primary = defineStyle(() => {
    return {
        tab: {
            backgroundColor: 'white',
            borderColor: 'transparent',
            borderBottomColor: `#E2E3E4`,
            borderWidth: 2,
            color: '#878790',
            borderRadius: '0px 0px 0px 0px',
            _selected: {
                backgroundColor: `white`,
                color: '#09BAC7',
                borderBottomColor: '#09BAC7'
            },
            _focus: {
                outline: 'none'
            },
            _hover: {
                borderColor: 'transparent'
            }
        },
        tabpanel: {
            px: 2
        }
    }
});

export const tabsTheme = defineStyleConfig({
    variants: { unstyled, enclosed, primary }
});
