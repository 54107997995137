import { useCreateClientMutation } from '@api';
import { getUserInfo } from '@auth';
import {
    Button,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { TeamIdParams } from '@router';
import { teamValidator } from 'common/validators';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { z } from 'zod';

interface CreateClientModalProps {
    isOpen: boolean;
    onClose: () => void;
}

type FormData = z.infer<typeof teamValidator.create>;

const CreateClientModal: React.FC<CreateClientModalProps> = ({ isOpen, onClose }) => {
    const params = useParams<TeamIdParams>();
    const user = getUserInfo();
    const [createClientDraftDocument] = useCreateClientMutation();
    const navigate = useNavigate();
    const {
        register,
        formState: { errors, isValid },
        getValues
    } = useForm<FormData>({
        resolver: zodResolver(teamValidator.create),
        mode: 'onChange',
        defaultValues: {
            name: ''
        }
    });

    const teamId = params.teamId ? +params.teamId : +user?.teamId!;

    const handleCreateClient = async () => {
        const values = getValues();
        try {
            const response = await createClientDraftDocument({
                name: values.name,
                teamId: teamId
            }).unwrap();
            navigate(`${response.id}`, { relative: 'route' });
            toast.success('Client was created successfully');
        } catch (error) {
            toast.error('Failed to create client');
        }
    };

    return (
        <Modal isCentered isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Create client</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <FormControl isInvalid={Boolean(errors.name)}>
                        <FormLabel>Client name</FormLabel>
                        <Input
                            type="text"
                            placeholder="Client name"
                            {...register('name', { required: true })}
                        />
                        <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
                    </FormControl>
                </ModalBody>

                <ModalFooter>
                    <Button variant={'outline'} mr={3} onClick={onClose}>
                        Cancel
                    </Button>
                    <Button
                        isDisabled={!isValid}
                        onClick={() => handleCreateClient()}
                        variant="solid"
                    >
                        Create client
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default CreateClientModal;
