import { Box } from '@chakra-ui/react';
import { CSSProperties } from 'react';

interface Props {
    properties: {
        style?: CSSProperties;
    };
}
export default function Rectangle({ properties }: Props) {
    return <Box w="100%" h="100%" pointerEvents='none' style={properties.style} />;
}
