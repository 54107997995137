const defaultTable = {
    properties: {
        cell: {
            color: 'black'
        },
        idCell: {
            color: 'black'
        },
        bodyStyle: {
            td: {
                padding: ''
            },
            tr: {
                _odd: {
                    backgroundColor: '#fff'
                },
                _even: {
                    backgroundColor: '#f4f7fe'
                },
                borderBottom: '1px solid',
                borderBottomColor: '#f4f7fe'
            }
        },
        cardStyle: {
            color: '#2b3674',
            borderColor: 'rgba(91, 103, 112, 0.8)',
            backgroundColor: 'white'
        },
        component: {
            paginationLayout: 'bottom'
        },
        headerCell: {
            color: '#2b3674'
        },
        labelStyle: {
            color: '#2b3674',
            borderColor: 'rgba(91, 103, 112, 0.8)',
            backgroundColor: '#f4f7fe'
        },
        footerStyle: {
            td: {
                color: 'black'
            },
            tr: {},
            cell: {
                color: 'black'
            }
        },
        headerStyle: {
            th: {
                color: 'red',
                borderColor: '#f4f7fe'
            },
            tr: {
                color: 'red',
                backgroundColor: '#f4f7fe'
            },
            cell: {
                color: 'red'
            }
        },
        emptyTableStyle: {
            td: {},
            tr: {},
            text: {}
        },
        paginationButtonsStyle: {
            stack: {
                gap: '4px',
                height: '1rem',
                margin: '0 0 10px'
            },
            rowsCount: {
                color: 'black'
            },
            backButton: {
                '&:hover': {
                    borderColor: '#3AD2ED'
                },
                backgroundColor: 'transparent'
            },
            forwardButton: {
                backgroundColor: 'transparent'
            },
            iconBackButton: {},
            iconForwardButton: {}
        }
    }
};
export default defaultTable;
