import { useEffect, useState } from "react";

export const getEditorCanvasScrollY = () => {
    return Number(document.getElementById('editor-canvas')?.scrollTop) === 0 ? 0 : Number(document.getElementById('editor-canvas')?.scrollTop) + 20;
}

export const getEditorCanvasScrollX = () => {
    return document.getElementById('editor-canvas')?.scrollLeft || 0;
}

export const getElementByIdOffsetY = (id: string) => {
    return document.getElementById(id)?.offsetTop || 0;
}

// hook to get canvas x, y scroll
export const useCanvasScroll = () => {
    const [scrollX, setScrollX] = useState(0);
    const [scrollY, setScrollY] = useState(0);
    const [canvasWidth, setCanvasWidth] = useState(0);
    const [canvasHeight, setCanvasHeight] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            setScrollX(document.getElementById('editor-canvas')?.scrollLeft || 0);
            setScrollY(document.getElementById('editor-canvas')?.scrollTop || 0);
            setCanvasWidth(document.getElementById('editor-canvas')?.clientWidth || 0);
            setCanvasHeight(document.getElementById('editor-canvas')?.clientHeight || 0);
        }
        document.getElementById('editor-canvas')?.addEventListener('scroll', handleScroll);
        return () => {
            document.getElementById('editor-canvas')?.removeEventListener('scroll', handleScroll);
        }
    }, []);

    return { scrollX, scrollY, canvasWidth, canvasHeight };
}