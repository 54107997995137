import { Flex } from "@chakra-ui/react"
import { Controller, FieldValues, Path, useFormContext } from "react-hook-form"
import AddIconButton from "../../AddIconButton"
import SquareColorPickerInput from "../inputs/SquareColorPickerInput"
import FormPropertyRow from "../layout/FormPropertyRow"
import FormTitle from "../layout/FormTitle"

const PalletForm = <T extends FieldValues>({ name, title }: { name: Path<T>, title?: string }) => {
    const methods = useFormContext<T>();

    return <Flex flexDir='column'>
        <Flex mt={4} alignItems='center' justifyContent={'space-between'}>
            <FormTitle title={title ?? ''} />
            <Controller
                control={methods.control}
                name={name}
                render={({ field }) => (
                    <AddIconButton onClick={() => field.onChange({ target: { value: [...field.value, 'transparent'] } })} />
                )}
            />
        </Flex>
        <FormPropertyRow>
            <Controller
                control={methods.control}
                name={name}
                render={({ field: { value, onChange } }) => (
                    <Flex width='100%' h='60px'>
                        {value.map((v: string, index: number) =>
                            <SquareColorPickerInput key={index} colorPickerProps={{ hideColorTypeBtns: true }} value={v} onChange={(e: any) => {
                                const index = value.indexOf(v)
                                const newValues = [...value]
                                newValues[index] = e.target.value
                                onChange({ target: { value: newValues } })
                            }}
                                onDeleteColor={() => {
                                    const index = value.indexOf(v)
                                    const newValues = [...value]
                                    newValues.splice(index, 1)
                                    onChange({ target: { value: newValues } })
                                }}
                                error={undefined} type="text" />
                        )}
                    </Flex>
                )}
            />
        </FormPropertyRow>
    </Flex>
}

export default PalletForm;