import { ThemeOverride, extendTheme } from '@chakra-ui/react';
import { avatarTheme } from './components/avatar';
import { badgeTheme } from './components/badge';
import { button } from './components/button';
import { cardTheme } from './components/card';
import { formTheme } from './components/form';
import { inputTheme } from './components/input';
import { link } from './components/link';
import { menuTheme } from './components/menu';
import { tableTheme } from './components/table';
import { text } from './components/text';
import { breakpoints } from './foundations/breakpoints';
import { tabsTheme } from './components/tabs';
import { tooltipTheme } from './components/tooltip';
import '@fontsource-variable/noto-sans'

export const theme: ThemeOverride = extendTheme({
    colors: {
        text: {
            primary: '#525A66',
            lightGray: 'rgba(74, 85, 100, 0.73)'
        },
        background: {
            primary: '#23E1EF'
        },
        brand: {
            primary: '#23E1EF'
        },
        icons: {
            edit: '#0FAAB6',
            delete: '#F13939',
            options: '#D0D7DD'
        },
        state: {
            success: '#00B341',
            error: '#FF0000',
            warning: '#FFA500',
            info: '#00BFFF'
        },
        default: {
            50: '#25E1EF',
            100: '#171D26'
        },
        error: {
            50: '#F24165',
            100: 'white'
        },
        success: {
            50: '#1BC30C',
            100: 'white'
        },
        additional: {
            50: '#137A81',
            100: 'white'
        },
        olive: {
            50: '#319795',
            100: 'white'
        },
        lake: {
            50: '#171d26',
            100: '#171d26',
            700: '#25E2EF'
        },
        blue: {
            50: '#E7FCFD',
            100: '#BDF6FA',
            200: '#92F0F7',
            300: '#67EAF4',
            400: '#3CE4F1',
            500: '#12DEED',
            600: '#0EB2BE',
            700: '#0B858E',
            800: '#07595F',
            900: '#042C2F'
        }
    },
    breakpoints,
    components: {
        Button: button,
        Avatar: avatarTheme,
        Menu: menuTheme,
        Text: text,
        Link: link,
        Card: cardTheme,
        Badge: badgeTheme,
        Table: tableTheme,
        Input: inputTheme,
        Form: formTheme,
        Tabs: tabsTheme,
        Tooltip: tooltipTheme
    },
    styles: {
        global: {
            body: {
                overflowX: 'hidden !important'
            }
        }
    },
    fonts: {
        body: 'Roboto, sans-serif',
        mono: 'Roboto, sans-serif'
    },
    textStyles: {
        h1: {
            fontFamily: 'Noto Sans, sans-serif',
            fontWeight: 600,
            fontSize: '11px',
            lineHeight: '13px',
        },
        h2: {
            fontFamily: 'Noto Sans, sans-serif',
            fontWeight: 400,
            fontSize: '9px',
            lineHeight: '11px',
            letterSpacing: '0.03em',
        },
        h3: {
            fontFamily: 'Noto Sans, sans-serif',
            fontWeight: 400,
            fontSize: '7px',
            lineHeight: '9px',
        },
        h4: {
            fontFamily: 'Noto Sans, sans-serif',
            fontWeight: 600,
            fontSize: '5px',
            lineHeight: '7px',
        },
        h5: {
            fontFamily: 'Noto Sans, sans-serif',
            fontWeight: 600,
            fontSize: '3px',
            lineHeight: '5px',
        }
    }
});
