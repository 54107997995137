import { useUploadReportImageMutation } from "@api";
import {
    Box, Button, Divider, Flex, FormControl, FormErrorMessage, Icon, Image, Input, Link, Modal, ModalBody,
    ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, SimpleGrid,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text, useDisclosure
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useAppSelector } from "@hooks";
import { useInfiniteScroll } from "ahooks";
import { commonValidator } from "common/validators";
import React, { useMemo, useRef } from "react";
import { createPortal } from "react-dom";
import { useDropzone } from 'react-dropzone';
import { Controller, FieldValues, Path, PathValue, useForm, useFormContext } from "react-hook-form";
import { FcAddImage } from "react-icons/fc";
import { toast } from "react-toastify";
import { selectActiveReport } from "src/redux/features/blueprint/bluePrintSlice";
import { ReportImage } from "src/redux/types/api";
import { assertIsDefined } from "src/templates/blueprint/utils";
import { z } from "zod";
import FormTitle from "../../layout/FormTitle";

interface Result {
    list: string[];
    nextId: string | undefined;
}

function getLoadMoreList(nextId: string | undefined, limit: number, images: any[]): Promise<Result> {
    let start = 0;
    if (nextId) {
        start = images.findIndex((i) => i === nextId);
    }
    const end = start + limit;
    const list = images.slice(start, end);
    const nId = images.length >= end ? images[end] : undefined;
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve({
                list,
                nextId: nId,
            });
        }, 1000);
    });
}

interface GalleryProps {
    images: ReportImage[];
    isFocused: boolean;
    onImageClick: (image: any) => void;
}

const Gallery:React.FC<GalleryProps> = ({ images, isFocused, onImageClick }) => {
    const { data, loading, loadMore, loadingMore } = useInfiniteScroll((d) =>
        getLoadMoreList(d?.nextId, 32, images),
    );

    const checkExistPortal = document.getElementById('load-more-portal');

    return (
        <>
            {loading ? (
                <p>loading</p>
            ) : (
                <SimpleGrid columns={4} spacing={2} px='2rem'>
                    {data?.list?.map((item) => (
                        <Image key={item} src={item.publicUrl} h='10rem' w='10rem' objectFit='contain' _hover={{ border: '1px solid gray', cursor: 'pointer' }} onClick={() => onImageClick(item)} />
                    ))}
                </SimpleGrid>
            )}

            {isFocused && checkExistPortal && createPortal(
                <Flex justifyContent='flex-end'>
                    {data?.nextId && (
                        <Button variant='solid' type="button" onClick={loadMore} disabled={loadingMore} >
                            {loadingMore ? 'Loading more...' : 'Load more'}
                        </Button>
                    )}

                    {!data?.nextId && <span>No more data</span>}
                </Flex>,
                document.getElementById('load-more-portal') as Element, 'load-more-portal')}
        </>
    );
}

const focusedStyle = {
    borderColor: '#3c4043'
};

const acceptStyle = {
    borderColor: '#038f4b'
};

const rejectStyle = {
    borderColor: '#ff0031'
};

interface UploadImageModalProps {
    isOpen: boolean;
    onClose: () => void;
    onFileDrop: (file: File) => void;
    onImageLinkDrop: (link: string) => void;
    onImageGalleryClick: (image: any) => void;
}

const UploadImageModal: React.FC<UploadImageModalProps> = ({ isOpen, onClose, onFileDrop, onImageLinkDrop, onImageGalleryClick }) => {
    const [tabIndex, setTabIndex] = React.useState(0);
    const activeReport = useAppSelector(selectActiveReport)
    const { register, getValues, formState } = useForm({
        defaultValues: {
            image: null
        },
        resolver: zodResolver(z.object({
            image: commonValidator.imageUrlValidator
        })),
        mode: 'onBlur'
    });
    const { errors } = formState;
    const hiddenInputRef = useRef<any>(null);

    const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject } = useDropzone({
            onDrop: (incomingFiles) => {
                if (hiddenInputRef.current) {
                    // Note the specific way we need to munge the file into the hidden input
                    // https://stackoverflow.com/a/68182158/1068446
                    const dataTransfer = new DataTransfer();
                    incomingFiles.forEach((v) => {
                        dataTransfer.items.add(v);
                    });
                    hiddenInputRef.current.files = dataTransfer.files;
                }
            },
            onDropAccepted(files) {
                if (files.length === 1 && isOpen) {
                    onFileDrop(files[0]);
                }
            },
            maxFiles: 1,
            accept: {
                'image/jpeg': [],
                'image/jpg': [],
                'image/png': []
            }
        });

    const style = useMemo(() => ({
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);

    return <Modal isOpen={isOpen} onClose={onClose} size='4xl'>
        <ModalOverlay />
        <ModalContent bg='#303135'>
            <ModalHeader>
            </ModalHeader>
            <ModalCloseButton color='white' />
            <ModalBody>
                <Tabs variant='enclosed' colorScheme="gray" onChange={(index) => setTabIndex(index)}>
                    <TabList>
                        <Tab>My Files</Tab>
                        <Tab>Gallery</Tab>
                    </TabList>

                    <TabPanels>
                        <TabPanel>
                            <Box style={{
                                backgroundColor: '#202124',
                                height: '600px',
                                border: '1px dashed',
                                borderRadius: '10px',
                                borderColor: '#3c4043',
                                borderTopLeftRadius: '0px',
                                ...style
                            }}>
                                <Flex flexDir='column' justifyContent='center' alignItems='center' h='80%' {...getRootProps({ className: 'dropzone' })}>
                                    <Icon as={FcAddImage} w={16} h={16} mr='1rem' />
                                    <Text color='#93969B'>Drag an image here or <Link color='#85AEEE'>upload a file</Link> </Text>
                                    <Flex>
                                        <input type="file" name={'image'} required={true} style={{ opacity: 0 }} ref={hiddenInputRef} />
                                        <input {...getInputProps()} />
                                    </Flex>
                                </Flex>
                                <Box px='1rem'>
                                    <Flex alignItems='center' gap='1rem'>
                                        <Divider orientation='horizontal' borderColor='#3c4043' />
                                        <Text color='#93969B'>OR</Text>
                                        <Divider orientation='horizontal' borderColor='#3c4043' />
                                    </Flex>
                                    <Flex alignItems='start' gap='1rem'>
                                        <FormControl isInvalid={Boolean(errors.image)}>
                                            <Input variant='pill' colorScheme="gray" type='text' placeholder="Paste image link" {...register('image')} />
                                            <FormErrorMessage>{errors.image?.message}</FormErrorMessage>
                                        </FormControl>
                                        <Button isDisabled={Boolean(errors.image)} variant='pill' colorScheme='gray' onClick={() => getValues().image && onImageLinkDrop(getValues().image ?? '')}>Submit</Button>
                                    </Flex>
                                </Box>
                            </Box>
                        </TabPanel>
                        <TabPanel>
                            <Box style={{
                                backgroundColor: '#202124',
                                height: '600px',
                                border: '1px dashed',
                                borderRadius: '10px',
                                borderColor: '#3c4043',
                                borderTopLeftRadius: '0px',
                                width: '100%',
                                overflowY: 'auto',
                                ...style
                            }}>
                                {activeReport?.images && activeReport?.images.length > 0 ?
                                    <Gallery images={activeReport?.images} isFocused={tabIndex === 1} onImageClick={onImageGalleryClick} />
                                    : (
                                        <Flex height='100%' width='100%' justifyContent='center' alignItems='center'>
                                            <Text color='#93969B' textAlign='center'>
                                                No images found in the gallery. <br />
                                                Please upload an image to the gallery.
                                            </Text>
                                        </Flex>
                                    )}
                            </Box>
                        </TabPanel>
                    </TabPanels>
                </Tabs>
                <Box id='load-more-portal' />
            </ModalBody>

            <ModalFooter>
            </ModalFooter>
        </ModalContent>
    </Modal>
}

const ImageForm = <T extends FieldValues>() => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const methods = useFormContext<T>();
    const activeReport = useAppSelector(selectActiveReport);
    const [uploadImage] = useUploadReportImageMutation();

    assertIsDefined(activeReport);
    const handleFileDrop = async (file: File) => {
        try {
            const formData = new FormData();
            formData.append('file', file);
            formData.append('reportId', activeReport.id.toString());
            const response = await uploadImage(formData).unwrap();
            methods.setValue('properties.src' as Path<T>, response.publicUrl as PathValue<T, Path<T>>, { shouldDirty: true });
            toast.success('Image uploaded successfully');
            onClose();
        } catch (error) {
            toast.error('Failed to upload image');
        }
    }

    const handleImageLinkDrop = (link: string) => {
        methods.setValue('properties.src' as Path<T>, link as PathValue<T, Path<T>>, { shouldDirty: true });
        onClose();
    }

    const handleImageGalleryClick = (image: any) => {
        methods.setValue('properties.src' as Path<T>, image.publicUrl as PathValue<T, Path<T>>, { shouldDirty: true });
        onClose();
    }

    return <Flex flexDir='column'>
        <Flex mt={4} flexDir='column'>
            <Button variant='outline' onClick={onOpen}>Select image from gallery</Button>
            <UploadImageModal isOpen={isOpen} onClose={onClose} onFileDrop={(file: File) => handleFileDrop(file)} onImageLinkDrop={handleImageLinkDrop} onImageGalleryClick={handleImageGalleryClick} />
        </Flex>
        <Flex mt={4} flexDir='column'>
            <FormTitle title='Image placeholder:' />
            <Controller
                control={methods.control}
                name={'properties.alt' as Path<T>}
                render={({ field }) => (
                    <Input
                        value={field.value}
                        onChange={field.onChange}
                    />
                )}
            />
        </Flex>
    </Flex>

}

export default ImageForm;