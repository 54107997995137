import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import './App.css';

import { ChakraProvider } from '@chakra-ui/react';
import { Suspense } from 'react';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { store } from './redux/store';
import RootRouter from './router/router';
import { theme } from './theme/theme';
import AuthProvider from './AuthContext';
import { WindowInfoProvider } from '@faceless-ui/window-info';
import { Breakpoints } from '@faceless-ui/window-info/dist/WindowInfoProvider';
import { windowInfoBreakpoints } from '@constants';

import * as Sentry from '@sentry/react';
import ErrorFallback from './ErrorFallback';

function App() {
    return (
        <Sentry.ErrorBoundary fallback={<ErrorFallback />}>
            <Provider store={store}>
                <AuthProvider>
                    <WindowInfoProvider
                        breakpoints={windowInfoBreakpoints as unknown as Breakpoints}
                    >
                        <ChakraProvider theme={theme}>
                            <Suspense fallback={<div>Loading...</div>}>
                                <RootRouter />
                            </Suspense>
                            <ToastContainer />
                        </ChakraProvider>
                    </WindowInfoProvider>
                </AuthProvider>
            </Provider>
        </Sentry.ErrorBoundary>
    );
}

export default App;
