import { Box, Flex, Text } from '@chakra-ui/react';
import { ComponentUI, LinearGradient, LinearGradientStop } from '@types';

import { ComponentType } from 'common/enums';
import {
    Bar,
    BarChart,
    CartesianGrid,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis
} from 'recharts';
import { useCanvas } from 'src/blueprint/pages/editor/EditorContext';
import { useAppSelector } from 'src/hooks/redux';
import { selectComponentData } from 'src/redux/features/blueprint/bluePrintSlice';
import { formatGenericValue } from 'src/utils';
import { v4 as uuidv4 } from 'uuid';
import { useDefaultData } from '../../defaultData';
import { linearGradientAngle2Coords } from '../../utils';
import GoCard from './../GoCard';

const BiaxialBarChart = ({ properties, id, height }: ComponentUI) => {
    const { state } = useCanvas();
    const isSharedReport = !state.useEditorReport;
    const componentData = useAppSelector((state) => selectComponentData(state, id));
    const defaultData = useDefaultData(ComponentType.LINE_CHART);
    let data = componentData ?? {};
    if ((componentData?.error || componentData === undefined) && !isSharedReport) {
        data = defaultData;
    }

    const xAxisKey = data?.xAxisKey;
    const yAxisKey = data?.yAxisKeys?.[0];

    const xAxisFieldConfig = data?.fieldConfigs?.[xAxisKey];
    const yAxisConfig = data?.fieldConfigs?.[yAxisKey];

    const xFormatType = xAxisFieldConfig?.type;
    const yFormatType = yAxisConfig?.type;

    const renderTooltipContent = (o: any) => {
        const { payload } = o;

        if (!payload || !payload.length) return null;
        if (payload.length === 1) {
            return (
                <Box
                    className="customized-tooltip-content"
                    style={properties?.component?.tooltip?.style}
                >
                    <Text style={properties?.component?.tooltip?.textStyle}>{`${
                        payload[0].name
                    }: ${formatGenericValue(
                        payload[0].value,
                        yAxisConfig?.type,
                        yAxisConfig
                    )}`}</Text>
                </Box>
            );
        }

        return (
            <div
                className="customized-tooltip-content"
                style={properties?.component?.tooltip?.style}
            >
                <ul className="list">
                    {payload.map((item: any, index: number) => (
                        <li
                            key={`item-${index}`}
                            style={properties?.component?.tooltip?.textStyle}
                        >
                            {`${item.name}: ${formatGenericValue(
                                item.value,
                                yAxisConfig?.type
                            )}`}
                        </li>
                    ))}
                </ul>
            </div>
        );
    };

    const maxValue = Math.max.apply(
        null,
        data.data?.map((entry: any) => {
            const keys = Object.keys(entry).slice(1);
            return Math.max.apply(
                null,
                keys.map((key: string) => {
                    return entry[key];
                })
            );
        })
    );
    const max = +maxValue < 1 ? +maxValue * 1.1 : +maxValue * 1.1;
    const customizedDomain = [0, +max.toFixed(4)];

    const formatXTicks = (value: any) => {
        return formatGenericValue(value, xFormatType, {
            ...xAxisFieldConfig,
            dateFormat: 'dd.MM'
        });
    };

    return (
        <GoCard
            width={'100%'}
            height={height}
            label={properties?.title}
            properties={properties.style}
        >
            <ResponsiveContainer>
                {data?.data === undefined ? (
                    <Flex
                        justifyContent="center"
                        alignItems="center"
                        height="100%"
                        textAlign="center"
                    >
                        For the selected period and campaigns <br></br> there is no data
                    </Flex>
                ) : (
                    <BarChart data={data?.data} margin={{ left: 20 }}>
                        <defs>
                            {properties?.component?.linearGradients?.map(
                                (gradient: LinearGradient, index: number) => {
                                    const angle = gradient?.angle ?? 270;
                                    const { startPoint, endPoint } =
                                        linearGradientAngle2Coords(angle);
                                    return (
                                        <linearGradient
                                            key={uuidv4()}
                                            id={`${index.toString()}-${id}`}
                                            x1={angle ? startPoint.x : gradient.x1}
                                            y1={angle ? startPoint.y : gradient.y1}
                                            x2={angle ? endPoint.x : gradient.x2}
                                            y2={angle ? endPoint.y : gradient.y2}
                                        >
                                            {gradient.stops.map(
                                                (stop: LinearGradientStop) => (
                                                    <stop
                                                        key={uuidv4()}
                                                        offset={stop.offset}
                                                        stopColor={stop.stopColor}
                                                        stopOpacity={stop.stopOpacity}
                                                    />
                                                )
                                            )}
                                        </linearGradient>
                                    );
                                }
                            )}
                        </defs>
                        <CartesianGrid
                            {...(properties?.component?.cartesianGrid ?? {})}
                        />
                        <XAxis dataKey={data?.xAxisKey} tickFormatter={formatXTicks} />
                        {data?.yAxisKeys?.map((key: string, index: number) => {
                            const axisConfig = data?.fieldConfigs?.[key];

                            return (
                                <YAxis
                                    key={index}
                                    dataKey={key}
                                    orientation={index % 2 === 0 ? 'left' : 'right'}
                                    yAxisId={index}
                                    tickFormatter={(value) => {
                                        return formatGenericValue(
                                            +value,
                                            axisConfig?.type,
                                            axisConfig
                                        );
                                    }}
                                />
                            );
                        })}
                        <Tooltip
                            formatter={(value) => {
                                return formatGenericValue(
                                    +value,
                                    properties?.component?.formatType
                                );
                            }}
                            content={renderTooltipContent}
                        />
                        {data?.yAxisKeys?.map((key: string, index: number) => {
                            let fill = 'gray';
                            let stroke = 'gray';
                            if (properties?.component?.linearGradients?.length) {
                                const idx =
                                    index % properties.component.linearGradients.length;
                                fill = `url(#${`${idx.toString()}-${id}`})`;
                                stroke =
                                    properties.component.linearGradients[idx]
                                        .legendSymbolFillColor;
                            } else if (properties.component?.pallet?.length) {
                                fill =
                                    properties.component.pallet[
                                        index % properties.component.pallet.length
                                    ];
                            }
                            return (
                                <Bar
                                    key={index}
                                    type="monotone"
                                    dataKey={key}
                                    stroke={stroke}
                                    fillOpacity={1}
                                    fill={fill}
                                    yAxisId={index}
                                />
                            );
                        })}
                    </BarChart>
                )}
            </ResponsiveContainer>
        </GoCard>
    );
};

export default BiaxialBarChart;
