import { useListClientsQuery } from '@api';
import { Box, Button, Card, CardBody, Flex, Icon, useDisclosure } from '@chakra-ui/react';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import SearchInput from 'src/components/SearchComponent';
import { BaseTable, SourceIcon, TitleCell } from '@tasklogy/zircon-ui-components';
import { useState } from 'react';
import { FiPlus } from 'react-icons/fi';
import { useNavigate, useParams } from 'react-router-dom';
import CreateClientModal from 'src/blueprint/pages/Clients/CreateClientModal';
import { Client } from 'src/redux/types/api';
import LoadingStatus from '../../LoadingStatus';
import { getUserInfo } from '@auth';
import { TeamIdParams } from '@router';
import AuthorizedWithScopePermissions from 'src/auth/AuthorizedWithScopePermissions';
import ActionCell from './ActionCell';

const columnHelper = createColumnHelper<Client>();

const columns = [
    columnHelper.accessor('name', {
        id: 'client',
        cell: (info) => {
            const navigate = useNavigate();
            return (
                <TitleCell
                    weight="bold"
                    title={info.getValue()}
                    onClick={() =>
                        navigate(`${info.row.original.id}`, { relative: 'route' })
                    }
                />
            );
        },
        header: () => 'Client'
    }),
    columnHelper.accessor('reports', {
        id: 'active-reports',
        cell: (info) => {
            return info.getValue()?.filter((r) => r.isActive).length ?? 0;
        },
        header: () => 'Active reports',
        sortingFn: 'datetime'
    }),
    columnHelper.accessor('id', {
        id: 'sources-count',
        cell: (info) => {
            return info.row.original.connectedSources?.length ?? 0;
        },
        header: () => 'Connected accounts'
    }),
    columnHelper.accessor('connectedSources', {
        id: 'connected-sources',
        cell: (info) => {
            const sourceIdentifiers = info.getValue()?.map((cs) => cs.sourceIdentifier);
            if (!sourceIdentifiers?.length) {
                return <>-</>;
            }
            return (
                <SourceIcon
                    selectedIcons={[
                        ...new Set(
                            info.getValue()?.map((cs) => cs.sourceIdentifier) ?? []
                        )
                    ]}
                />
            );
        },
        header: () => 'Connected sources'
    }),
    columnHelper.accessor('assignedUsers', {
        id: 'assignedUsers',
        cell: (info) => {
            const users = info.getValue();
            return users?.length ? users.map((u) => u.fullName).join(', ') : '-';
        },
        header: () => 'Assigned users'
    }),
    columnHelper.accessor('id', {
        id: 'action',
        cell: (info) => {
            return <ActionCell client={info.row.original} />;
        },
        header: () => ''
    })
];

const ClientsTable = () => {
    const currentUser = getUserInfo();
    const params = useParams<TeamIdParams>();
    const [search, setSearch] = useState<string>('');
    const {
        isOpen: isOpenCreateClientModal,
        onOpen: onOpenCreateClientModal,
        onClose: onCloseCreateClientModal
    } = useDisclosure();
    const { data, isLoading } = useListClientsQuery(
        {
            teamId: params?.teamId ? parseInt(params.teamId) : currentUser?.teamId ?? null
        },
        {
            skip: !currentUser?.teamId && !params.teamId
        }
    );

    const tableData = data
        ?.filter((client: Client) => client.name.toLowerCase().includes(search))
        .sort(
            (a: Client, b: Client) =>
                new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );

    return (
        <Box w="100%" textAlign="center">
            <LoadingStatus isLoading={isLoading}>
                <Box>
                    <Card>
                        <CardBody>
                            <Flex
                                justifyContent={'end'}
                                alignItems="center"
                                gap="20px"
                                mb="1rem"
                            >
                                <SearchInput search={search} setSearch={setSearch} />
                                <AuthorizedWithScopePermissions
                                    scopePermissions={{
                                        team: ['create-client']
                                    }}
                                >
                                    <Button
                                        variant={'solid'}
                                        onClick={onOpenCreateClientModal}
                                    >
                                        <Box display="flex" alignItems="center" gap="5px">
                                            <Icon as={FiPlus}></Icon>
                                            Add client
                                        </Box>
                                    </Button>
                                </AuthorizedWithScopePermissions>
                            </Flex>
                            <BaseTable
                                columns={columns as ColumnDef<Client, any>[]}
                                data={tableData ?? []}
                                variant="reports"
                            />
                            <CreateClientModal
                                isOpen={isOpenCreateClientModal}
                                onClose={onCloseCreateClientModal}
                            />
                        </CardBody>
                    </Card>
                </Box>
            </LoadingStatus>
        </Box>
    );
};
export default ClientsTable;
