import { Box, Icon, Link } from '@chakra-ui/react';
import { ActionDelete, ActionEdit, NavigateLink } from '@tasklogy/zircon-ui-components';
import { FiPlus } from 'react-icons/fi';
import withScopePermissions from '../withScopePermissions';
import React from 'react';

interface NavigateProps
    extends Omit<React.ComponentProps<typeof NavigateLink>, 'children'> {
    children: React.ReactNode;
}
const Navigate: React.FC<NavigateProps> = (props) => {
    return (
        <NavigateLink variant={props?.variant} to={props.to} options={props.options}>
            {props.children}
        </NavigateLink>
    );
};

type CreateProps = Omit<React.ComponentProps<typeof NavigateLink>, 'children'>;
const CreateClient: React.FC<CreateProps> = (props) => {
    return (
        <NavigateLink variant={props?.variant} to={props.to} options={props.options}>
            <Box display="flex" alignItems="center" gap="5px">
                <Icon as={FiPlus}></Icon>
                Add client
            </Box>
        </NavigateLink>
    );
};

const CreateTeam: React.FC<CreateProps> = (props) => {
    return (
        <NavigateLink variant={props?.variant} to={props.to} options={props.options}>
            <Box display="flex" alignItems="center" gap="5px">
                <Icon as={FiPlus}></Icon>
                Add Team
            </Box>
        </NavigateLink>
    );
};

const CreateCampaign: React.FC<CreateProps> = (props) => {
    return (
        <NavigateLink variant={props?.variant} to={props.to} options={props.options}>
            <Box display="flex" alignItems="center" gap="5px">
                <Icon as={FiPlus}></Icon>
                Create Campaign
            </Box>
        </NavigateLink>
    );
};

interface EditLinkProps extends Omit<React.ComponentProps<typeof Link>, 'children'> {
    children: React.ReactNode;
    onOpen: () => void;
}
const EditLink: React.FC<EditLinkProps> = ({ onOpen, children }) => {
    return (
        <Box>
            <ActionEdit onClick={onOpen} />
            {children}
        </Box>
    );
};

type EditProps = React.ComponentProps<typeof ActionEdit>;
const Edit: React.FC<EditProps> = (props) => {
    return <ActionEdit {...props} />;
};

type DeleteProps = React.ComponentProps<typeof ActionDelete>;
const Delete: React.FC<DeleteProps> = (props) => {
    return <ActionDelete {...props} />;
};

const WithPermissionsAction: {
    CreateClient: React.FC<CreateProps>;
    EditClient: React.FC<EditProps>;
    DeleteClient: React.FC<DeleteProps>;
    CreateTeam: React.FC<CreateProps>;
    EditTeam: React.FC<EditProps>;
    DeleteTeam: React.FC<DeleteProps>;
    EditTeamMember: React.FC<EditLinkProps>;
} = () => {
    return <></>;
};

WithPermissionsAction.CreateClient = withScopePermissions(CreateClient, {
    any: ['create-client']
});

WithPermissionsAction.EditClient = withScopePermissions(Edit, {
    any: ['edit-client']
});

WithPermissionsAction.DeleteClient = withScopePermissions(Delete, {
    any: ['delete-client']
});

WithPermissionsAction.CreateTeam = withScopePermissions(CreateTeam, {
    any: ['create-team']
});

WithPermissionsAction.EditTeam = withScopePermissions(Edit, {
    any: ['edit-team']
});

WithPermissionsAction.DeleteTeam = withScopePermissions(Delete, {
    any: ['delete-team']
});

WithPermissionsAction.EditTeamMember = withScopePermissions(EditLink, {
    any: ['edit-team-member']
});

export default WithPermissionsAction;
