import { Box, Flex, IconButton } from "@chakra-ui/react";
import { useAppSelector } from "@hooks";
import { Controller, useFormContext } from "react-hook-form";
import { selectActiveReportPage } from "src/redux/features/blueprint/bluePrintSlice";
import FormNumberInput from "../inputs/Number";
import FormPropertyRow from "../layout/FormPropertyRow";
import FormPropertyTitle from "../layout/FormPropertyTitle";
import FormTitle from "../layout/FormTitle";
import { reportPageValidator } from "common/validators";
import { z } from "zod";
import { PiLinkSimpleHorizontalBreakLight, PiLinkSimpleHorizontalLight } from "react-icons/pi";
import React from "react";
import { ComponentType } from "common/enums";
import { getAspectRatio } from "../../setup/Image/getAspectRation";

const formValidator = reportPageValidator.baseComponent.pick({
    x: true,
    y: true,
    w: true,
    h: true,
    zIndex: true,
    type: true,
    properties: true
})

type FormData = z.infer<typeof formValidator>;

interface LockAspectRatioButtonProps {
    isLocked: boolean;
    setIsLocked: (isLocked: boolean) => void;
}

// This icon button is for lock the aspect ratio of the component
const LockAspectRatioButton: React.FC<LockAspectRatioButtonProps> = ({ isLocked, setIsLocked }) => {
    const icon = isLocked ? <PiLinkSimpleHorizontalLight style={{ transform: 'rotate(90deg)' }} /> : <PiLinkSimpleHorizontalBreakLight style={{ transform: 'rotate(90deg)' }} />;
    return <IconButton variant='editor' aria-label="link" icon={icon} onClick={() => setIsLocked(!isLocked)} />
}

const PositionForm = () => {
    const [isLocked, setIsLocked] = React.useState(false);
    const methods = useFormContext<FormData>();
    const activeReportPage = useAppSelector(selectActiveReportPage);
    const { errors } = methods.formState;

    const componentWidth = Number(methods.watch('w'));
    const componentHeight = Number(methods.watch('h'));
    const componentType = methods.watch('type');

    const pageLayout = activeReportPage?.layout ?? { pageWidth: 0, pageHeight: 0 };

    const handleWidthHeightChange = (value: number | undefined, isWidth: boolean, onChange: any) => {
        console.log('value', value, isWidth, componentWidth, componentHeight);
        if (isLocked) {
            if (isWidth) {
                if (value === undefined || value === 0 || componentHeight === 0) {
                    // @ts-ignore
                    methods.setValue('w', '');
                } else {
                    const ratio = componentWidth === 0 ? value : componentWidth / value;
                    const height = +(componentHeight / ratio).toFixed(2);
                    methods.setValue('w', value, { shouldDirty: true });
                    methods.setValue('h', height, { shouldDirty: true });
                }
            } else {
                if (value === undefined || value === 0) {
                    // @ts-ignore
                    methods.setValue('h', '');
                } else {
                    const ratio = componentHeight === 0 ? value : componentHeight / value;
                    const width = +(componentWidth / ratio).toFixed(2);
                    methods.setValue('h', value, { shouldDirty: true });
                    methods.setValue('w', width, { shouldDirty: true })
                }
            }
        } else {
            if (isWidth) {
                if (value === undefined) {
                    // @ts-ignore
                    onChange('');
                } else {
                    onChange(value);
                }
            } else {
                if (value === undefined) {
                    // @ts-ignore
                    onChange('');
                } else {
                    onChange(value);
                }
            }
        }
    }

    return <Box>
        <Flex flexDir='column'>
            <Flex my={4}>
                <FormTitle title="Position" />
            </Flex>
            <FormPropertyRow>
                <FormPropertyTitle title='Size' />
                <Flex gap='1rem' alignItems='center'>
                    <Controller
                        name='w'
                        render={({ field: { value, onChange, ...rest } }) => (
                            <FormNumberInput label="W" value={value} onChange={(e) => handleWidthHeightChange(e.target.value ? +e.target.value : undefined, true, onChange)} {...rest} max={pageLayout.pageWidth} error={errors.w} width='80px' type="number" />
                        )}
                    />
                    <Controller
                        name='h'
                        render={({ field: { value, onChange, ...rest } }) => (
                            <FormNumberInput label="H" value={value} onChange={(e) => handleWidthHeightChange(e.target.value ? +e.target.value : undefined, false, onChange)} {...rest} max={pageLayout.pageHeight} error={errors.h} width='80px' type="number" />
                        )}
                    />
                    {[ComponentType.IMAGE, ComponentType.LOGO].includes(componentType) &&
                        <LockAspectRatioButton isLocked={isLocked} setIsLocked={setIsLocked} />
                    }
                </Flex>
            </FormPropertyRow>
            <FormPropertyRow>
                <FormPropertyTitle title='Position' />
                <Flex gap='1rem'>
                    <Controller
                        name='x'
                        render={({ field: { value, onChange, ...rest } }) => (
                            <FormNumberInput label="X" value={value} onChange={(e) => onChange(e.target.value ? +e.target.value : '')} {...rest} max={pageLayout.pageWidth - componentWidth} error={errors.x} width='80px' type="number" />
                        )}
                    />
                    <Controller
                        name='y'
                        render={({ field: { value, onChange, ...rest } }) => (
                            <FormNumberInput label="Y" value={value} onChange={(e) => onChange(e.target.value ? +e.target.value : '')} {...rest} max={pageLayout.pageHeight - componentHeight} error={errors.y} width='80px' type="number" />
                        )}
                    />
                </Flex>
            </FormPropertyRow>
            <FormPropertyRow>
                <FormPropertyTitle title='Z-Index' />
                <Flex gap='1rem'>
                    <Controller
                        name='zIndex'
                        defaultValue={0}
                        render={({ field: { value, onChange, ...rest } }) => (
                            <FormNumberInput label="Z" value={value} onChange={(e) => onChange(e.target.value ? +e.target.value : '')} {...rest} max={500} error={errors.zIndex} width='80px' type="number" />
                        )}
                    />
                </Flex>
            </FormPropertyRow>
        </Flex>
    </Box>
}

export default PositionForm;