import { hasRoles, hasScopePermissions } from '@auth';
import { Box, Flex, Link } from '@chakra-ui/react';
import { Outlet, useNavigate } from 'react-router-dom';
import privateRoutes from 'src/router/private';
import { PathNames } from 'src/router/router';
import { Route } from '../../router/types';

export const LayoutSettings = () => {
    const navigate = useNavigate();
    const routes = privateRoutes();
    const settingsRoute = routes?.[0]?.children?.find(
        (route: Route) => route.path === PathNames.USER_SETTINGS
    );

    const userSettingsRoutes =
        settingsRoute?.children
            ?.filter(
                (route: Route) =>
                    route.path === PathNames.USER_SETTINGS_PERSONAL_DATA ||
                    route.path === PathNames.USER_SETTINGS_PASSWORD
            )
            .filter((route: Route) => {
                const authPermissions: (boolean | undefined)[] = [];
                if (route.handle?.auth?.withScopePermissions) {
                    authPermissions.push(
                        hasScopePermissions(route.handle.auth.withScopePermissions)
                    );
                }
                if (route.handle?.auth?.hasRoles) {
                    authPermissions.push(hasRoles(route.handle.auth.hasRoles));
                }

                if (authPermissions.length === 0) {
                    return false;
                }
                return authPermissions.some(Boolean);
            }) ?? [];

    const projectSettingsRoutes =
        settingsRoute?.children
            ?.filter(
                (route: Route) =>
                    route.path === PathNames.USER_SETTINGS_COMPANY_DATA ||
                    route.path === PathNames.USER_SETTINGS_TEAM_MEMBERS
            )
            .filter((route: Route) => {
                const authPermissions: (boolean | undefined)[] = [];
                if (route.handle?.auth?.withScopePermissions) {
                    authPermissions.push(
                        hasScopePermissions(route.handle.auth.withScopePermissions)
                    );
                }
                if (route.handle?.auth?.hasRoles) {
                    authPermissions.push(hasRoles(route.handle.auth.hasRoles));
                }

                if (authPermissions.length === 0) {
                    return false;
                }
                return authPermissions.some(Boolean);
            }) ?? [];

    return (
        <Flex h='100%'>
            <Box
                w="100%"
                maxW="300px"
                px="35px"
                pb="60px"
                pt="40px"
                bg="white"
                mb="0.375rem"
            >
                {userSettingsRoutes?.length > 0 && (
                    <Box
                        as="h3"
                        mb="20px"
                        fontSize="21px"
                        fontWeight="bold"
                        color="#525A66"
                    >
                        User settings
                    </Box>
                )}
                {userSettingsRoutes?.map((route: Route) => (
                    <Box key={route.path}>
                        <Link variant="textLink" onClick={() => navigate(route.path)}>
                            {route?.handle?.name}
                        </Link>
                    </Box>
                ))}
                {projectSettingsRoutes.length > 0 && (
                    <Box
                        as="h3"
                        my="20px"
                        fontSize="21px"
                        fontWeight="bold"
                        color="#525A66"
                    >
                        Team settings
                    </Box>
                )}
                {projectSettingsRoutes?.map((route: Route) => (
                    <Box key={route.path} mb="0.375rem">
                        <Link variant="textLink" onClick={() => navigate(route.path)}>
                            {route?.handle?.name}
                        </Link>
                    </Box>
                ))}
            </Box>
            <Flex w="100%" bg="#F8F8F8">
                <Outlet />
            </Flex>
        </Flex>
    );
};
