import { Flex, IconButton } from '@chakra-ui/react'
import { Controller, FieldValues, useFormContext } from "react-hook-form"
import { MdOutlineVerticalAlignBottom, MdOutlineVerticalAlignCenter, MdOutlineVerticalAlignTop } from 'react-icons/md'
import { RxAlignCenterHorizontally, RxAlignLeft, RxAlignRight } from "react-icons/rx"
import { TbLayoutDistributeHorizontal, TbLayoutDistributeVertical } from 'react-icons/tb'
import FormPropertyRow from "../layout/FormPropertyRow"
import FormPropertyTitle from "../layout/FormPropertyTitle"

const DonutLegendForm = <T extends FieldValues>({ objPath }: { objPath: string }) => {
    const methods = useFormContext<T>();
    const { errors } = methods.formState;

    return <Flex flexDir='column'>
        <FormPropertyRow>
            <Flex justifyContent='space-between' alignItems='center' w='100%'>
                <FormPropertyTitle title='Align' />
                <Flex gap='1rem' alignItems='center'>
                    <Controller
                        name={`${objPath}.align`}
                        defaultValue={'left'}
                        render={({ field }) => (
                            <IconButton variant='ghost' backgroundColor={field.value === 'left' ? 'gray.200' : 'transparent'} onClick={() => field.onChange({
                                target: { value: 'left' }
                            })} aria-label="Align Left" icon={<RxAlignLeft />} />
                        )}
                    />
                    <Controller
                        name={`${objPath}.align`}
                        defaultValue={'center'}
                        render={({ field }) => (
                            <IconButton variant='ghost' backgroundColor={field.value !== 'left' && field.value !== 'right' ? 'gray.200' : 'transparent'} onClick={() => field.onChange({
                                target: { value: 'center' }
                            })} aria-label="Align Center" icon={<RxAlignCenterHorizontally />} />
                        )}
                    />
                    <Controller
                        name={`${objPath}.align`}
                        defaultValue={'right'}
                        render={({ field }) => (
                            <IconButton variant='ghost' backgroundColor={field.value === 'right' ? 'gray.200' : 'transparent'} onClick={() => field.onChange({
                                target: { value: 'right' }
                            })} aria-label="Align Right" icon={<RxAlignRight />} />
                        )}
                    />
                </Flex>
            </Flex>
        </FormPropertyRow>
        <FormPropertyRow>
            <Flex justifyContent='space-between' alignItems='center' w='100%'><FormPropertyTitle title='Layout' />
                <Flex gap='1rem' alignItems='center'>
                    <Controller
                        name={`${objPath}.layout`}
                        defaultValue={'horizontal'}
                        render={({ field }) => (
                            <IconButton variant='ghost' backgroundColor={field.value === 'horizontal' ? 'gray.200' : 'transparent'} onClick={() => field.onChange({
                                target: { value: 'horizontal' }
                            })} aria-label="Horizontal" icon={<TbLayoutDistributeHorizontal />} />
                        )}
                    />
                    <Controller
                        name={`${objPath}.layout`}
                        defaultValue={'vertical'}
                        render={({ field }) => (
                            <IconButton variant='ghost' backgroundColor={field.value === 'vertical' ? 'gray.200' : 'transparent'} onClick={() => field.onChange({
                                target: { value: 'vertical' }
                            })} aria-label="Vertical" icon={<TbLayoutDistributeVertical />} />
                        )}
                    />
                </Flex>
            </Flex>
        </FormPropertyRow>
        <FormPropertyRow>
            <Flex justifyContent='space-between' alignItems='center' w='100%'><FormPropertyTitle title='Items align' />
                <Flex gap='1rem' alignItems='center'>
                    <Controller
                        name={`${objPath}.itemsAlign`}
                        defaultValue={'left'}
                        render={({ field }) => (
                            <IconButton variant='ghost' backgroundColor={field.value === 'left' ? 'gray.200' : 'transparent'} onClick={() => field.onChange({
                                target: { value: 'left' }
                            })} aria-label="Align Left" icon={<RxAlignLeft />} />
                        )}
                    />
                    <Controller
                        name={`${objPath}.itemsAlign`}
                        defaultValue={'mirror'}
                        render={({ field }) => (
                            <IconButton variant='ghost' backgroundColor={field.value !== 'left' && field.value !== 'right' ? 'gray.200' : 'transparent'} onClick={() => field.onChange({
                                target: { value: 'mirror' }
                            })} aria-label="Align Mirror" icon={<RxAlignCenterHorizontally />} />
                        )}
                    />
                    <Controller
                        name={`${objPath}.itemsAlign`}
                        defaultValue={'right'}
                        render={({ field }) => (
                            <IconButton variant='ghost' backgroundColor={field.value === 'right' ? 'gray.200' : 'transparent'} onClick={() => field.onChange({
                                target: { value: 'right' }
                            })} aria-label="Align Right" icon={<RxAlignRight />} />
                        )}
                    />
                </Flex>
            </Flex>
        </FormPropertyRow>
        <FormPropertyRow>
            <Flex justifyContent='space-between' alignItems='center' w='100%'>
                <FormPropertyTitle title='Vertical align' />
                <Flex gap='1rem' alignItems='center'>
                    <Controller
                        name={`${objPath}.verticalAlign`}
                        defaultValue={'top'}
                        render={({ field }) => (
                            <IconButton variant='ghost' backgroundColor={field.value === 'top' ? 'gray.200' : 'transparent'} onClick={() => field.onChange({
                                target: { value: 'top' }
                            })} aria-label="Align Left" icon={<MdOutlineVerticalAlignTop />} />
                        )}
                    />
                    <Controller
                        name={`${objPath}.verticalAlign`}
                        defaultValue={'middle'}
                        render={({ field }) => (
                            <IconButton variant='ghost' backgroundColor={field.value !== 'top' && field.value !== 'bottom' ? 'gray.200' : 'transparent'} onClick={() => field.onChange({
                                target: { value: 'middle' }
                            })} aria-label="Align Mirror" icon={<MdOutlineVerticalAlignCenter />} />
                        )}
                    />
                    <Controller
                        name={`${objPath}.verticalAlign`}
                        defaultValue={'bottom'}
                        render={({ field }) => (
                            <IconButton variant='ghost' backgroundColor={field.value === 'bottom' ? 'gray.200' : 'transparent'} onClick={() => field.onChange({
                                target: { value: 'bottom' }
                            })} aria-label="Align Right" icon={<MdOutlineVerticalAlignBottom />} />
                        )}
                    />
                </Flex>
            </Flex>
        </FormPropertyRow>

    </Flex>
}

export default DonutLegendForm;