
import React from 'react';
import { Box, Flex, Text } from '@chakra-ui/react';

interface FormTitleProps {
    title: string;
}

const FormTitle: React.FC<FormTitleProps> = ({ title }) => {
    return <Text textStyle='h1'>{title}</Text>
}

export default FormTitle;