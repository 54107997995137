import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { useAppSelector } from '@hooks';
import { ComponentType } from 'common/enums';
import { useCanvas } from 'src/blueprint/pages/editor/EditorContext';
import { selectActiveReport, selectComponentById } from 'src/redux/features/blueprint/bluePrintSlice';
import Setup from './Setup';
import Style from './Style';
import ReportScreenshot from 'src/blueprint/pages/editor/components/TabPages/ReportScreenshot';

const staticComponents = [ComponentType.TEXT, ComponentType.RECTANGLE, ComponentType.DOWNLOAD_BUTTON];

const PropertySidebarTabs = () => {
    const activeReport = useAppSelector(selectActiveReport);
    const { state: { activeSelectedComponentId } } = useCanvas();
    const componentToEdit = useAppSelector(selectComponentById(activeSelectedComponentId));
    const showSetup = componentToEdit?.type ? !staticComponents.includes(componentToEdit.type) : false;
    return <Tabs variant='primary'>
        <TabList>
            <Tab>Style</Tab>
            {showSetup && <Tab>Setup</Tab>}
            {!componentToEdit && activeReport?.isTemplate && <Tab>Report Screenshot</Tab>}
        </TabList>

        <TabPanels>
            <TabPanel maxH='calc( 100vh - 98px)'>
                <Style />
            </TabPanel>
            <TabPanel maxH='calc( 100vh - 98px)'>
                { showSetup ?  <Setup /> : <ReportScreenshot />}
            </TabPanel>
        </TabPanels>
    </Tabs>
}

export default PropertySidebarTabs;