import { useUpdateReportPageMutation } from "@api";
import {
    Input
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { reportPageValidator } from "common/validators";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import AutoSave from "src/blueprint/pages/editor/components/AutoSave";
import { TPage } from "./types";
import { slugify } from "./utils";

export interface PageFormProps {
    page: TPage;
    onSaveCb: () => void;
}

export const PageForm = ({ page, onSaveCb }: PageFormProps) => {
    const [updatePage] = useUpdateReportPageMutation();
    const methods = useForm({
        values: page,
        resolver: zodResolver(reportPageValidator.update),
        mode: 'onBlur',
    });

    const onSubmit = async () => {
        const values = methods.getValues();
        try {
            await updatePage({
                displayName: values.displayName,
                slug: slugify(values.displayName) + '-' + page.id,
                id: page.id
            }).unwrap();
            onSaveCb()
        } catch (error) {
            toast.error('Failed to update page');
            onSaveCb()
        }
    }

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <Controller control={methods.control} name='displayName' render={({ field }) => (
                    <Input variant={'unstyled'} {...field} placeholder="" boxShadow='none' />
                )} />
                <input hidden type="submit" />
                <AutoSave onSubmit={onSubmit} defaultValues={page} />
            </form>
        </FormProvider>
    );
};