import { useAppSelector } from '@hooks';
import { ComponentType } from 'common/enums';
import { Component } from 'common/types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useCreateComponent } from 'src/hooks/useCreateComponent';
import { useDeleteComponent } from 'src/hooks/useDeleteComponent';
import { useDuplicateComponent } from 'src/hooks/useDuplicateComponent';
import {
    useSetBulkComponentConfig,
    useSetComponentConfig
} from 'src/hooks/useSetComponentConfig';
import { selectActiveReportPage } from 'src/redux/features/blueprint/bluePrintSlice';
import { ICanvasContext } from './types';
import { useCanvasAlignment } from './useCanvasAlignment';
import { useCanvasHotkeys } from './useCanvasHotkeys';
import { useEventEmitter } from 'ahooks';
import { PathNames } from 'src/router/router';
import { SHARED_REPORT_PATH } from '@constants';

export const CanvasContext = React.createContext<ICanvasContext | undefined>(undefined);

// Custom hook to use CanvasContext
export const useCanvas = () => {
    const context = React.useContext(CanvasContext);
    if (!context) throw new Error('useCanvas must be used within a CanvasProvider');
    return context;
};

interface CanvasProviderProps {
    children: React.ReactNode;
    useSharedReportAPI?: boolean;
    useEditorReport?: boolean;
    reportBasePathName: string;
    containerStyle?: React.CSSProperties;
    height?: number;
    width?: number;
}

// CanvasProvider component
const CanvasProvider: React.FC<CanvasProviderProps> = ({
    children,
    useEditorReport = false,
    useSharedReportAPI = false,
    reportBasePathName,
    containerStyle,
    height,
    width
}) => {
    const eventEmitter = useEventEmitter<string>();
    const [isReadOnly, setIsReadOnly] = useState<boolean>(false);
    const activeReportPage = useAppSelector(selectActiveReportPage);
    const canvasObjects = Object.values(activeReportPage?.components || {});

    const [scale, setScale] = useState<number>(1);
    const [canvasHeight, setHeight] = useState<number>(height ?? 0);
    const [canvasWidth, setWidth] = useState<number>(width ?? 0);

    const [activeSelection, setActiveSelection] = useState<Set<string>>(new Set());
    const [activeSelectedComponentId, setActiveSelectedComponentId] =
        useState<string>('');
    const [enabledInlineEditor, setEnabledInlineEditor] = useState<boolean>(false);

    const createComponent = useCreateComponent();
    const deleteComponent = useDeleteComponent();
    const duplicateComponent = useDuplicateComponent();
    const setComponentConfig = useSetComponentConfig();
    const setBulkComponentConfig = useSetBulkComponentConfig();

    const containerRef = useRef<HTMLDivElement>(null);
    const isSelectAll = useRef<boolean>(false);

    // Custom handlers
    const addComponent = useCallback(
        async (type: ComponentType, x?: number, y?: number) => {
            const component = await createComponent(type, x, y);

            if (component) {
                setActiveSelection(new Set([component.id]));
                setActiveSelectedComponentId(component.id);
            }
        },
        [createComponent]
    );

    const deleteObject = useCallback(
        async (id: string) => {
            setActiveSelection(new Set());
            setActiveSelectedComponentId('');
            await deleteComponent(id);
        },
        [deleteComponent]
    );

    const duplicateObject = useCallback(
        async (component: Component) => await duplicateComponent(component),
        [duplicateComponent]
    );

    const duplicateSelectedObjects = React.useCallback(() => {
        activeSelection.forEach(async (id) => {
            const component = canvasObjects.find((obj) => obj.id === id);
            if (component) {
                await duplicateObject(component);
            }
        });
    }, [canvasObjects, activeSelection, duplicateObject]);

    const handleSetActiveSelection = useCallback((ids: Set<string>) => {
        setActiveSelection(ids);
        setActiveSelectedComponentId(
            ids.size === 1 ? ids.values().next().value ?? '' : ''
        );
    }, []);

    const handleCopyStyles = React.useCallback(
        async (component: Component) => {
            if (activeSelection.size !== 1) return;
            if (!component) return;
            await navigator.clipboard.writeText(JSON.stringify(component.properties));
        },
        [activeSelection]
    );

    const handlePasteStyles = React.useCallback(
        async (component: Component) => {
            if (activeSelection.size !== 1) return;
            if (!component) return;
            try {
                const styles = await navigator.clipboard.readText();
                const parsedStyles = JSON.parse(styles);
                const componentWithNewStyles = { ...component, properties: parsedStyles };
                setComponentConfig(componentWithNewStyles);
            } catch (error) {
                toast.error('Error pasting styles');
            }
        },
        [activeSelection, canvasObjects, setComponentConfig]
    );

    // Update components
    const updateComponents = useCallback(
        async (updatedComponents: Component[]) => {
            await setBulkComponentConfig(updatedComponents);
        },
        [setComponentConfig]
    );

    // Hotkeys registration
    useCanvasHotkeys({
        canvasObjects,
        activeSelection,
        setActiveSelection: handleSetActiveSelection,
        duplicateSelectedObjects,
        updateComponents
    });

    // Alignment actions
    const alignActions = useCanvasAlignment({
        canvasObjects,
        activeSelection,
        updateComponents
    });

    // image reset aspect ratio, from url get image size
    const resetAspectRatio = useCallback(
        async (id: string) => {
            const component = canvasObjects.find((obj) => obj.id === id);
            if (!component) return;
            if ([ComponentType.IMAGE, ComponentType.LOGO].includes(component.type)) {
                const img = new Image();
                img.src = component.properties.src;
                img.onload = () => {
                    const { width, height } = img;
                    const updatedComponent = {
                        ...component,
                        w: width,
                        h: height
                    };
                    setComponentConfig(updatedComponent);
                };
            }
        },
        [canvasObjects, setComponentConfig]
    );

    // Context value
    const contextValue = {
        state: {
            canvasObjects,
            activeSelection,
            enabledInlineEditor,
            activeSelectedComponentId,
            isReadOnly,
            useSharedReportAPI: useSharedReportAPI,
            useEditorReport: useEditorReport,
            reportBasePathName: reportBasePathName,
            scale,
            height: canvasHeight,
            width: canvasWidth
        },
        actions: {
            setActiveSelection: handleSetActiveSelection,
            addComponent,
            deleteObject,
            setEnabledInlineEditor,
            duplicateObject,
            setIsReadOnly,
            copyStyles: handleCopyStyles,
            pasteStyles: handlePasteStyles,
            align: alignActions,
            setScale: setScale,
            resetAspectRatio,
            setHeight,
            setWidth
        },
        eventEmitter
    };

    // Handle outside click
    useEffect(() => {
        const handleMouseDown = () => {
            if (!isSelectAll.current) return;
            isSelectAll.current = false;
            setActiveSelection(new Set());
        };

        document.addEventListener('mousedown', handleMouseDown);
        return () => document.removeEventListener('mousedown', handleMouseDown);
    }, []);

    return (
        <div ref={containerRef} style={containerStyle}>
            <CanvasContext.Provider value={contextValue}>
                {children}
            </CanvasContext.Provider>
        </div>
    );
};

export default CanvasProvider;
