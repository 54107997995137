import { Flex, SimpleGrid } from "@chakra-ui/react"
import { Controller, FieldValues, Path, useFormContext } from "react-hook-form"
import FormNumberInput from "../inputs/Number"
import SquareColorPickerInput from "../inputs/SquareColorPickerInput"
import FormPropertyTitle from "../layout/FormPropertyTitle"

const BOX_SHADOW = /(\d+)\s+(\d+)\s+(\d+)\s+(\d+)\s+(.+)/;


const DropShadowForm = <T extends FieldValues>({ objPath }: { objPath: Path<T> }) => {
    const methods = useFormContext<T>();

    const extractDropShadow = (value: string | undefined) => {
        if (!value) return { x: 0, y: 0, blur: 0, spread: 0, color: '#000000' };
        const color = value?.split(' ').pop();
        const [x, y, blur, spread] = value?.split(' ').slice(0, 4).map((v) => v.replace('px', '').length > 0 ? parseInt(v.replace('px', '')) : '');
        return { x, y, blur, spread, color };
    }

    const handleChange = (key: string, keyValue: string, value: string) => {
        const newDropShadow = { ...extractDropShadow(value), [key]: keyValue };
        return `${newDropShadow.x}px ${newDropShadow.y}px ${newDropShadow.blur}px ${newDropShadow.spread}px ${newDropShadow.color}`;
    }

    return <Flex direction='column'>
        <Flex alignItems='center' gap='1rem'>
            <FormPropertyTitle title='Drop shadow' />
        </Flex>
        <Flex gap='0.5rem' justifyContent='space-between'>
            <SimpleGrid columns={2} spacing='0.2rem'>
                <Flex maxW='4rem'>
                    <Controller
                        name={`${objPath}.boxShadow` as Path<T>}
                        control={methods.control}
                        render={({ field }) => <FormNumberInput label="X" error={undefined} variant='editor' value={extractDropShadow(field.value).x} onChange={(e) => {
                            const newValue = handleChange('x', e.target.value, field.value);
                            field.onChange({ target: { value: newValue } });
                        }}
                        />}
                    />
                </Flex>
                <Flex maxW='4rem'>
                    <Controller
                        name={`${objPath}.boxShadow` as Path<T>}
                        control={methods.control}
                        render={({ field }) => <FormNumberInput label="Y" error={undefined} variant='editor' value={extractDropShadow(field.value).y} onChange={(e) => {
                            const newValue = handleChange('y', e.target.value, field.value);
                            field.onChange({ target: { value: newValue } });
                        }}
                        />}
                    />
                </Flex>
                <Flex maxW='5rem'>
                    <Controller
                        name={`${objPath}.boxShadow` as Path<T>}
                        control={methods.control}
                        render={({ field }) => <FormNumberInput label="Blur" error={undefined} variant='editor' value={extractDropShadow(field.value).blur} onChange={(e) => {
                            const newValue = handleChange('blur', e.target.value, field.value);
                            field.onChange({ target: { value: newValue } });
                        }}
                        />}
                    />
                </Flex>
                <Flex maxW='7rem'>
                    <Controller
                        name={`${objPath}.boxShadow` as Path<T>}
                        control={methods.control}
                        render={({ field }) => <FormNumberInput label="Spread" error={undefined} variant='editor' value={extractDropShadow(field.value).spread} onChange={(e) => {
                            const newValue = handleChange('spread', e.target.value, field.value);
                            field.onChange({ target: { value: newValue } });
                        }}
                        />}
                    />
                </Flex>
            </SimpleGrid>
            <Controller
                control={methods.control}
                name={`${objPath}.boxShadow` as Path<T>}
                render={({ field: { value, onChange } }) => (
                    <Flex width='70px' h='70px'>
                        <SquareColorPickerInput colorPickerProps={{ hideColorTypeBtns: true }} value={extractDropShadow(value).color} onChange={(e: any) => {
                            const newValue = handleChange('color', e.target.value, value);
                            onChange({ target: { value: newValue } });
                        }}
                            error={undefined} type="text" />
                    </Flex>
                )}
            />
        </Flex>
    </Flex>
}

export default DropShadowForm;