import { Box, useTheme } from '@chakra-ui/react';
import React, { CSSProperties, MutableRefObject, RefAttributes } from 'react';
import ReactSelect, {
    GroupBase,
    Props,
    SelectInstance,
    StylesConfig,
    Theme
} from 'react-select';

const chakraStyles: StylesConfig = {
    control: (provided, state) => ({
        ...provided,
        color: 'primary',
        boxShadow: '0 3px 14px rgba(0, 0, 0, 0.08)',
        backgroundColor: state.isDisabled ? '#F9F9F9' : 'white',
        borderRadius: '7px',
        minHeight: '40px',
        '&:hover': {
            backgroundColor: '#F9F9F9'
        },
        pointerEvents: 'auto',

        cursor: state.isDisabled ? 'not-allowed' : 'auto',
        borderColor: state.isFocused ? 'primary' : '#F2F3F4'
    }),
    container: (provided) => ({
        ...provided
    }),
    dropdownIndicator: (provided, { selectProps, isDisabled }) => ({
        ...provided,
        background: 'transparent',
        color: isDisabled ? '#EEE' : '#bbb',
        p: 0,
        w: '40px',
        '> svg': {
            transition: 'transform 300ms',
            transform: `rotate(${selectProps.menuIsOpen ? -180 : 0}deg)`
        }
    }),
    indicatorSeparator: (provided) => ({
        ...provided,
        display: 'none'
    }),
    placeholder: (provided, state) => ({
        ...provided,
        color: state.isDisabled ? '#bbb' : provided.color
    }),
    menuList: (provided) => ({
        ...provided,
        bg: 'white',
        py: 0
    }),
    option: (provided, { isSelected, isDisabled }) => ({
        ...provided,
        backgroundColor: isSelected ? '#F9F9F9' : 'white',
        color: isDisabled ? '#ccc' : 'black !important',
        '&:hover': {
            backgroundColor: '#F9F9F9'
        },
        _active: {
            color: 'black'
        },
        cursor: isDisabled ? 'not-allowed' : 'pointer'
    })
};

const editorVariant: StylesConfig = {
    control: (provided, state) => ({
        ...provided,
        color: 'primary',
        boxShadow: 'none',
        backgroundColor: state.isDisabled ? '#F9F9F9' : 'white',
        borderRadius: '9px',
        minHeight: '40px',
        '&:hover': {
            backgroundColor: '#F2F3F4'
        },
        pointerEvents: 'auto',
        cursor: state.isDisabled ? 'not-allowed' : 'auto',
        fontFamily: 'Noto Sans, sans-serif',
        fontSize: '11px',
        fontWeight: 400,
        lineHeight: '13px',
        borderColor: state.isFocused ? '#F2F3F4' : '#F2F3F4'
    }),
    container: (provided) => ({
        ...provided
    }),
    dropdownIndicator: (provided, { selectProps, isDisabled }) => ({
        ...provided,
        background: 'transparent',
        color: isDisabled ? '#EEE' : '#bbb',
        p: 0,
        w: '40px',
        '> svg': {
            transition: 'transform 300ms',
            transform: `rotate(${selectProps.menuIsOpen ? -180 : 0}deg)`
        }
    }),
    indicatorSeparator: (provided) => ({
        ...provided,
        color: '#F2F3F4'
    }),
    placeholder: (provided, state) => ({
        ...provided,
        color: state.isDisabled ? '#bbb' : provided.color
    }),
    menuList: (provided) => ({
        ...provided,
        bg: 'white',
        py: 0
    }),
    option: (provided, { isSelected, isDisabled }) => ({
        ...provided,
        backgroundColor: isSelected ? '#F9F9F9' : 'white',
        color: isDisabled ? '#ccc' : 'black !important',
        '&:hover': {
            backgroundColor: '#F9F9F9'
        },
        _active: {
            color: 'black'
        },
        cursor: isDisabled ? 'not-allowed' : 'pointer',
        fontFamily: 'Noto Sans, sans-serif',
        fontSize: '11px',
        fontWeight: 400,
        lineHeight: '17px',
    })
};

const selectTheme = (selectTheme: Theme, _color: string): Theme => {
    return {
        ...selectTheme,
        colors: {
            ...selectTheme.colors,
            neutral0: 'white', // background
            neutral20: '#DFDFDF' // border
            // neutral80: '#DFDFDF' // text
        }
    };
};

export type CustomSelectProps<
    Option = unknown,
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>
> = Props<Option, IsMulti, Group> &
    RefAttributes<SelectInstance<Option, IsMulti, Group>> & {
        isCreatable?: boolean;
        isWhithCheckbox?: boolean;
        styles?: any;
        style?: CSSProperties;
        variant?: 'editor' | 'default';
    };

const StyledSelect = React.forwardRef(
    <Option, IsMulti extends boolean, Group extends GroupBase<Option>>(
        props: CustomSelectProps<Option, IsMulti, Group>,
        ref:
            | ((instance: SelectInstance<Option, IsMulti, Group> | null) => void)
            | MutableRefObject<SelectInstance<Option, IsMulti, Group> | null>
            | null
    ) => {
        const chakraTheme = useTheme();
        return (
            <Box style={props.style}>
                <ReactSelect
                    styles={props.variant === 'editor' ? editorVariant : chakraStyles}
                    isOptionDisabled={(option: any) => option.isDisabled}
                    theme={(theme) =>
                        selectTheme(theme, chakraTheme.colors.brand.primary)
                    }
                    ref={ref}
                    {...props}
                />
            </Box>
        );
    }
);

export default StyledSelect;
