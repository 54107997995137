import { Helmet } from 'react-helmet';

export const fonts = {
    manrope: {
        name: 'Manrope',
        renderHelmet: () => {
            return (
                <Helmet>
                    <link rel="preconnect" href="https://fonts.googleapis.com" />
                    <link
                        rel="preconnect"
                        href="https://fonts.gstatic.com"
                        crossOrigin="anonymous"
                    />
                    <link
                        href="https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&display=swap"
                        rel="stylesheet"
                    />
                </Helmet>
            );
        }
    },
    ['noto-sans']: {
        name: 'Noto Sans',
        renderHelmet: () => {
            return (
                <Helmet>
                    <link rel="preconnect" href="https://fonts.googleapis.com" />
                    <link
                        rel="preconnect"
                        href="https://fonts.gstatic.com"
                        crossOrigin="anonymous"
                    />
                    <link
                        href="https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap"
                        rel="stylesheet"
                    />
                </Helmet>
            );
        }
    },
    ['helvetica-neue']: {
        name: 'Helvetica Neue',
        renderHelmet: () => {
            return null;
        }
    }
};
