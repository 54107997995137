import { Box, Flex } from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useAppSelector } from "@hooks";
import { reportPageValidator } from "common/validators";
import { FormProvider, useForm } from "react-hook-form";
import AutoSave from "src/blueprint/pages/editor/components/AutoSave";
import { useCanvas } from "src/blueprint/pages/editor/EditorContext";
import { useSetComponentConfig } from "src/hooks/useSetComponentConfig";
import { selectComponentById } from "src/redux/features/blueprint/bluePrintSlice";
import { deepMerge } from "src/utils/theme";
import { z } from "zod";
import { FormAccordion, FormAccordionItem } from "../../Accordion";
import CartesianGridForm from "../../forms/CartesianGridForm";
import ColorForm from "../../forms/ColorForm";
import PalletForm from "../../forms/PalletForm";
import PositionForm from "../../forms/PositionForm";
import GradientForm from "../../forms/GradientForm";
import CardForm from "../../forms/CardForm";

const formValidator = reportPageValidator.baseComponent.extend({
    properties: z.object({
        style: z.object({
            background: z.string().optional(),
            boxShadow: z.string().optional(),
        }),
        component: z.object({
            pallet: z.array(z.string()).optional(),
            linearGradients: z.array(z.object({
                x1: z.string(),
                x2: z.string(),
                y1: z.string(),
                y2: z.string(),
                stops: z.array(z.object({
                    stopColor: z.string(),
                    offset: z.string(),
                    stopOpacity: z.number()
                })).min(2),
                legendSymbol: z.string(),
                legendSymbolFillColor: z.string(),
                legendSymbolTextColor: z.string(),
            })).optional(),
            cartesianGrid: z.object({
                stroke: z.string(),
                opacity: z.number(),
                strokeDasharray: z.string(),
            })
        })
    })
})

type FormData = z.infer<typeof formValidator>

const defaultValues: Pick<FormData, 'properties'> = {
    properties: {
        style: {
            background: 'black',
            boxShadow: '0px 0px 0px 0px #000000'
        },
        component: {
            pallet: [],
            linearGradients: [],
            cartesianGrid: {
                stroke: '#E7E7E7',
                opacity: 1,
                strokeDasharray: '3 3',
            }
        }
    }
}

const StackedBarChartStyle: React.FC = () => {
    const { state: { activeSelectedComponentId } } = useCanvas();
    const componentToEdit = useAppSelector(selectComponentById(activeSelectedComponentId));
    const setComponentConfig = useSetComponentConfig();

    const methods = useForm<FormData>({
        defaultValues: defaultValues,
        values: componentToEdit as any,
        resolver: zodResolver(formValidator),
        mode: 'onBlur',
    })

    const onSubmit = async () => {
        const values = methods.getValues();
        setComponentConfig(values as any);
    }

    return <Box>
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <PositionForm />
                <GradientForm<FormData> name={"properties.component.linearGradients"} title="Gradients" />
                <PalletForm<FormData> name={"properties.component.pallet"} title="Pallet" />
                <CartesianGridForm<FormData> objPath={'properties.component.cartesianGrid'} />
                <CardForm<FormData> objPath={"properties.style"} />
                <input hidden type="submit" />
                <AutoSave onSubmit={onSubmit} defaultValues={deepMerge(defaultValues, componentToEdit)} />
            </form>
        </FormProvider>
    </Box>
}

export default StackedBarChartStyle;