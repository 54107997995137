import {
    Box,
    Flex,
    Text
} from '@chakra-ui/react'

interface FormAccordionProps {
    children: React.ReactNode
}

const FormAccordion: React.FC<FormAccordionProps> = ({ children }) => {
    return <Flex direction='column' gap='1rem'>
        {children}
    </Flex>
}

interface FormAccordionItemProps {
    title: string
    children: React.ReactNode
}

const FormAccordionItem: React.FC<FormAccordionItemProps> = ({ title, children }) => {
    return <Flex direction='column'>
        <Text textStyle='h1'>{title}</Text>
        <Box>
            {children}
        </Box>
    </Flex>
}

export { FormAccordion, FormAccordionItem }
