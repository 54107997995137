import { Icon, IconButton, Tooltip, VStack } from '@chakra-ui/react';
import { ComponentType } from 'common/enums';
import { CiImageOn } from 'react-icons/ci';
import { PiTextAaBold } from 'react-icons/pi';
import { useCanvas } from 'src/blueprint/pages/editor/EditorContext';
import AlignToolbar from 'src/templates/blueprint/toolbars/AlignToolbar';
import AddChartPopover from './AddChartPopover';
import AddComponentMenu from './AddComponentMenu';
import PagesPopover from './PagesPopover/PagesPopover';
import { useCanvasScroll } from './utils';
import React from 'react';

enum OpenPopover {
    PAGES,
    CHARTS,
    MENU
}

const Sidebar: React.FC = () => {
    const [isOpened, setIsOpened] = React.useState<OpenPopover | undefined>(undefined);
    const { actions, state } = useCanvas();
    const { scrollX, scrollY, canvasHeight } = useCanvasScroll();
    const x = state.width ? state.width / 2 : scrollX;
    const y = scrollY + canvasHeight / 2;

    const onToggle = (popover: OpenPopover) => {
        if (isOpened === popover) {
            setIsOpened(undefined);
        } else {
            setIsOpened(popover);
        }
    };
    return (
        <VStack
            w="48px"
            height="calc( 100vh - 48px)"
            bg="gray.800"
            color="black"
            p={4}
            spacing={4}
            style={{ backgroundColor: 'white' }}
        >
            <PagesPopover
                isOpen={isOpened === OpenPopover.PAGES}
                onToggle={() => onToggle(OpenPopover.PAGES)}
            />
            <AddChartPopover
                isOpen={isOpened === OpenPopover.CHARTS}
                onToggle={() => onToggle(OpenPopover.CHARTS)}
            />
            <AddComponentMenu
                isOpen={isOpened === OpenPopover.MENU}
                onToggle={() => onToggle(OpenPopover.MENU)}
            />

            <Tooltip
                label="Add a text field"
                aria-label="Add a text field"
                placement="right"
            >
                <IconButton
                    id="editor-sidebar-text"
                    variant="icon"
                    aria-label="Add text"
                    onClick={() => actions.addComponent(ComponentType.TEXT, x, y)}
                    icon={<Icon h="60px" as={PiTextAaBold} />}
                />
            </Tooltip>
            <Tooltip label="Add an image" aria-label="Add an image" placement="right">
                <IconButton
                    id="editor-sidebar-image"
                    variant="icon"
                    aria-label="Add image"
                    onClick={() => actions.addComponent(ComponentType.IMAGE, x, y)}
                    icon={<Icon h="60px" as={CiImageOn} />}
                />
            </Tooltip>
            <AlignToolbar layout="vertical" />
        </VStack>
    );
};

export default Sidebar;
