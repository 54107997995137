import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.tsx';
import './index.css';
import { Inspector, gotoVSCode } from 'react-dev-inspector';

import * as Sentry from '@sentry/react';
import { getEnv } from './utils/index.ts';

// __RELEASE_NAME__ is defined in the Vite config
window.__RELEASE_NAME__ = __RELEASE_NAME__;

if (getEnv() !== 'local') {
    Sentry.init({
        dsn: import.meta.env.VITE_SENTRY_DSN,
        integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
        environment: getEnv(),
        release: __RELEASE_NAME__,
        // Tracing
        tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ['localhost', import.meta.env.VITE_API_URL],
        enabled: true
        // Session Replay
    });
}

ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
        <Inspector keys={['Shift', 'Command', 'X']} onInspectElement={gotoVSCode} />
        <App />
    </React.StrictMode>
);

declare global {
    const __RELEASE_NAME__: string;
    interface Window {
        __RELEASE_NAME__: string;
    }
}
