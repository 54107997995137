const defaultDateRange = {
    properties: {
        buttonStyle: {
            color: 'white',
            '&:hover': {
                outline: 'none',
                borderColor: 'transparent',
                backgroundColor: '#5B6770'
            },
            flexWrap: 'nowrap',
            textWrap: 'nowrap',
            fontWeight: 'bolder',
            borderColor: '#5B6770',
            backgroundColor: '#5B6770'
        },
        pickerStyle: { color: 'black' },
        rangeColors: ['#5B6770'],
        omitPickerStyle: { color: 'black' },
        submitButtonStyle: {
            color: 'white',
            '&:hover': { backgroundColor: '#5B6770' },
            backgroundColor: '#5B6770'
        },
        popoverContentStyle: {
            color: 'black',
            borderColor: '#5B6770'
        },
        fromTodayContentColor: 'transparent',
        staticRangeLabelStyle: { color: 'black' }
    }
};
export default defaultDateRange;
