import { Box, Text } from '@chakra-ui/react';
import { BlueprintDatePickerProperties, ComponentUI } from '@types';
import { DateRangeFilter } from 'common/types';
import { endOfYesterday } from 'date-fns';
import { useSearchParams } from 'react-router-dom';
import { useCanvas } from 'src/blueprint/pages/editor/EditorContext';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux';
import { useGetReportPageFiltersMutation } from 'src/redux/api';
import {
    selectActiveReportPage,
    selectAppliedFilters,
    setAppliedFilter
} from 'src/redux/features/blueprint/bluePrintSlice';
import DatePicker2 from 'src/templates/blueprint/organisms/calendar/DatePicker2';
import { assertIsDefined, getFilterComponents } from '../utils';
import { useFetchReportPageData } from 'src/hooks/useFetchReportPageData';

interface Props extends Omit<ComponentUI, 'properties'> {
    properties: BlueprintDatePickerProperties;
    id: string;
}

const DatePickerFilter = ({ properties, id }: Props) => {
    const { state } = useCanvas();
    const [searchParams, setSearchParams] = useSearchParams();
    const dispatch = useAppDispatch();
    const activeReportPage = useAppSelector(selectActiveReportPage);
    const appliedFilters = useAppSelector(selectAppliedFilters);
    const appliedFilter = appliedFilters.find((f) => f.id === id);
    const fetchPageData = useFetchReportPageData();
    const [fetchPageFilterOptions] = useGetReportPageFiltersMutation();

    assertIsDefined(activeReportPage);

    const filterConfiguration = activeReportPage?.components[id] as DateRangeFilter;

    const filterComponents = getFilterComponents(activeReportPage.components);

    const handleChanges = (dateRange: any) => {
        const newFilters = appliedFilters.map((f) => {
            if (f.id === id) {
                return {
                    id,
                    value: {
                        start: dateRange.startDate.getTime(),
                        end: dateRange.endDate.getTime()
                    }
                };
            }

            return {
                id: f.id,
                value: f.value
            };
        });
        fetchPageData({
            filterValues: newFilters,
            isSharedReport: state.useSharedReportAPI
        });
        fetchPageFilterOptions({
            reportPageId: activeReportPage.id,
            filterIds: filterComponents?.map((f) => f.id) ?? [],
            dateRange: {
                start: dateRange.startDate.getTime(),
                end: dateRange.endDate.getTime()
            },
            isSharedReport: state.useSharedReportAPI
        });

        setSearchParams({
            ...searchParams,
            [id]: `${dateRange.startDate.getTime()}-${dateRange.endDate.getTime()}`
        });

        dispatch(
            setAppliedFilter({
                id,
                value: {
                    start: dateRange.startDate.getTime(),
                    end: dateRange.endDate.getTime()
                }
            })
        );
    };

    const minDate = new Date(filterConfiguration?.minDate ?? 0);
    const maxDate = new Date(filterConfiguration?.maxDate ?? endOfYesterday());

    return (
        <Box id={id} height="100%">
            {properties?.label && (
                <Text
                    color="white"
                    fontSize="medium"
                    fontWeight={400}
                    marginBottom={2}
                    style={properties.labelStyle}
                >
                    {properties?.label}
                </Text>
            )}
            <DatePicker2
                properties={properties}
                value={{
                    startDate: new Date(appliedFilter?.value?.start ?? 0),
                    endDate: new Date(appliedFilter?.value?.end ?? endOfYesterday()),
                    key: id
                }}
                minDate={minDate.getTime()}
                maxDate={maxDate.getTime()}
                onChange={(dateRange) => handleChanges(dateRange)}
            />
        </Box>
    );
};

export default DatePickerFilter;
