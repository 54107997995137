import {
    Flex,
    HTMLChakraProps,
    ThemingProps,
    chakra,
    forwardRef,
    useStyleConfig
} from '@chakra-ui/react';
import { BlueprintTableProperties } from '@types';
import { CSSProperties } from 'react';

interface CustomCardProps extends HTMLChakraProps<'div'>, ThemingProps {
    label?: string;
    properties?: Pick<BlueprintTableProperties, 'cardStyle' | 'labelStyle'>;
}

const GoCard = forwardRef<CustomCardProps, 'div'>((props, ref) => {
    const { children, label, size, variant, properties, ...rest } = props;

    return (
        <chakra.div
            ref={ref}
            display="flex"
            flexDir="row"
            gap={10}
            position={label ? 'relative' : 'unset'}
            mb={label ? '2.5rem' : 'unset'}
            {...rest}
            style={{ ...rest, ...properties?.cardStyle }}
        >
            {label && <CardLabel label={label} style={properties?.labelStyle} />}
            {children}
        </chakra.div>
    );
});

function CardLabel({
    label,
    style: styleProps
}: {
    label: string;
    style?: CSSProperties;
}) {
    return (
        <Flex
            h="2.5rem"
            fontWeight="bold"
            position="absolute"
            display="flex"
            alignItems={'center'}
            borderBottomRadius="0.75rem"
            left="1rem"
            style={styleProps}
        >
            {label}
        </Flex>
    );
}

export default GoCard;
