import { useCreateTeamMutation, useGetTeamQuery } from '@api';
import {
    Box,
    Button,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input
} from '@chakra-ui/react';
import { zodResolver } from '@hookform/resolvers/zod';
import { TeamIdParams } from '@router';
import { FormGrid, OutletTitle } from '@tasklogy/zircon-ui-components';
import { teamValidator } from 'common/validators';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import LoadingStatus from 'src/blueprint/components/LoadingStatus';
import { PathNames } from 'src/router/router';
import { z } from 'zod';

type FormData = z.infer<typeof teamValidator.create>;

const TeamCreate = () => {
    const navigate = useNavigate();
    const params = useParams<TeamIdParams>();
    const { data, isLoading } = useGetTeamQuery(
        {
            teamId: parseInt(params?.teamId as string)
        },
        {
            skip: !params?.teamId
        }
    );
    const [createTeam] = useCreateTeamMutation();
    const {
        register,
        formState: { errors, isDirty, isValid },
        getValues
    } = useForm<FormData>({
        resolver: zodResolver(teamValidator.create),
        values: data,
        mode: 'onChange',
        defaultValues: {
            name: ''
        }
    });

    const formIsInvalid = !isValid;

    const handleSaveChanges = async () => {
        const values = getValues();

        try {
            const response = await createTeam(values).unwrap();
            navigate(PathNames.TEAMS);
        } catch (error) {
            toast.error('Failed to create team');
        }
    };
    return (
        <Flex flexDir={'column'} height={'100%'} justifyContent={'space-between'}>
            <LoadingStatus isLoading={isLoading}>
                <Box>
                    <OutletTitle>
                        {params?.teamId ? 'Edit' : 'Create'} team
                    </OutletTitle>
                    <Flex justifyContent={'space-between'}>
                        <FormGrid>
                            <FormGrid.Item>
                                <FormControl isInvalid={Boolean(errors.name)}>
                                    <FormLabel>Team name</FormLabel>
                                    <Input
                                        type="text"
                                        placeholder={''}
                                        readOnly={Boolean(params?.teamId)}
                                        {...register('name')}
                                    />
                                    <FormErrorMessage>
                                        {errors.name?.message}
                                    </FormErrorMessage>
                                </FormControl>
                            </FormGrid.Item>
                        </FormGrid>
                    </Flex>
                    <Flex justifyContent={'end'} mt={'2rem'}>
                        <Button
                            variant="solid"
                            isDisabled={formIsInvalid}
                            onClick={() => handleSaveChanges()}
                        >
                            Save changes
                        </Button>
                    </Flex>
                </Box>
            </LoadingStatus>
        </Flex>
    );
};

export default TeamCreate;
