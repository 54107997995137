const defaultLineChart = {
    properties: {
        style: {
            width: '100%',
            height: '100%',
            padding: '1rem',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
            borderRadius: '20px',
            backgroundColor: 'white'
        },
        component: {
            tooltip: {
                style: {
                    padding: '0.5rem',
                    borderRadius: 5,
                    backgroundColor: 'white'
                },
                textStyle: {
                    color: 'black',
                    fontWeight: 'bolder',
                    textTransform: 'uppercase'
                }
            },
            embedded: {
                title: null
            },
            formatType: 'PERCENTAGE',
            cartesianGrid: {
                stroke: '#E7E7E7',
                opacity: 1,
                strokeDasharray: '3 3'
            },
            pallet: [
                '#2b3674',
                'rgb(255, 0, 6)',
                'rgb(97, 97, 97)',
                'rgb(26, 115, 232)',
                '#2f1ae8',
                '#343a40',
                '#bbabd6',
                '#7CB342'
            ]
        }
    }
};
export default defaultLineChart;
