import { ComponentUI } from '@types';
import { useAppSelector } from 'src/hooks/redux';
import {
    selectComponent,
    selectComponentData
} from 'src/redux/features/blueprint/bluePrintSlice';

import { Box, Flex, Text, VStack } from '@chakra-ui/react';

import { formatGenericValue } from 'src/utils';
import GoCard from '../GoCard';
import { DataComponent, TableReturnType } from 'common/types';
import { useMemo, useState } from 'react';
import PaginationButtons from '../table/PaginationButtons';

interface Column {
    value: string;
    isActive?: boolean;
}
interface RowProps {
    progress: number;
    columns: Column[];
    index: number;
}

function ProgressRow({ progress, columns }: RowProps) {
    return (
        <Flex
            position="relative"
            background="linear-gradient(90deg, rgba(6, 214, 255, 0.03) 56.5%, rgba(6, 214, 255, 0) 94.11%)"
            borderRadius="md"
            overflow="hidden"
            border="1px solid rgba(233, 233, 233, 0.3)"
            w="100%"
            h="32px"
        >
            <Box
                position="absolute"
                top="0"
                left="0"
                height="100%"
                borderRadius={'md'}
                width={`${progress}%`}
                background="linear-gradient(270deg, #06D6FF 2.06%, #0634FF 100%)"
                zIndex="1"
                pointerEvents="none" // Allows interactions with underlying content
            />

            <Box
                position="absolute"
                top="0"
                left="0"
                height="100%"
                borderRadius={'md'}
                width="100%"
                background="transparent"
                zIndex="2"
                pointerEvents="none"
            >
                <Flex w="100^" h="100%" alignItems="center" gap="0.75rem">
                    <Box flex="4" color="white" ml="1rem">
                        <Text color="#03084F">{columns[0]?.value}</Text>
                    </Box>

                    {columns.slice(1).map((col, index: number) => (
                        <Box flex="1" textAlign="left" height="max-content" key={index}>
                            <Text
                                fontWeight={col.isActive ? '500' : '400'}
                                color={col.isActive ? '#03084F' : '#9D9EB0'}
                            >
                                {col.value}
                            </Text>
                        </Box>
                    ))}
                </Flex>
            </Box>
        </Flex>
    );
}

const TableProgressBarChart = ({ properties, id }: ComponentUI) => {
    const componentData = useAppSelector((state) =>
        selectComponentData(state, id)
    ) as TableReturnType;
    const component = useAppSelector((state) =>
        selectComponent(state, id)
    ) as DataComponent;

    const [pagination, setPagination] = useState<{
        pageIndex: number;
        pageSize: number;
    }>({
        pageIndex: 0,
        pageSize: properties?.component?.pageSize ?? 4
    });

    const pageCount = componentData?.rows?.length
        ? Math.ceil(componentData?.rows?.length / pagination.pageSize)
        : 0;

    const onPreviousPage = () => {
        if (pagination.pageIndex > 0) {
            setPagination({ ...pagination, pageIndex: pagination.pageIndex - 1 });
        }
    };

    const onNextPage = () => {
        if (pagination.pageIndex < pageCount - 1) {
            setPagination({ ...pagination, pageIndex: pagination.pageIndex + 1 });
        }
    };

    const [progressAccessor, setProgressAccessor] = useState<string | undefined>(
        undefined
    );

    const sortedRows = useMemo(() => {
        if (!componentData?.rows?.length) {
            return [];
        }

        const activeAccessor = progressAccessor ?? componentData?.columns?.[1]?.accessor;

        const sortAccesssor_ = activeAccessor ?? componentData?.columns?.[1]?.accessor;

        let rows = componentData?.rows ?? [];

        if (sortAccesssor_) {
            rows = componentData?.rows?.slice().sort((a, b) => {
                return b[sortAccesssor_] - a[sortAccesssor_];
            });
        }

        return rows.slice(
            pagination.pageIndex * pagination.pageSize,
            (pagination.pageIndex + 1) * pagination.pageSize
        );
    }, [componentData, progressAccessor, pagination]);

    const renderContent = () => {
        if (component.dimensions?.length != 1 && !component.metrics?.length) {
            return (
                <Flex justifyContent="center" alignItems="center" w="100%">
                    <Text color="inherit" textAlign="center">
                        You need to select exactly one dimension and at least one metric.
                    </Text>
                </Flex>
            );
        }

        if (componentData?.error) {
            return (
                <Flex justifyContent="center" alignItems="center" w="100%">
                    <Text textAlign="center">
                        There was an error fetching the data. Please try again later.
                    </Text>
                </Flex>
            );
        }

        if (!componentData?.rows?.length) {
            return <Flex justifyContent="center" alignItems="center" w="100%"></Flex>;
        }

        if (componentData) {
            const data = componentData;
            const columns = data?.columns ?? [];
            const summary = data?.summary ?? [];

            const totals: Record<string, any> = {};

            summary.forEach((summaryRow) => {
                totals[summaryRow.accessor] = summaryRow.value;
            });

            const activeAccessor = progressAccessor ?? columns[1]?.accessor;

            const rows = sortedRows?.map((sortedRow) => {
                const row: { columns: Column[]; progress: number } = {
                    columns: [],
                    progress: 0
                };

                const activeCol = columns.find((col) => col.accessor === activeAccessor);

                if (!activeCol) {
                    return row;
                }

                const total = summary.find((s) => s.accessor === activeAccessor)
                    ?.value as number;

                const progress = (sortedRow[activeCol.accessor] / total) * 100;

                columns.forEach((col) => {
                    const col_ = {
                        value: formatGenericValue(sortedRow[col.accessor], col.type, col),
                        isActive: col.accessor === activeAccessor
                    };

                    row.columns.push(col_);
                });

                row.progress = progress;

                return row;
            });

            return (
                <Flex w="100%" h="100%" flexDir="column" fontSize="12px">
                    <Flex w="100%" alignItems="center" gap="0.75rem" mb="0.5rem">
                        <Box flex="4" />
                        {columns.slice(1).map((col) => (
                            <Box key={col.title} flex="1" textAlign="left">
                                <Text
                                    fontSize="12px"
                                    as="span"
                                    fontWeight={'400'}
                                    cursor="pointer"
                                    onClick={() => setProgressAccessor(col.accessor)}
                                    color={
                                        activeAccessor === col.accessor
                                            ? '#0768FF'
                                            : '#9D9EB0'
                                    }
                                    textDecor={
                                        activeAccessor === col.accessor
                                            ? 'underline'
                                            : 'none'
                                    }
                                >
                                    {col.title}
                                </Text>
                            </Box>
                        ))}
                    </Flex>
                    <Flex
                        w="100%"
                        flexDir="column"
                        h="100%"
                        justifyContent="space-between"
                    >
                        <VStack w="100%" spacing="1rem" fontSize="12px">
                            {rows.map((row, index) => (
                                <ProgressRow
                                    key={index}
                                    index={index}
                                    progress={row.progress}
                                    columns={row.columns}
                                />
                            ))}
                        </VStack>
                        <Box>
                            <PaginationButtons
                                pageCount={pageCount}
                                pageIndex={pagination.pageIndex}
                                pageSize={pagination.pageSize}
                                totalRowsCount={data?.rows?.length ?? 0}
                                onSetPageIndex={(pageIndex) =>
                                    setPagination({ ...pagination, pageIndex })
                                }
                                onPreviousPage={onPreviousPage}
                                onNextPage={onNextPage}
                                styles={{
                                    stack: {
                                        marginTop: '1rem',
                                        gap: '0',
                                        justifyContent: 'right'
                                    }
                                }}
                            />
                        </Box>
                    </Flex>
                </Flex>
            );
        }
    };

    return (
        <GoCard
            label={properties?.component?.embedded?.title}
            properties={properties}
            w="100%"
            h="100%"
        >
            {renderContent()}
        </GoCard>
    );
};

export default TableProgressBarChart;
