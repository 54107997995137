import { Flex } from "@chakra-ui/react";

interface Props {
    children: React.ReactNode;
}

const FormPropertyRow: React.FC<Props> = ({ children }) => {
    return <Flex alignItems='center' my={1} justifyContent='space-between'>
        {children}
    </Flex>
}

export default FormPropertyRow;