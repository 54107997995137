import {
    Box,
    Button,
    Flex,
    FormControl,
    FormErrorMessage,
    Image,
    Input,
    InputGroup,
    InputLeftElement,
    InputProps,
    InputRightElement,
    Popover,
    PopoverAnchor,
    PopoverBody,
    PopoverContent,
    Portal,
    Text,
    useDisclosure,
    useOutsideClick,
} from "@chakra-ui/react";
import React, { forwardRef, useRef } from "react";
import ColorPicker, { useColorPicker } from "react-best-gradient-color-picker";
import { FieldError } from "react-hook-form";
import { MdOutlineDeleteForever } from "react-icons/md";
import transparentIcon from "src/assets/editor/transparent.png";
import { usePopoverContext } from "src/editor/contexts/PopoverProvider";

interface FormNumberInputProps extends InputProps {
    error: FieldError | undefined;
    colorPickerProps?: any;
    onDeleteColor?: () => void;
    id: string; // Add an ID prop to differentiate instances
}

const ColorPickerInput = forwardRef<HTMLInputElement, FormNumberInputProps>(
    ({ error, value, onChange, onDeleteColor, colorPickerProps, id, ...props }, ref) => {
        const { openPopover, setOpenPopover } = usePopoverContext();
        const popoverRef = useRef<HTMLDivElement>(null);
        const isOpen = openPopover === id;

        const handleToggle = () => {
            setOpenPopover(isOpen ? null : id);
        };

        // Close the popover when clicking outside of it
        useOutsideClick({
            ref: popoverRef,
            handler: () => {
                if (openPopover === id) {
                    setOpenPopover(null);
                }
            },
        });

        const [focused, setFocused] = React.useState(false);
        const handleChange = (e: any) => {
            onChange?.(e);
        };

        const color = value as string;

        const { rgbaArr, isGradient } = useColorPicker(
            color,
            (newColor: string) => {
                handleChange({
                    target: {
                        value: newColor,
                    },
                });
            }
        );

        const handleOpacityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
            const opacity = +e.target.value / 100;
            const rgba = `rgba(${rgbaArr[0]}, ${rgbaArr[1]}, ${rgbaArr[2]}, ${opacity})`;

            if (!color.toLowerCase().includes("rgb")) {
                handleChange({ target: { value: rgba } });
                return;
            }

            const newColor = color.includes("rgb(")
                ? color.replace("rgb", "rgba").replace(")", `, ${opacity})`)
                : color.replace(/[^,]+(?=\))/, `${opacity}`);
                
                handleChange({ target: { value: newColor } });
            };

        return (
            <Box ref={popoverRef}  w="100%" h='100%'>
                <Popover isOpen={isOpen} onClose={() => setOpenPopover(null)}
                    autoFocus={false} placement="left">
                    <PopoverAnchor>
                        <Box display="flex" w="100%" h='100%' gap="0.5rem" alignItems="center">
                            <FormControl isInvalid={!!error?.message} w="100%" h='100%'>
                                {isGradient ? (
                                    <Box
                                        background={color}
                                        onClick={handleToggle}
                                        h="100%"
                                        minH="15px"
                                        borderRadius="3px"
                                        border='1px solid #DFDFDF' 
                                        cursor='pointer'
                                    />
                                ) : (
                                    <InputGroup>
                                        <InputLeftElement height="32px">
                                            {rgbaArr[3] === 0 ? (
                                                <Image
                                                    src={transparentIcon}
                                                    onClick={handleToggle}
                                                    objectFit="contain"
                                                    w="15px"
                                                    h="15px"
                                                    border='1px solid #DFDFDF' 
                                                    cursor='pointer'
                                                    borderRadius={3}
                                                />
                                            ) : (
                                                <Box
                                                    onClick={handleToggle}
                                                    backgroundColor={color}
                                                    w="15px"
                                                    h="15px"
                                                    border='1px solid #DFDFDF' 
                                                    cursor='pointer'
                                                    borderRadius={3}
                                                />
                                            )}
                                        </InputLeftElement>
                                        <Input
                                            variant="editor"
                                            size="xs"
                                            width="100%"
                                            onChange={handleChange}
                                            value={color}
                                            ref={ref}
                                            {...props}
                                        />
                                    </InputGroup>
                                )}
                                <Portal>
                                    <PopoverContent>
                                        <PopoverBody>
                                            <Flex w="100%" justifyContent="flex-end" mb="1rem">
                                                <Button
                                                    onClick={onDeleteColor}
                                                    leftIcon={<MdOutlineDeleteForever />}
                                                    colorScheme="red"
                                                    size="xs"
                                                    variant="solid"
                                                >
                                                    Delete color
                                                </Button>
                                            </Flex>
                                            <ColorPicker
                                                {...colorPickerProps}
                                                value={color}
                                                onChange={(newColor) =>
                                                    handleChange({
                                                        target: {
                                                            value: newColor,
                                                        },
                                                    })
                                                }
                                            />
                                        </PopoverBody>
                                    </PopoverContent>
                                </Portal>
                                {error?.message && <FormErrorMessage>{error.message}</FormErrorMessage>}
                            </FormControl>
                            {!isGradient && (
                                <InputGroup width='120px'>
                                    <Input
                                        variant="editor"
                                        size="xs"
                                        value={focused ? rgbaArr[3] * 100 : `${(rgbaArr[3] * 100).toFixed(0)}`}
                                        onFocus={() => setFocused(true)}
                                        onBlur={() => setFocused(false)}
                                        onChange={handleOpacityChange}
                                        type="text"
                                    />
                                    <InputRightElement height="32px" >
                                        <Text fontSize='xs' color='#929292'>%</Text>
                                    </InputRightElement>
                                </InputGroup>
                            )}
                        </Box>
                    </PopoverAnchor>
                </Popover>
            </Box>
        );
    }
);

export default ColorPickerInput;
