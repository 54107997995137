import { Flex } from '@chakra-ui/react';
import { WithPermissionsAction } from '@auth';
import SearchInput from 'src/components/SearchComponent';
import { OutletTitle } from '@tasklogy/zircon-ui-components';
import { useState } from 'react';
import { generatePath } from 'react-router-dom';
import TeamsTable from 'src/blueprint/components/Tables/TeamsTable/TeamsTable';
import { PathNames } from 'src/router/router';
export const TeamIndex = () => {
    const [search, setSearch] = useState<string>('');
    return (
        <>
            <Flex gap={2} mb="3rem" justifyContent="space-between" alignItems="center">
                <OutletTitle>Teams</OutletTitle>
                <Flex alignItems="center" gap="20px">
                    <SearchInput search={search} setSearch={setSearch} />
                    <WithPermissionsAction.CreateTeam
                        variant="defaultSmall"
                        to={generatePath(PathNames.TEAM_CREATE_UPDATE)}
                    />
                </Flex>
            </Flex>

            <TeamsTable searchValue={search} />
        </>
    );
};
