import { CSSProperties, useRef } from 'react';
import { useAppSelector } from 'src/hooks/redux';
import { selectActiveReportPage } from 'src/redux/features/blueprint/bluePrintSlice';

import { ComponentType, LayoutScale } from 'common/enums';
import { Component } from 'common/types';
import { ResizeEnable, Rnd, RndDragCallback, RndResizeCallback } from 'react-rnd';
import GoText from './atoms/Text';
import DatePickerFilter from './organisms/DatePickerFilter';
import DonutChart from './organisms/DonutChart';
import DownloadReportButton from './organisms/DownloadReportButton';
import SingleValue from './organisms/SingleValue';
import Table from './organisms/table/Table';

import { useWindowInfo } from '@faceless-ui/window-info';
import { defaultLayout } from 'common/constants';
import React from 'react';
import { isHotkeyPressed } from 'react-hotkeys-hook';
import { useCanvas } from 'src/blueprint/pages/editor/EditorContext';
import {
    useSetBulkComponentConfig,
    useSetComponentConfig
} from 'src/hooks/useSetComponentConfig';
import Image from './atoms/Image';
import Rectangle from './atoms/Rectangle';
import CanvasComponentMenu from './CanvasComponentMenu';
import CanvasObjectWrapper from './CanvasObjectWrapper';
import GoAreaChart from './organisms/AreaChart';
import BiaxialBarChart from './organisms/BarCharts/BiaxialBarChart';
import StackedBarChart from './organisms/BarCharts/StackedBarChart';
import TableProgressBarChart from './organisms/BarCharts/TableProgressBarChart';
import TinyBarChart from './organisms/BarCharts/TinyBarChart';
import MultiSelect from './organisms/filters/MultiSelect';
import SingleSelect from './organisms/filters/SingleSelect';
import GoLineChart from './organisms/LineChart';
import NavigationButton from './organisms/NavigationButton';
import ZirconPieChart from './organisms/PieChart';
import './UIComponent.css';
import { parseViewportWidth, resizeHandleClasses } from './utils';
import Logo from './atoms/Logo';
import Navbar from './organisms/Navbar';

const UIComponents = {
    [ComponentType.TABLE]: Table,
    [ComponentType.DONUT_CHART]: DonutChart,
    [ComponentType.LINE_CHART]: GoLineChart,
    [ComponentType.AREA_CHART]: GoAreaChart,
    [ComponentType.SINGLE_VALUE]: SingleValue,
    [ComponentType.PIE_CHART]: ZirconPieChart,
    [ComponentType.SINGLE_SELECT]: SingleSelect,
    [ComponentType.RECTANGLE]: Rectangle,
    [ComponentType.TEXT]: GoText,
    [ComponentType.DOWNLOAD_BUTTON]: DownloadReportButton,
    [ComponentType.NAVIGATION_BUTTON]: NavigationButton,
    [ComponentType.DATE_RANGE]: DatePickerFilter,
    [ComponentType.MULTI_SELECT]: MultiSelect,
    [ComponentType.IMAGE]: Image,
    [ComponentType.LOGO]: Logo,
    [ComponentType.BIAXIAL_BAR_CHART]: BiaxialBarChart,
    [ComponentType.STACKED_BAR_CHART]: StackedBarChart,
    [ComponentType.TINY_BAR_CHART]: TinyBarChart,
    [ComponentType.TABLE_PROGRESS_BAR_CHART]: TableProgressBarChart,
    [ComponentType.NAVBAR]: Navbar
};

interface CanvasComponentProps {
    children: React.ReactNode;
    style?: CSSProperties;
    component: Component;
    scale: number;
}

const getEnableResize = (
    _type: ComponentType,
    lockAspectRatio: boolean
): ResizeEnable => {
    if (lockAspectRatio) {
        return {
            bottomLeft: true,
            bottomRight: true,
            topLeft: true,
            topRight: true,

            bottom: false,
            left: false,
            right: false,
            top: false
        };
    }
    return {
        bottom: true,
        bottomLeft: true,
        bottomRight: true,

        top: true,
        topLeft: true,
        topRight: true,

        left: true,
        right: true
    };
};

const CanvasComponent = ({ children, component, scale }: CanvasComponentProps) => {
    const { state, actions } = useCanvas();
    const activeReportPage = useAppSelector(selectActiveReportPage);
    const [isDragged, setIsDragged] = React.useState<boolean>(false);
    const dragStartX = React.useRef<number>(0);
    const dragStartY = React.useRef<number>(0);
    const objectRef = useRef<any>(null);

    const setComponentConfig = useSetComponentConfig();
    const setBulkComponentConfig = useSetBulkComponentConfig();

    const isEditorReport = state.useEditorReport;

    // @ts-expect-error - RndDragCallback cannot be async, e is not being used
    const handleDragStop: RndDragCallback = async (e, d) => {
        if (state.activeSelection.size === 0) {
            return;
        }
        const canvasObjects = Object.values(activeReportPage?.components || {});
        const selectedObjects = canvasObjects.filter((obj) =>
            state.activeSelection.has(obj.id)
        );
        const changeX = d.x - dragStartX.current;
        const changeY = d.y - dragStartY.current;

        const updatedComponents = selectedObjects.map((obj) => ({
            ...obj,
            x: obj.x + changeX,
            y: obj.y + changeY
        }));
        setBulkComponentConfig(updatedComponents);
    };

    const handleResize: RndResizeCallback = async (
        _e,
        _direction,
        ref,
        _delta,
        position
    ) => {
        const updatedComponent = {
            ...component,
            w: parseInt(ref.style.width),
            h: parseInt(ref.style.height),
            ...position
        };

        await setComponentConfig(updatedComponent);
    };

    const style: CSSProperties = {
        outline: 'none',
        boxShadow: `0px 0px 0px 3px ${(component.id && state?.activeSelection.has(component.id)) || isDragged ? '#5592E8' : 'transparent'}`
    };

    const handleClass =
        component.id &&
        state.activeSelection.has(component.id) &&
        state.activeSelection.size === 1
            ? 'showHandles'
            : '';

    const onDoubleClick = () => {
        if (!isEditorReport || !state.isReadOnly) return;
        actions.setIsReadOnly(false);
        actions?.setEnabledInlineEditor(true);
    };

    const onKeyDown = (e: React.KeyboardEvent) => {
        if (!isEditorReport && !state.isReadOnly) {
            e.stopPropagation();
        }
    };

    return (
        <>
            {!isDragged && (
                <CanvasComponentMenu
                    component={component}
                    showMenu={
                        state.activeSelectedComponentId === component.id &&
                        !state.enabledInlineEditor
                    }
                    showActions={
                        state.activeSelectedComponentId === component.id &&
                        state.enabledInlineEditor
                    }
                />
            )}
            <Rnd
                ref={objectRef}
                scale={scale}
                dragGrid={isHotkeyPressed('shift') ? [1, 1] : [10, 10]}
                resizeGrid={[10, 10]}
                bounds={'parent'}
                style={{
                    zIndex: component.zIndex,
                    ...style
                }}
                size={{
                    width: component.w,
                    height: component.h
                }}
                position={{ x: component.x, y: component.y }}
                onDragStart={() => {
                    setIsDragged(true);
                    dragStartX.current = component.x;
                    dragStartY.current = component.y;
                }}
                onDragStop={(e, d) => {
                    setIsDragged(false);
                    handleDragStop(e, d);
                }}
                resizeHandleWrapperClass={handleClass}
                resizeHandleClasses={resizeHandleClasses}
                onResizeStop={handleResize}
                enableResizing={getEnableResize(component.type, isHotkeyPressed('shift'))}
                disableDragging={!isEditorReport}
                onDoubleClick={onDoubleClick}
                onKeyDown={onKeyDown}
                tabIndex={0}
                lockAspectRatio={isHotkeyPressed('shift')}
            >
                {children}
            </Rnd>
        </>
    );
};

export default function UIComponent({ component }: { component: Component }) {
    const { state } = useCanvas();

    const properties = component?.properties ?? {};

    let propertiesWithComponentProps = {
        ...properties,
        style: {
            display: 'block',
            width: component.w,
            height: component.h,
            ...properties.style
        }
    };

    const Component = UIComponents[component.type];

    if (!Component) {
        return null;
    }

    if (!state.useEditorReport) {
        return (
            <div
                style={{
                    transform: `scale(${state.scale})`,
                    transformOrigin: '0 0',
                    position: 'relative',
                    zIndex: component?.zIndex ?? 1
                }}
                key={component.id}
            >
                <div
                    style={{
                        position: 'absolute',
                        width: component.w,
                        height: component.h,
                        transform: `translate(${component.x}px, ${component.y}px)`,
                        outline: 'none'
                    }}
                >
                    <Component
                        key={component.id}
                        properties={propertiesWithComponentProps as any}
                        id={component.id}
                        height={component.h}
                        width={component.w}
                        zIndex={component.zIndex}
                    />
                </div>
            </div>
        );
    } else {
        return (
            <>
                {state.activeSelection.has(component.id) ? (
                    <CanvasComponent
                        component={component}
                        style={component.properties.style}
                        scale={1}
                        key={component.id}
                    >
                        <Component
                            properties={propertiesWithComponentProps as any}
                            id={component.id}
                            key={component.id}
                            height={component.h}
                            width={component.w}
                            zIndex={component.zIndex}
                        />
                    </CanvasComponent>
                ) : (
                    <CanvasObjectWrapper component={component}>
                        <Component
                            properties={propertiesWithComponentProps as any}
                            id={component.id}
                            key={component.id}
                            height={component.h}
                            width={component.w}
                            zIndex={component.zIndex}
                        />
                    </CanvasObjectWrapper>
                )}
            </>
        );
    }
}
