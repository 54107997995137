import { api, useListNotificationsQuery, useSeenNotificationsMutation } from '@api';
import { getUserInfo } from '@auth';
import {
    Badge,
    Box,
    HStack,
    IconButton,
    Popover,
    PopoverArrow,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    Portal,
    Text,
    VStack,
    useOutsideClick
} from '@chakra-ui/react';
import { useAppDispatch } from '@hooks';
import { useRef, useState } from 'react';
import { FiBell } from 'react-icons/fi';
import { generatePath, useNavigate } from 'react-router-dom';
import { PathNames } from 'src/router/router';

export default function Notifications() {
    const [isOpen, setIsOpen] = useState(false);
    const [setSeen] = useSeenNotificationsMutation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const popoverRef = useRef(null);

    const user = getUserInfo();
    const { data: notifications } = useListNotificationsQuery(undefined, {
        pollingInterval: 30 * 1000,
        skip: !user?.id
    });

    const unread = notifications?.filter((n) => !n.isRead) ?? [];

    const open = () => {
        setIsOpen(true);
    };

    const close = () => {
        setIsOpen(false);
        if (unread.length > 0 && isOpen) {
            setSeen({
                notificationIds: unread.map((n) => n.id)
            });
        }
    };

    useOutsideClick({
        ref: popoverRef,
        handler: close
    });

    return (
        <Box position="relative" display="inline-block" ref={popoverRef}>
            {/* Notification Popover */}
            <Popover
                returnFocusOnClose={false}
                isOpen={isOpen}
                onOpen={open}
                onClose={close}
            >
                <PopoverTrigger>
                    {/* Notification Icon Button */}
                    <IconButton
                        aria-label="Notifications"
                        icon={<FiBell size={24} />}
                        variant="ghost"
                        colorScheme="gray"
                        size="lg"
                    />
                </PopoverTrigger>

                {/* Red Badge with Unread Count */}
                {unread.length > 0 && (
                    <Badge
                        position="absolute"
                        top="1"
                        right="1"
                        backgroundColor="red.500"
                        color="white"
                        borderRadius="full"
                        px="0.375rem"
                        py="0rem"
                        fontSize="0.75rem"
                    >
                        {unread.length}
                    </Badge>
                )}

                {/* Popover Content */}
                <Portal>
                    <PopoverContent w="24rem" maxH="40rem" overflowY="auto">
                        <PopoverArrow />
                        <PopoverBody p="0">
                            {notifications?.length === 0 ? (
                                <Text
                                    textAlign="center"
                                    py="0.5rem"
                                    pl="0.5rem"
                                    pr="0.85rem"
                                >
                                    No new notifications
                                </Text>
                            ) : (
                                <VStack align="start" p="0" spacing="0">
                                    {notifications
                                        ?.slice(0, 10)
                                        ?.map((notification, index) => {
                                            const clientId =
                                                notification.extraData?.clientId;

                                            const navigateTo = clientId
                                                ? generatePath(PathNames.CLIENT_DETAIL, {
                                                      clientId: clientId.toString()
                                                  })
                                                : null;

                                            return (
                                                <Box
                                                    key={notification.id}
                                                    w="full"
                                                    cursor={
                                                        navigateTo ? 'pointer' : 'default'
                                                    }
                                                    _hover={{
                                                        backgroundColor: navigateTo
                                                            ? 'gray.100'
                                                            : 'none'
                                                    }}
                                                    onClick={() => {
                                                        if (navigateTo) {
                                                            navigate(navigateTo);
                                                            dispatch(
                                                                api.util.invalidateTags([
                                                                    'Client'
                                                                ])
                                                            );
                                                            close();
                                                        }
                                                    }}
                                                >
                                                    <Box
                                                        w="100%"
                                                        py="0.5rem"
                                                        pl="0.5rem"
                                                        pr="0.85rem"
                                                        borderTop={
                                                            index === 0
                                                                ? 'none'
                                                                : '1px solid #ccc'
                                                        }
                                                    >
                                                        <HStack>
                                                            <Box
                                                                w="0.65rem"
                                                                h="0.65rem"
                                                                borderRadius="100%"
                                                                backgroundColor={
                                                                    notification.isRead
                                                                        ? 'transaprent'
                                                                        : 'brand.primary'
                                                                }
                                                            />
                                                            <Box w="100%">
                                                                <HStack
                                                                    justify="space-between"
                                                                    align="center"
                                                                    w="100%"
                                                                    mb="0.125rem"
                                                                >
                                                                    <Text
                                                                        fontSize="sm"
                                                                        fontWeight="bold"
                                                                    >
                                                                        {
                                                                            notification.title
                                                                        }
                                                                    </Text>{' '}
                                                                    <Text
                                                                        fontSize="xs"
                                                                        color="gray.500"
                                                                    >
                                                                        {new Date(
                                                                            notification.createdAt
                                                                        ).toLocaleString()}
                                                                    </Text>
                                                                </HStack>

                                                                <Text fontSize="0.75rem">
                                                                    {notification.body}
                                                                </Text>
                                                            </Box>
                                                        </HStack>
                                                    </Box>
                                                </Box>
                                            );
                                        })}
                                </VStack>
                            )}
                        </PopoverBody>
                    </PopoverContent>
                </Portal>
            </Popover>
        </Box>
    );
}
