import { Box, Flex } from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useAppSelector } from "@hooks";
import { reportPageValidator } from "common/validators";
import { FormProvider, useForm } from "react-hook-form";
import AutoSave from "src/blueprint/pages/editor/components/AutoSave";
import { useCanvas } from "src/blueprint/pages/editor/EditorContext";
import { useSetComponentConfig } from "src/hooks/useSetComponentConfig";
import { selectComponentById } from "src/redux/features/blueprint/bluePrintSlice";
import { deepMerge } from "src/utils/theme";
import { z } from "zod";
import { FormAccordion, FormAccordionItem } from "../../Accordion";
import ColorForm from "../../forms/ColorForm";
import PositionForm from "../../forms/PositionForm";

const formValidator = reportPageValidator.baseComponent.extend({
    properties: z.object({
        style: z.object({
            background: z.string().optional(),
        }),
        controlStyle: z.object({
            color: z.string().optional(),
            backgroundColor: z.string().optional(),
            flexWrap: z.string().optional(),
            textWrap: z.string().optional(),
            "&:hover": z.object({
                borderColor: z.string().optional(),
            }).optional(),
        }).optional(),
        dropdownIndicatorStyle: z.object({
            color: z.string().optional(),
        }).optional(),
        indicatorSeparatorStyle: z.object({
            backgroundColor: z.string().optional(),
        }).optional(),
    })
})

type FormData = z.infer<typeof formValidator>

const defaultValues: Pick<FormData, 'properties'> = {
    properties: {
        style: {
            background: 'black',
        },
        controlStyle: {
            color: "#2b3674",
            "&:hover": {
                borderColor: "transparent"
            },
            flexWrap: "nowrap",
            textWrap: "nowrap",
            backgroundColor: "#ffffff"
        },
        dropdownIndicatorStyle: {
            color: "#2b3674"
        },
        indicatorSeparatorStyle: {
            backgroundColor: "transparent"
        }
    }
}

const MultiSelectStyle: React.FC = () => {
    const { state: { activeSelectedComponentId } } = useCanvas();
    const componentToEdit = useAppSelector(selectComponentById(activeSelectedComponentId));
    const setComponentConfig = useSetComponentConfig();

    const methods = useForm<FormData>({
        defaultValues: defaultValues,
        values: componentToEdit as any,
        resolver: zodResolver(formValidator),
        mode: 'onBlur',
    })

    const onSubmit = async () => {
        const values = methods.getValues();
        setComponentConfig(values as any);
    }

    return <Box>
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <PositionForm />
                <FormAccordion>
                    <FormAccordionItem title="Control">
                        <Flex flexDir='column'>
                            <ColorForm<FormData> title='Color' name={"properties.controlStyle.color"} />
                            <ColorForm<FormData> title='Background Color' name={"properties.controlStyle.backgroundColor"} />
                        </Flex>
                    </FormAccordionItem>
                    <FormAccordionItem title="Dropdown Indicator">
                        <Flex flexDir='column'>
                            <ColorForm<FormData> name={'properties.dropdownIndicatorStyle.color'} />
                        </Flex>
                    </FormAccordionItem>
                    <FormAccordionItem title="Indicator Separator">
                        <Flex flexDir='column'>
                            <ColorForm<FormData> name={'properties.indicatorSeparatorStyle.backgroundColor'} />
                        </Flex>
                    </FormAccordionItem>
                </FormAccordion>
                <input hidden type="submit" />
                <AutoSave onSubmit={onSubmit} defaultValues={deepMerge(defaultValues, componentToEdit)} />
            </form>
        </FormProvider>
    </Box>
}

export default MultiSelectStyle;