import { getUserInfo } from '@auth';
import { RoleName } from 'common/roles';

export const hasRoles = (roles: RoleName[]) => {
    const user = getUserInfo();
    const userRole = user?.role;

    if (!user || !userRole) {
        return false;
    }

    return roles.some((role) => userRole === role);
};
