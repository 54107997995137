import { ComponentType } from "react";
import { ScopePermissions } from "../router/types";
import { getUserInfo } from "./getUserInfo";
import { hasScopePermissions } from "./hasScopePermissions";



const withScopePermissions = <P extends object>(WrappedComponent: ComponentType<P>, scopePermissions: ScopePermissions) => {
    const WithScopePermissionsComponent: React.FC<P> = (props) => {
        const user = getUserInfo();
        const hasPermissions = hasScopePermissions(scopePermissions);
        if (!user || !hasPermissions) {
            return null;
        }
        return <WrappedComponent {...(props as P)} />;
    }
    return WithScopePermissionsComponent;
}

export default withScopePermissions;