import {
    ScopePermissions,
    WithAnyPermissionName,
    WithAnyPermissionScope
} from '../router/types';
import { getUserInfo } from './getUserInfo';

export const hasScopePermissions = (scopePermissions: ScopePermissions) => {
    const user = getUserInfo();
    const userScopePermissions = user?.scopePermissions;
    const scopes = Object.keys(scopePermissions) as WithAnyPermissionScope[];

    if (!user || !user.permissions) {
        return false;
    }

    // scopes has 'any' as a key, so we need to check if the user has any of the scopes permissions
    if (scopes.includes('any')) {
        const permissions = scopePermissions['any'];
        return permissions?.some(
            (permission: WithAnyPermissionName) =>
                permission === 'any' ||
                user?.permissions.some(
                    (userPermission) => userPermission.name === permission
                )
        );
    } else {
        return scopes.some((scope) => {
            const permissions = scopePermissions[scope];
            const userPermissions = userScopePermissions?.[scope];
            if (!userPermissions || !permissions) {
                return false;
            }
            return permissions.some(
                (permission) =>
                    permission === 'any' || userPermissions.includes(permission)
            );
        });
    }
};
