import {
    CheckIcon,
    CopyIcon,
    DeleteIcon,
    ExternalLinkIcon
} from '@chakra-ui/icons';
import {
    Flex,
    IconButton,
    Menu,
    MenuButton,
    MenuItem,
    MenuList
} from '@chakra-ui/react';
import { ComponentType } from 'common/enums';
import { Component } from 'common/types';
import { MdCancel } from 'react-icons/md';
import { PiResizeFill } from 'react-icons/pi';
import { SlOptionsVertical } from 'react-icons/sl';
import { useCanvas } from 'src/blueprint/pages/editor/EditorContext';

interface CanvasComponentMenuProps {
    component: Component;
    showMenu: boolean;
    showActions?: boolean;
}

const CanvasComponentMenu: React.FC<CanvasComponentMenuProps> = ({
    showMenu,
    component,
    showActions = false
}) => {
    const { actions, eventEmitter } = useCanvas();
    if (!showMenu && !showActions) {
        return null;
    }

    return (
        <div
            id="canvas-component-menu"
            style={{
                backgroundColor: showMenu || showActions ? '#EFEFEF' : 'transparent',
                position: 'absolute',
                width: component.w,
                top: -42,
                height: 40,
                justifyContent: 'flex-end',
                display: showMenu || showActions ? 'flex' : 'none',
                transform: `translate(${component.x}px, ${component.y}px)`,
                zIndex: 1000
            }}
        >
            {showMenu && (
                <Menu>
                    <MenuButton
                        as={IconButton}
                        aria-label="Options"
                        icon={<SlOptionsVertical />}
                        variant="icon"
                    />
                    <MenuList>
                        {[ComponentType.IMAGE, ComponentType.LOGO].includes(component.type) && <MenuItem
                            icon={<PiResizeFill />}
                            onClick={() => actions.resetAspectRatio(component.id)}
                        >
                            Reset aspect ratio
                        </MenuItem>}
                        <MenuItem
                            icon={<DeleteIcon />}
                            onClick={() => actions.deleteObject(component.id)}
                        >
                            Delete
                        </MenuItem>
                        <MenuItem
                            icon={<ExternalLinkIcon />}
                            onClick={() => actions.duplicateObject(component)}
                        >
                            Duplicate Object
                        </MenuItem>
                        <MenuItem
                            icon={<CopyIcon />}
                            onClick={() => actions.copyStyles(component)}
                        >
                            Copy Styles
                        </MenuItem>
                        <MenuItem
                            icon={<CopyIcon />}
                            onClick={() => actions.pasteStyles(component)}
                        >
                            Paste Styles
                        </MenuItem>
                    </MenuList>
                </Menu>
            )}
            {showActions && (
                <Flex>
                    <IconButton
                        aria-label="Cancel"
                        icon={<MdCancel />}
                        variant="icon"
                        onClick={() => eventEmitter.emit('cancel-inline-edit')}
                    />
                    <IconButton
                        aria-label="Save"
                        icon={<CheckIcon />}
                        variant="icon"
                        onClick={() => eventEmitter.emit('save-inline-edit')}
                    />
                </Flex>
            )}
        </div>
    );
};

export default CanvasComponentMenu;
