import { Box, useToast } from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useAppDispatch, useAppSelector } from "@hooks";
import { reportPageValidator } from "common/validators";
import { FormProvider, useForm } from "react-hook-form";
import AutoSave from "src/blueprint/pages/editor/components/AutoSave";
import { useCanvas } from "src/blueprint/pages/editor/EditorContext";
import { useSetComponentConfig } from "src/hooks/useSetComponentConfig";
import { selectActiveReportPage, selectComponentById, setActiveReportPageLayout } from "src/redux/features/blueprint/bluePrintSlice";
import { z } from "zod";
import BorderForm from "../../forms/BorderForm";
import ColorForm from "../../forms/ColorForm";
import PositionForm from "../../forms/PositionForm";
import { LayoutScale } from "common/enums";
import { useUpdateReportPageMutation } from "@api";
import React from "react";
import { assertIsDefined } from "src/templates/blueprint/utils";
import ScaleForm from "./ScaleForm";
import PageSizeForm from "./PageSizeForm";
import { deepMerge } from "src/utils/theme";

const formValidator = reportPageValidator.layout;

type FormData = z.infer<typeof formValidator>

const defaultValues: FormData = {
    pageHeight: 1200,
    pageWidth: 1920,
    background: 'white',
    scale: LayoutScale.FIXED
}

const PageStyle: React.FC = () => {
    const dispatch = useAppDispatch();
    const toast = useToast();
    const [updateReportPage] = useUpdateReportPageMutation();
    const page = useAppSelector(selectActiveReportPage);
    const { state: { activeSelectedComponentId } } = useCanvas();
    const componentToEdit = useAppSelector(selectComponentById(activeSelectedComponentId));

    const methods = useForm<FormData>({
        defaultValues: defaultValues,
        values: page?.layout as any,
        resolver: zodResolver(formValidator),
        mode: 'onBlur',
    })
    const onSubmit = async () => {
        const values = methods.getValues();
        const currentLayout = page?.layout;

        assertIsDefined(page, 'Page is not defined');
        assertIsDefined(currentLayout, 'Layout is not defined');
        dispatch(setActiveReportPageLayout(values));

        try {
            await updateReportPage({
                id: page.id,
                layout: values
            }).unwrap();
            toast({
                title: 'Changes has been successfully saved',
                status: 'success',
                position: 'top-right'
            });
        } catch (error) {
            dispatch(setActiveReportPageLayout(currentLayout));
            toast({
                title: 'There was an error with saving',
                description: 'Failed to save changes',
                status: 'error',
                duration: 9000,
                isClosable: true,
                position: 'top-right'
            });
        }
    }

    return <Box>
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <ScaleForm name={'scale'} />
                <ColorForm<FormData> name={"background"} />
                <PageSizeForm />
                <input hidden type="submit" />
                <AutoSave onSubmit={onSubmit} defaultValues={deepMerge(defaultValues, componentToEdit)} />
            </form>
        </FormProvider>
    </Box>
}

export default PageStyle;