import { RoleName } from 'common/roles';
import * as authService from './authService';
import { Team } from 'src/redux/types/api';

type Permission = {
    scope: string;
    name: string;
};

export type User = {
    permissions: Permission[];
    scopePermissions?: Record<string, string[]>;
    createdAt: string;
    email: string;
    firstName: string;
    lastName: string;
    id: number;
    phoneNumber?: string;
    role: RoleName;
    roleDisplayname: string;
    updatedAt: string;
    isReportingoAdmin?: boolean;
    teamId?: number;
    team?: Team;
};

export const getUserInfo = (): User | null => {
    const user = authService.getUserFromStorage();

    if (!user) {
        return null;
    }

    const scopePermissions: Record<string, string[]> = {};

    user.permissions.forEach(({ scope, name }) => {
        if (!scopePermissions[scope]) {
            scopePermissions[scope] = [name];
        } else {
            scopePermissions[scope].push(name);
        }
    });

    user.scopePermissions = scopePermissions;

    return user;
};
