const defaultMultiSelect = {
    properties: {
        style: {
            backgroundColor: '#ffffff'
        },
        controlStyle: {
            color: '#2b3674',
            '&:hover': {
                borderColor: 'transparent'
            },
            flexWrap: 'nowrap',
            textWrap: 'nowrap',
            backgroundColor: '#ffffff'
        },
        dropdownIndicatorStyle: {
            color: '#2b3674'
        },
        indicatorSeparatorStyle: {
            backgroundColor: '#2b3674'
        }
    }
};
export default defaultMultiSelect;
