import { Box, Button, Flex, FormControl, FormLabel, Input, Text } from '@chakra-ui/react';
import { ActionDelete, ActionEdit, HappyModal } from '@tasklogy/zircon-ui-components';
import { useModal } from 'src/hooks/useModal';
import { Team } from 'src/redux/types/api';

import { toast } from 'react-toastify';
import { generatePath, useNavigate } from 'react-router-dom';
import { PathNames } from 'src/router/router';
import { useState } from 'react';
import { useDeleteTeamMutation } from '@api';

interface Props {
    team: Team;
}

export default function ActionCell({ team }: Props) {
    const {
        open: openDeleteModal,
        close: closeDeleteModal,
        isOpen: isDeleteModalOpen
    } = useModal();
    const [passPhrase, setPassPhrase] = useState('');
    const [deleteTeam, { isLoading }] = useDeleteTeamMutation();

    const navigate = useNavigate();

    const handleEdit = () => {
        navigate(
            generatePath(PathNames.TEAM_CREATE_UPDATE, {
                teamId: team.id?.toString()
            })
        );
    };
    return (
        <Flex mr="1rem" justifyContent="end" minW={'5rem'}>
            <ActionEdit onClick={handleEdit} />
            <ActionDelete onClick={openDeleteModal} />
            <HappyModal isOpen={isDeleteModalOpen} onClose={closeDeleteModal}>
                <HappyModal.Body>
                    <Flex flexDir="column" gap="1rem">
                        <Box>
                            Are you sure you want to delete team
                            <br />{' '}
                            <Text as="span" fontWeight="bold">
                                {team.name}
                            </Text>{' '}
                            ?
                        </Box>
                        <Box>
                            <FormControl>
                                <FormLabel>Enter team name to confirm</FormLabel>
                                <Input
                                    placeholder={`Type '${team.name}'`}
                                    type="text"
                                    value={passPhrase}
                                    onChange={(e) => {
                                        setPassPhrase(e.target.value);
                                    }}
                                />
                            </FormControl>
                        </Box>
                    </Flex>
                </HappyModal.Body>
                <HappyModal.Footer>
                    <Button variant="outline" onClick={closeDeleteModal}>
                        No
                    </Button>
                    <Button
                        variant="solid"
                        colorScheme="error"
                        isLoading={isLoading}
                        isDisabled={passPhrase !== team.name}
                        onClick={async () => {
                            try {
                                await deleteTeam({ id: team.id, passPhrase }).unwrap();
                                closeDeleteModal();
                                toast.success('Team deleted successfully');
                            } catch (e) {
                                console.error(e);
                                toast.error('Failed to delete team');
                            }
                        }}
                    >
                        Delete
                    </Button>
                </HappyModal.Footer>
            </HappyModal>
        </Flex>
    );
}
