import { useCreateReportPageMutation, useUpdateReportMutation } from '@api';
import {
    Box,
    Flex,
    Icon,
    IconButton,
    Popover,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    Portal,
    Text,
    Tooltip,
    useDisclosure,
    useOutsideClick
} from '@chakra-ui/react';
import { useAppSelector } from '@hooks';
import { ReportPage } from 'common/types';
import { Reorder, useAnimationControls, useMotionValue } from 'framer-motion';
import { debounce } from 'lodash';
import { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { IoAddCircle } from 'react-icons/io5';
import { RiFile4Line } from 'react-icons/ri';
import { toast } from 'react-toastify';
import { useRaisedShadow } from 'src/editor/hooks/useRaisedShadow';
import { selectActiveReport } from 'src/redux/features/blueprint/bluePrintSlice';
import { assertIsDefined } from 'src/templates/blueprint/utils';
import { Page } from './Page';
import { TPage } from './types';

const liStyle: CSSProperties = {
    listStyle: 'none',
    padding: 0,
    margin: 0
};

const ulStyle: CSSProperties = {
    ...liStyle,
    position: 'relative',
    width: '300px',
    cursor: 'grab'
};

const ReorderItem = ({ item }: { item: TPage }) => {
    const y = useMotionValue(0);
    const boxShadow = useRaisedShadow(y);

    return (
        <Reorder.Item value={item} style={{ ...liStyle, boxShadow, y }}>
            <Page key={item.id} {...item} />
        </Reorder.Item>
    );
};

interface ReorderGroupProps {
    pages: ReportPage[];
    onReorderEnd: (items: ReportPage[]) => void;
}

const ReorderGroupPages: React.FC<ReorderGroupProps> = ({ pages, onReorderEnd }) => {
    const controls = useAnimationControls();
    const [items, setItems] = useState<ReportPage[]>(pages);

    useEffect(() => {
        setItems(pages);
    }, [pages]);

    const debouncedChangeHandler = useMemo(
        () =>
            debounce((value) => {
                onReorderEnd(value);
            }, 500),
        []
    );

    useEffect(() => {
        // fire if order has changed
        if (
            pages?.map((page) => page.id).join(',') !==
            items?.map((page) => page.id).join(',')
        ) {
            debouncedChangeHandler(items);
        }
        return () => debouncedChangeHandler.cancel();
    }, [items, debouncedChangeHandler]);

    return (
        <Reorder.Group
            axis="y"
            values={items}
            onReorder={setItems}
            style={ulStyle}
            animate={controls}
        >
            {items.map((item) => (
                <ReorderItem key={item.id} item={item} />
            ))}
        </Reorder.Group>
    );
};

interface PagesPopoverProps {
    isOpen: boolean;
    onToggle: () => void;
}

const PagesPopover: React.FC<PagesPopoverProps> = ({ isOpen, onToggle }) => {
    const [createPage] = useCreateReportPageMutation();
    const activeReport = useAppSelector(selectActiveReport);
    const [updateReport] = useUpdateReportMutation();

    assertIsDefined(activeReport);

    const handleCreateReportPage = useCallback(() => {
        createPage({
            displayName: 'Untitled',
            slug: `untitled-${activeReport.pages.length + 1}`,
            reportId: activeReport?.id
        });
    }, [createPage, activeReport?.id]);

    const handleReorderEnd = useCallback(
        async (items: ReportPage[]) => {
            try {
                await updateReport({
                    id: activeReport.id,
                    pageOrder: items.map((page) => page.id)
                }).unwrap();
                toast.success('Pages reordered');
            } catch (error) {
                console.error(error);
                toast.error('Failed to reorder pages');
            }
        },
        [updateReport, activeReport.id]
    );

    const pages = useMemo(() => {
        if (Array.isArray(activeReport?.pageOrder)) {
            const pagesFormPageOrder = activeReport.pageOrder
                .map((pageId) => activeReport.pages?.find((page) => page.id === pageId))
                .filter((page): page is ReportPage => page !== undefined) ?? [];
            const leftOverPages = activeReport.pages?.filter((page) => activeReport.pageOrder?.indexOf(page.id) === -1) ?? [];
            return [...pagesFormPageOrder, ...leftOverPages];
        } else {
            return activeReport?.pages ?? [];
        }
    }, [activeReport?.pageOrder, activeReport?.pages]);

    return (
        <Popover placement="right-start" closeOnBlur isOpen={isOpen}>
            <PopoverTrigger>
                <Box>
                    <Tooltip label="Pages" aria-label="Pages" placement="right">
                        <IconButton
                            variant="icon"
                            aria-label="Add new component"
                            onClick={onToggle}
                            icon={<Icon h="60px" as={RiFile4Line} />}
                        />
                    </Tooltip>
                </Box>
            </PopoverTrigger>

            <Portal>
                <Box zIndex={99999} position="relative">
                    <PopoverContent className="editor-sidebar-content">
                        <PopoverBody display="flex" flexDir="column" gap="0.5rem">
                            {isOpen && (
                                <ReorderGroupPages
                                    pages={pages}
                                    onReorderEnd={handleReorderEnd}
                                />
                            )}
                            <Flex
                                w="fit-content"
                                gap="10px"
                                alignItems="center"
                                onClick={() => handleCreateReportPage()}
                                _hover={{
                                    cursor: 'pointer',
                                    borderBottom: '1px solid rgba(28, 115, 232, 1)'
                                }}
                            >
                                <Icon color="rgba(28, 115, 232, 1)" as={IoAddCircle} />
                                <Text color="rgba(28, 115, 232, 1)">Add new page</Text>
                            </Flex>
                        </PopoverBody>
                    </PopoverContent>
                </Box>
            </Portal>
        </Popover>
    );
};

export default PagesPopover;
