import { useLocation, useNavigate } from 'react-router-dom';
import BlueprintWrapper from '../BlueprintWrapper';
import { PathNames } from './router';

import { useContext, useEffect } from 'react';
import { AuthContext } from 'src/AuthContext';
import { redirectToLogin } from 'src/auth/authService';
import Blueprint from '../blueprint';
import { ErrorBoundary } from '@sentry/react';
import ErrorFallback from 'src/ErrorFallback';

const PrivateWrapper = () => {
    const { user } = useContext(AuthContext);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (!user) {
            redirectToLogin();
        }
    }, []);

    useEffect(() => {
        if (user && location.pathname === '/') {
            navigate(PathNames.DASHBOARD_CLIENTS);
        }
    }, [location.pathname, user]);

    if (!user) {
        return null;
    }

    if (location.pathname === '/') {
        navigate(PathNames.DASHBOARD_REPORTS);
    }

    return (
        <ErrorBoundary fallback={<ErrorFallback />}>
            <BlueprintWrapper>
                <Blueprint />
            </BlueprintWrapper>
        </ErrorBoundary>
    );
};

export default PrivateWrapper;
