/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { SearchIcon } from '@chakra-ui/icons';
import { Box, Icon } from '@chakra-ui/react';
import { BlueprintMultiSelectProperties } from '@types';
import type {
    CSSProperties,
    KeyboardEvent,
    MutableRefObject,
    ReactElement,
    RefAttributes
} from 'react';
import { forwardRef, useCallback, useRef, useState } from 'react';
import type {
    ControlProps,
    GroupBase,
    MenuListProps,
    NoticeProps,
    OptionProps,
    Props,
    SelectInstance
} from 'react-select';
import ReactSelect, { components } from 'react-select';
import { JSX } from 'react/jsx-runtime';

export type CustomSelectProps<
    Option = unknown,
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>
> = Props<Option, IsMulti, Group> &
    RefAttributes<SelectInstance<Option, IsMulti, Group>> & {
        isCreatable?: boolean;
        isWhithCheckbox?: boolean;
        properties?: BlueprintMultiSelectProperties;
        menuListKeywordValue?: string;
        menuListKeywordLabel?: string;
    };

export type SelectComponent = <
    Option = unknown,
    IsMulti extends boolean = false,
    Group extends GroupBase<Option> = GroupBase<Option>
>(
    props: CustomSelectProps<Option, IsMulti, Group>
) => ReactElement;

interface Option1 {
    value: string;
    label: string;
}

interface CustomMenuListProps extends MenuListProps<Option1, true> {
    onNewMachineClick: (searchValue: string) => void;
    style?: CSSProperties;
    menuListKeywordValue?: string;
    menuListKeywordLabel?: string;
}

const NoOptionsMessage = (
    props: JSX.IntrinsicAttributes & NoticeProps<unknown, boolean, GroupBase<unknown>>
) => {
    return (
        <components.NoOptionsMessage {...props}>
            Žiadne výsledky
        </components.NoOptionsMessage>
    );
};

const InputOption = ({
    getStyles,
    isDisabled,
    isFocused,
    isSelected,
    children,
    innerProps,
    ...rest
}: OptionProps) => {
    const [_isActive, setIsActive] = useState(false);
    const onMouseDown = () => setIsActive(true);
    const onMouseUp = () => setIsActive(false);
    const onMouseLeave = () => setIsActive(false);

    const style = {
        alignItems: 'center',
        color: 'inherit',
        display: 'flex '
    };

    // prop assignment
    const props = {
        ...innerProps,
        onMouseDown,
        onMouseUp,
        onMouseLeave,
        style
    };

    return (
        <components.Option
            {...rest}
            isDisabled={isDisabled}
            isFocused={isFocused}
            isSelected={isSelected}
            getStyles={getStyles}
            innerProps={props}
            className="react-select-option"
        >
            <Box transform="scale(1.125)" mr="0.5rem" mt="2px">
                <input type="checkbox" checked={isSelected} onChange={() => {}} />
            </Box>

            {children}
        </components.Option>
    );
};

function CustomMenuList({
    children,
    onNewMachineClick,
    style,
    ...props
}: CustomMenuListProps) {
    const { menuListKeywordValue, menuListKeywordLabel } = props;
    const { selectProps, setValue, getValue } = props;

    return (
        <components.MenuList {...props}>
            {selectProps.inputValue && (
                <Box
                    w="100%"
                    py="0.625rem"
                    px="0.75rem"
                    transition="all 0.2s"
                    _hover={{
                        bg: 'blue.100'
                    }}
                    onClick={() => {
                        setValue(
                            [
                                ...getValue(),
                                {
                                    value: `${menuListKeywordValue ? menuListKeywordValue : 'contains:'}${selectProps.inputValue}`,
                                    label: `${menuListKeywordLabel ? menuListKeywordLabel : 'Obsahuje:'} ${selectProps.inputValue}`
                                }
                            ],
                            'select-option'
                        );
                        onNewMachineClick(selectProps.inputValue);
                    }}
                    style={style}
                >
                    {menuListKeywordLabel ? menuListKeywordLabel : 'Obsahuje:'}{' '}
                    {selectProps.inputValue && `${selectProps.inputValue}`}
                </Box>
            )}
            {children}
        </components.MenuList>
    );
}

const Control = ({ children, ...props }: ControlProps<any>) => {
    // @ts-ignore
    const properties  = props?.selectProps?.properties;
    const controlStyle = properties?.controlStyle ?? {};
    const showIcon = properties?.showIcon ?? false;
    return (
        <components.Control {...props}>
            {showIcon && <Icon as={SearchIcon} color={controlStyle?.color} />}
            {children}
        </components.Control>
    );
};

const CustomSelect = forwardRef(
    <Option, IsMulti extends boolean, Group extends GroupBase<Option>>(
        props: CustomSelectProps<Option, IsMulti, Group>,
        ref:
            | ((instance: SelectInstance<Option, IsMulti, Group> | null) => void)
            | MutableRefObject<SelectInstance<Option, IsMulti, Group> | null>
            | null
    ) => {
        const { menuListKeywordLabel, menuListKeywordValue } = props;
        const ref2 = useRef<any>();
        const { optionStyle } = props.properties ?? {};
        const [searchText, setSearchText] = useState('');
        const MenuList = useCallback((props: MenuListProps<Option1, true>) => {
            return (
                <CustomMenuList
                    {...props}
                    style={optionStyle}
                    menuListKeywordLabel={menuListKeywordLabel}
                    menuListKeywordValue={menuListKeywordValue}
                    onNewMachineClick={() => {
                        ref2.current?.blur();
                    }}
                />
            );
        }, []);

        const { isCreatable, isWhithCheckbox } = props;

        const customComponents = {
            NoOptionsMessage,
            ...(isCreatable && { MenuList }),
            ...(isWhithCheckbox && { Option: InputOption }),
            ...(!isWhithCheckbox && {
                Option: (props: OptionProps) => (
                    <components.Option {...props} className="react-select-option" />
                )
            }),
            Control
        };

        const onInputChange = (inputValue: string) => {
            setSearchText(inputValue);
        };

        const handleKeyDown = (event: KeyboardEvent) => {
            if (event.key === 'Enter') {
                event.preventDefault();
                const select = ref2.current;
                if (select) {
                    const currentSelectValue = select.getValue();
                    select.setValue([
                        ...currentSelectValue,
                        {
                            value: `contains:${searchText}`,
                            label: `Obsahuje: ${searchText}`
                        }
                    ]);
                }
            }
        };

        return (
            <ReactSelect
                ref={ref ?? ref2}
                className="react-select-container"
                styles={{
                    container: (provided) => ({
                        ...provided,
                        height: 'inherit',
                    }),
                    control: (provided, state) => ({
                        ...provided,
                        height: 'inherit',
                        width: '100%',
                        borderRadius: '16px',
                        padding: '8px 8px',
                        cursor: 'pointer',
                        boxShadow: state.isFocused ? `0 0 0 1px "#2B6CB0"` : 'none',
                        '&:hover': {
                            borderColor: 'inherit'
                        },
                        ...props.properties?.controlStyle
                    }),
                    option: (provided) => ({
                        ...provided,
                        ...props.properties?.optionStyle
                    }),
                    menuList: (provided) => ({
                        ...provided,
                        padding: '0',
                        ...props.properties?.menuListStyle
                    }),
                    placeholder: (provided) => ({
                        ...provided,
                        ...props.properties?.placeholderStyle
                    }),
                    multiValueLabel: (provided) => ({
                        ...provided,
                        ...props.properties?.multiValueLabelStyle
                    }),
                    multiValue: (provided) => ({
                        ...provided,
                        maxWidth: '48%',
                        ...props.properties?.multiValueStyle
                    }),
                    valueContainer: (provided) => ({
                        ...provided,
                        ...props.properties?.valueContainerStyle
                    }),
                    singleValue: (provided) => ({
                        ...provided,
                        ...props.properties?.singleValueStyle
                    }),
                    multiValueRemove: (provided) => ({
                        ...provided,
                        ...props.properties?.multiValueRemoveStyle
                    }),
                    indicatorSeparator: (provided) => ({
                        ...provided,
                        ...props.properties?.indicatorSeparatorStyle
                    }),
                    clearIndicator: (provided) => ({
                        ...provided,
                        ...props.properties?.clearIndicatorStyle
                    }),
                    dropdownIndicator: (provided) => ({
                        ...provided,
                        ...props.properties?.dropdownIndicatorStyle
                    }),
                    menu: (provided) => ({
                        ...provided,
                        ...props.properties?.menuStyle
                    }),
                }}
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore - customComponents
                components={customComponents}
                {...props}
                onKeyDown={handleKeyDown}
                onInputChange={onInputChange}
            />
        );
    }
) as SelectComponent;

// @ts-expect-error - displayName
CustomSelect.displayName = 'CustomSelect';

export default CustomSelect;
