import { Link as ChakraLink, Text, Textarea } from '@chakra-ui/react';
import { generatePath, NavLink } from 'react-router-dom';

import { useAppSelector } from '@hooks';
import { ComponentUI } from '@types';
import { Component } from 'common/types';
import React, { useEffect, useState } from 'react';
import { useCanvas } from 'src/blueprint/pages/editor/EditorContext';
import { useSetComponentConfig } from 'src/hooks/useSetComponentConfig';
import {
    selectActiveReport,
    selectComponentById
} from 'src/redux/features/blueprint/bluePrintSlice';
import { PathNames } from 'src/router/router';
import { jsonStyleToChakraProps } from 'src/utils/theme';
import { assertIsDefined } from '../utils';

export default function NavigationButton({ properties, id }: ComponentUI) {
    const componentToEdit = useAppSelector(selectComponentById(id)) as Component;
    const textValue = componentToEdit?.properties?.text ?? '';
    const [inputText, setInputText] = useState(textValue);
    const { url, text, style, pageId } = properties;
    const activeReport = useAppSelector(selectActiveReport);
    const { state, eventEmitter, actions } = useCanvas();
    const isSharedReport = !state.useEditorReport;
    const setComponentConfig = useSetComponentConfig();

    useEffect(() => {
        setInputText(textValue);
    }, [textValue]);

    const handleSave = React.useCallback(async () => {
        assertIsDefined(componentToEdit);
        const component = {
            ...componentToEdit,
            properties: {
                ...componentToEdit.properties,
                text: inputText
            }
        } as Component;
        await setComponentConfig(component);
        actions.setActiveSelection(new Set());
    }, [componentToEdit, inputText, setComponentConfig, actions]);

    eventEmitter.useSubscription((val) => {
        if (val === 'save-inline-edit') {
            handleSave();
        }
    });

    if (state.enabledInlineEditor && state.activeSelectedComponentId === id) {
        return (
            <Textarea
                variant={'unstyled'}
                style={{
                    ...properties.style,
                    height: componentToEdit.h,
                    width: componentToEdit.w
                }}
                value={inputText}
                onChange={(e) => setInputText(e.target.value)}
            />
        );
    }

    const buttonStyle = {
        width: '100%',
        ...style
    };
    const { '&:active': activeStyle, ...restButtonStyle } = buttonStyle;

    const activeButtonStyle = {
        ...restButtonStyle,
        ...activeStyle
    };

    const componentStyleProps = jsonStyleToChakraProps(buttonStyle);

    if (!isSharedReport) {
        return (
            <Text as="a" {...componentStyleProps}>
                {text}
            </Text>
        );
    }

    if (Boolean(pageId)) {
        const page = activeReport?.pages.find((page) => {
            return page.id === pageId;
        });
        const to = `${state.reportBasePathName}/${activeReport?.slug}/${page?.slug}`;
        return (
            <NavLink
                to={to}
                style={(props) => (props.isActive ? activeButtonStyle : restButtonStyle)}
            >
                {text}
            </NavLink>
        );
    }

    if (url) {
        return (
            <ChakraLink href={url} target="_blank" {...componentStyleProps}>
                {text}
            </ChakraLink>
        );
    }

    return (
        <Text as="a" {...componentStyleProps}>
            {text}
        </Text>
    );
}
