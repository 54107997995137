import { Box, Flex } from '@chakra-ui/react';
import { NavLink } from 'react-router-dom';

import { useAppSelector } from '@hooks';
import { ComponentUI } from '@types';

import { useCanvas } from 'src/blueprint/pages/editor/EditorContext';
import { selectActiveReport } from 'src/redux/features/blueprint/bluePrintSlice';
import { ReportPage } from 'common/types';
import { useMemo } from 'react';

const DEFAULT_BUTTON_STYLE = {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'center',
    height: '40px'
};

export default function Navbar({ properties }: ComponentUI) {
    const activeReport = useAppSelector(selectActiveReport);
    const { state } = useCanvas();
    const inEditor = state.useEditorReport

    const buttonStyle = {
        ...DEFAULT_BUTTON_STYLE,
        ...properties.style.buttonStyle
    };

    const { active: activeStyle, ...restButtonStyle } = buttonStyle;

    const activeButtonStyle = {
        ...restButtonStyle,
        ...activeStyle
    };

    const pages = useMemo(() => {
        if (Array.isArray(activeReport?.pageOrder)) {
            return activeReport.pageOrder
                .map((pageId) => activeReport.pages.find((page) => page.id === pageId))
                .filter((page): page is ReportPage => Boolean(page?.isActive)) ?? [];
        } else {
            return activeReport?.pages ?? [];
        }
    }, [activeReport?.pageOrder, activeReport?.pages]);


    return (
        <Box style={properties.style}>
            <Flex
                flexDir="column"
                h="100%"
                maxH="100%"
                overflow="hidden"
                style={properties.style.containerStyle}
                {...(inEditor ? { pointerEvents: 'none' } : {})}
            >
                {pages.map((page) => {
                    const to = `${state.reportBasePathName}/${activeReport?.slug}/${page?.slug}`;

                    return (
                        <NavLink
                            to={to}
                            style={(props) =>
                                props.isActive ? activeButtonStyle : restButtonStyle
                            }
                        >
                            {page.displayName}
                        </NavLink>
                    );
                })}
            </Flex>
        </Box>
    );
}
