import { Box, Flex } from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useAppSelector } from "@hooks";
import { reportPageValidator } from "common/validators";
import { FormProvider, useForm } from "react-hook-form";
import AutoSave from "src/blueprint/pages/editor/components/AutoSave";
import { useCanvas } from "src/blueprint/pages/editor/EditorContext";
import { useSetComponentConfig } from "src/hooks/useSetComponentConfig";
import { selectComponentById } from "src/redux/features/blueprint/bluePrintSlice";
import { deepMerge } from "src/utils/theme";
import { z } from "zod";
import { FormAccordion, FormAccordionItem } from "../../Accordion";
import ColorForm from "../../forms/ColorForm";
import PositionForm from "../../forms/PositionForm";
import TextForm from "../../forms/TextForm";
import CardForm from "../../forms/CardForm";

const formValidator = reportPageValidator.baseComponent.extend({
    properties: z.object({
        style: z.object({
            gap: z.number().optional(),
            margin: z.string().optional(),
            display: z.string().optional(),
            padding: z.string().optional(),
            borderRadius: z.string().optional(),
            flexDirection: z.string().optional(),
            paddingInline: z.string().optional(),
            background: z.string().optional(),
        }),
        label: z.object({
            color: z.string().optional(),
            fontSize: z.string().optional(),
            lineHeight: z.string().optional()
        }),
        value: z.object({
            color: z.string().optional(),
            fontSize: z.string().optional(),
            marginTop: z.string().optional(),
            fontWeight: z.union([z.string(), z.number()]).optional(),
            lineHeight: z.string().optional(),
            whiteSpace: z.string().optional()
        }),
    })
})

type FormData = z.infer<typeof formValidator>

const defaultValues: Pick<FormData, 'properties'> = {
    properties: {
        style: {
            gap: 0,
            margin: "0.5rem",
            display: "flex",
            padding: "0.875rem 0.75rem 0.875rem 0.75rem",
            borderRadius: "20px",
            flexDirection: "column",
            paddingInline: "1.25rem",
            background: "white"
        },
        label: {
            color: "rgb(112, 126, 174)",
            fontSize: "16px",
            lineHeight: "20px"
        },
        value: {
            color: "rgb(43, 54, 116)",
            fontSize: "20px",
            marginTop: "0.25rem",
            fontWeight: "400",
            lineHeight: "36px",
            whiteSpace: "nowrap"
        }
    }
};

const SingleValueStyle: React.FC = () => {
    const { state: { activeSelectedComponentId } } = useCanvas();
    const componentToEdit = useAppSelector(selectComponentById(activeSelectedComponentId));
    const setComponentConfig = useSetComponentConfig();

    const methods = useForm<FormData>({
        defaultValues: defaultValues,
        values: componentToEdit as any,
        resolver: zodResolver(formValidator),
        mode: 'onBlur',
    })

    const onSubmit = async () => {
        const values = methods.getValues();
        setComponentConfig(values as any);
    }

    return <Box>
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <PositionForm />
                <FormAccordion>
                    <FormAccordionItem title="Label">
                        <Flex flexDir='column'>
                            <TextForm<FormData> objPath={'properties.label'} />
                        </Flex>
                    </FormAccordionItem>
                    <FormAccordionItem title="Value">
                        <Flex flexDir='column'>
                            <TextForm<FormData> objPath={'properties.value'} />
                        </Flex>
                    </FormAccordionItem>
                </FormAccordion>
                <CardForm<FormData> objPath={"properties.style"} />
                <input hidden type="submit" />
                <AutoSave onSubmit={onSubmit} defaultValues={deepMerge(defaultValues, componentToEdit)} />
            </form>
        </FormProvider>
    </Box>
}

export default SingleValueStyle;