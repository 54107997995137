import { BsDatabaseCheck } from 'react-icons/bs';
import { FiCodepen, FiLock } from 'react-icons/fi';
import { IoMdHome } from 'react-icons/io';
import { MdTrolley } from 'react-icons/md';
import { generatePath, Link, Outlet } from 'react-router-dom';
import ClientDetail from 'src/blueprint/pages/ClientDetail';
import TeamCreate from 'src/pages/Teams/TeamCreate';
import { TeamDetail } from 'src/pages/Teams/TeamDetail';
import { TeamIndex } from 'src/pages/Teams/TeamIndex';
import OutletLayout from '../blueprint/components/OutletLayout';
import ClientCreateReport from '../blueprint/pages/ClientCreateReport/ClientCreateReport';
import ClientReports from '../blueprint/pages/ClientReports';
import Clients from '../blueprint/pages/Clients/Clients';
import { CrumbBlueprintReport, CrumbClientDetail, TeamDetailScrumb } from './Crumbs';
import { PathNames } from './router';
import { Route } from './types';

import { GrTemplate } from 'react-icons/gr';
import ReportCreated from 'src/blueprint/pages/CreateReport/ReportCreated';
import SelectedTemplate from 'src/blueprint/pages/CreateReport/SelectedTemplate/SelectedTemplate';
import TemplatesList from 'src/blueprint/pages/CreateReport/TemplatesList/TemplatesList';
import { LayoutSettings } from 'src/pages/Settings/LayoutSettings';
import OutletLayoutSettings from 'src/pages/Settings/OutletLayoutSettings';
import { CompanyData } from 'src/pages/Settings/ProjectSettings/CompanyData';
import { TeamMembers } from 'src/pages/Settings/ProjectSettings/TeamMembers';
import { PersonalData } from 'src/pages/Settings/UserSettings/PersonalData';
import SourceAuthIndex from 'src/pages/SourceAuths/SourceAuthIndex';
import TemplatesIndex from 'src/pages/Templates/Index';

import Reports from '../blueprint/pages/Reports';
import Editor from '../blueprint/pages/editor';
import PrivateWrapper from './PrivateWrapper';
import { FaBook } from 'react-icons/fa6';
import Documentation from 'src/pages/Documentation';
import { Box } from '@chakra-ui/react';
import ProgressiveEditor from 'src/editor';
import BlueprintWrapper from 'src/BlueprintWrapper';
import LivePreview from 'src/pages/LivePreview';

const privateRoutes = (): Route[] => {
    return [
        {
            path: PathNames.DASHBOARD,
            element: <PrivateWrapper />,
            children: [
                {
                    path: PathNames.DASHBOARD_REPORTS,
                    element: <Outlet />,
                    handle: {
                        navItem: {
                            name: 'Reports',
                            icon: IoMdHome
                        },
                        crumb: ({ pathname }) => <Link to={pathname}>Reports</Link>,
                        auth: {
                            withScopePermissions: { any: ['view-report'] }
                        }
                    },
                    children: [
                        {
                            path: PathNames.DASHBOARD_REPORTS,
                            element: (
                                <OutletLayout>
                                    <Reports />
                                </OutletLayout>
                            )
                        },
                        {
                            path: PathNames.DASHBOARD_REPORT,
                            element: <Editor />,
                            handle: {
                                crumb: (props: any) => <CrumbBlueprintReport {...props} />
                            }
                        }
                    ]
                },
                {
                    path: PathNames.DASHBOARD_CLIENTS,
                    element: <Outlet />,
                    handle: {
                        navItem: {
                            name: 'Clients',
                            icon: MdTrolley
                        },
                        crumb: ({ pathname }) => <Link to={pathname}>Clients</Link>,
                        auth: {
                            withScopePermissions: {
                                team: ['any'],
                                assignedClient: ['any']
                            }
                        }
                    },
                    children: [
                        {
                            path: `${PathNames.DASHBOARD_CLIENTS}/`,
                            element: (
                                <OutletLayout>
                                    <Clients />
                                </OutletLayout>
                            )
                        },

                        {
                            path: PathNames.CREATE_CLIENT,
                            element: (
                                <OutletLayout>
                                    <ClientDetail />
                                </OutletLayout>
                            ),
                            handle: {
                                crumb: ({ pathname }) => (
                                    <Link to={pathname}>Create Client</Link>
                                )
                            }
                        },
                        {
                            path: PathNames.CLIENT_DETAIL,
                            element: <Outlet />,
                            children: [
                                {
                                    path: `${PathNames.CLIENT_DETAIL}/`,
                                    element: <Outlet />,
                                    handle: {
                                        crumb: (props) => <CrumbClientDetail {...props} />
                                    },
                                    children: [
                                        {
                                            path: `${PathNames.CLIENT_DETAIL}/`,
                                            element: (
                                                <OutletLayout>
                                                    <ClientDetail />
                                                </OutletLayout>
                                            )
                                        },
                                        {
                                            path: PathNames.DASHBOARD_CLIENT_REPORTS,
                                            element: (
                                                <OutletLayout>
                                                    <ClientReports />
                                                </OutletLayout>
                                            ),
                                            handle: {
                                                crumb: ({ pathname }) => (
                                                    <Link to={pathname}>Reports</Link>
                                                )
                                            }
                                        },
                                        {
                                            path: PathNames.CLIENT_CREATE_REPORT,
                                            element: (
                                                <OutletLayout>
                                                    <ClientCreateReport />
                                                </OutletLayout>
                                            ),
                                            handle: {
                                                crumb: ({ pathname }) => (
                                                    <Link to={pathname}>
                                                        Create report
                                                    </Link>
                                                )
                                            }
                                        },
                                        {
                                            path: PathNames.CLIENT_CREATE_REPORT_SIMPLE,
                                            element: (
                                                <OutletLayout>
                                                    <TemplatesList />
                                                </OutletLayout>
                                            ),
                                            handle: {
                                                crumb: ({ pathname }) => (
                                                    <Link to={pathname}>
                                                        Create report
                                                    </Link>
                                                )
                                            }
                                        },
                                        {
                                            path: PathNames.CLIENT_CREATE_REPORT_SIMPLE_TEMPLATE_REPORT_VIEW,
                                            element: (
                                                <OutletLayout>
                                                    <SelectedTemplate />
                                                </OutletLayout>
                                            ),
                                            handle: {
                                                crumb: ({ pathname }) => (
                                                    <Link to={pathname}>
                                                        Create report
                                                    </Link>
                                                )
                                            }
                                        },
                                        {
                                            path: PathNames.CLIENT_CREATE_REPORT_SIMPLE_TEMPLATE_CREATED,
                                            element: (
                                                <OutletLayout>
                                                    <ReportCreated />
                                                </OutletLayout>
                                            ),
                                            handle: {
                                                crumb: ({ params }) => (
                                                    <Link
                                                        to={`${generatePath(
                                                            PathNames.CLIENT_DETAIL,
                                                            {
                                                                clientId:
                                                                    params?.clientId!
                                                            }
                                                        )}/?tab=reports`}
                                                    >
                                                        Go back to reports
                                                    </Link>
                                                )
                                            }
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                },
                {
                    path: PathNames.DASHBOARD_SOURCE_AUTHS,
                    element: <Outlet />,
                    handle: {
                        navItem: {
                            name: 'My Connections',
                            icon: BsDatabaseCheck
                        },
                        crumb: ({ pathname }) => (
                            <Link to={pathname}>My Connections</Link>
                        ),
                        auth: {
                            withScopePermissions: { any: ['any'] }
                        }
                    },
                    children: [
                        {
                            path: PathNames.DASHBOARD_SOURCE_AUTHS,
                            element: (
                                <OutletLayout>
                                    <SourceAuthIndex />
                                </OutletLayout>
                            )
                        }
                    ]
                },
                {
                    path: PathNames.TEAMS,
                    element: <Outlet />,
                    handle: {
                        crumb: () => <Link to={PathNames.TEAMS}>Teams</Link>,
                        navItem: {
                            name: 'Teams',
                            icon: FiCodepen
                        },
                        name: 'Teams',
                        icon: FiCodepen,
                        auth: {
                            hasRoles: ['reportingo-admin', 'super-admin']
                        }
                    },
                    children: [
                        {
                            path: `${PathNames.TEAMS}/`,
                            element: (
                                <OutletLayout>
                                    <TeamIndex />
                                </OutletLayout>
                            )
                        },
                        {
                            path: PathNames.TEAM_DETAIL,
                            element: <Outlet />,
                            children: [
                                {
                                    path: `${PathNames.TEAM_DETAIL}/`,
                                    element: <Outlet />,
                                    handle: {
                                        crumb: (props) => <TeamDetailScrumb {...props} />
                                    },
                                    children: [
                                        {
                                            path: `${PathNames.TEAM_DETAIL}/`,
                                            element: (
                                                <OutletLayout>
                                                    <TeamDetail />
                                                </OutletLayout>
                                            )
                                        },
                                        {
                                            path: PathNames.TEAM_CREATE_CLIENT,
                                            element: (
                                                <OutletLayout>
                                                    <ClientDetail />
                                                </OutletLayout>
                                            ),
                                            handle: {
                                                crumb: ({ pathname }) => (
                                                    <Link to={pathname}>
                                                        Create client
                                                    </Link>
                                                )
                                            }
                                        },
                                        {
                                            path: PathNames.TEAM_CLIENT_DETAIL,
                                            element: <Outlet />,
                                            handle: {
                                                crumb: (props) => (
                                                    <CrumbClientDetail {...props} />
                                                )
                                            },
                                            children: [
                                                {
                                                    path: `${PathNames.TEAM_CLIENT_DETAIL}/`,
                                                    element: (
                                                        <OutletLayout>
                                                            <ClientDetail />
                                                        </OutletLayout>
                                                    )
                                                }
                                            ]
                                        }
                                    ]
                                }
                            ]
                        },
                        {
                            path: PathNames.TEAM_CREATE_UPDATE,
                            element: (
                                <OutletLayout>
                                    <TeamCreate />
                                </OutletLayout>
                            ),
                            handle: {
                                crumb: () => <Link to="#">Create team</Link>
                            }
                        }
                    ]
                },
                {
                    path: PathNames.TEMPLATES,
                    element: <Outlet />,
                    handle: {
                        navItem: { name: 'Templates', icon: GrTemplate },
                        crumb: ({ pathname }) => <Link to={pathname}>Templates</Link>,
                        auth: {
                            hasRoles: ['reportingo-admin', 'super-admin']
                        }
                    },
                    children: [
                        {
                            path: `${PathNames.TEMPLATES}/`,
                            element: (
                                <OutletLayout>
                                    <TemplatesIndex />
                                </OutletLayout>
                            )
                        }
                    ]
                },
                {
                    path: PathNames.USER_SETTINGS,
                    element: <LayoutSettings />,
                    handle: {
                        crumb: () => (
                            <Link to={PathNames.USER_SETTINGS_PERSONAL_DATA}>
                                Settings
                            </Link>
                        ),
                        navItem: {
                            name: 'Settings',
                            icon: FiLock
                        },
                        name: 'Settings',
                        icon: FiLock,
                        auth: {
                            withScopePermissions: { any: ['any'] }
                        }
                    },
                    children: [
                        {
                            path: PathNames.USER_SETTINGS + '/',
                            element: (
                                <OutletLayoutSettings>
                                    <PersonalData />
                                </OutletLayoutSettings>
                            )
                        },
                        {
                            path: PathNames.USER_SETTINGS_PERSONAL_DATA,
                            element: (
                                <OutletLayoutSettings>
                                    <PersonalData />
                                </OutletLayoutSettings>
                            ),
                            handle: {
                                crumb: () => (
                                    <Link to={PathNames.USER_SETTINGS_PERSONAL_DATA}>
                                        Personal data
                                    </Link>
                                ),
                                name: 'Personal data',
                                auth: {
                                    withScopePermissions: { any: ['any'] }
                                }
                            }
                        },
                        {
                            path: PathNames.USER_SETTINGS_COMPANY_DATA,
                            element: (
                                <OutletLayoutSettings>
                                    <CompanyData />
                                </OutletLayoutSettings>
                            ),
                            handle: {
                                crumb: () => (
                                    <Link to={PathNames.USER_SETTINGS_COMPANY_DATA}>
                                        Company data
                                    </Link>
                                ),
                                name: 'Company data',
                                auth: {
                                    hasRoles: ['team-admin']
                                }
                            }
                        },
                        {
                            path: PathNames.USER_SETTINGS_TEAM_MEMBERS,
                            element: (
                                <OutletLayoutSettings>
                                    <TeamMembers />
                                </OutletLayoutSettings>
                            ),
                            handle: {
                                crumb: () => (
                                    <Link to={PathNames.USER_SETTINGS_TEAM_MEMBERS}>
                                        Team members
                                    </Link>
                                ),
                                name: 'Team members',
                                auth: {
                                    hasRoles: ['team-admin']
                                }
                            }
                        }
                    ]
                },
                {
                    path: PathNames.DASHBOARD_DOCUMENTATION,
                    element: <Outlet />,
                    handle: {
                        navItem: {
                            name: 'Documentation',
                            icon: FaBook
                        },
                        crumb: ({ pathname }) => <Link to={pathname}>Documentation</Link>,
                        auth: {
                            withScopePermissions: { any: ['any'] }
                        }
                    },
                    children: [
                        {
                            path: PathNames.DASHBOARD_DOCUMENTATION,
                            element: (
                                <Box m={0} minH="calc(100vh - 6rem)" bg="#F8F8F8">
                                    <Documentation />
                                </Box>
                            )
                        }
                    ]
                }
            ]
        },
        {
            path: PathNames.EDITOR,
            element: <BlueprintWrapper><ProgressiveEditor /></BlueprintWrapper>,
        },
        {
            path: PathNames.LIVE_PREVIEW,
            element: <BlueprintWrapper><LivePreview /></BlueprintWrapper>,
        },
    ];
};

export default privateRoutes;
