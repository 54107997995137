import { Flex } from "@chakra-ui/react"
import { getProperty } from 'dot-prop'
import { Controller, FieldError, FieldValues, Path, PathValue, useFormContext } from "react-hook-form"
import ColorPickerInput from "../inputs/ColorPickerInput"
import FormNumberInput from "../inputs/Number"
import FormPropertyRow from "../layout/FormPropertyRow"
import FormTitle from "../layout/FormTitle"
import { parsePx, toPx } from "src/templates/blueprint/utils"
import { useId } from "react"

const BorderForm = <T extends FieldValues>({ objPath }: { objPath: Path<T> }) => {
    const methods = useFormContext<T>();
    const { errors } = methods.formState;
    const id = useId();
    return <Flex flexDir='column'>
        <Flex my={4}>
            <FormTitle title="Border" />
        </Flex>
        <FormPropertyRow>
            <Flex gap={'1rem'} alignItems={'center'}>
                <Controller
                    name={`${objPath}.borderColor` as Path<T>}
                    defaultValue={'transparent' as PathValue<T, Path<T>>}
                    control={methods.control}
                    render={({ field }) => <ColorPickerInput id={id} colorPickerProps={{ hideColorTypeBtns: true }} value={field.value ?? 'black'} onChange={(e) => field.onChange({ target: { value: e.target.value } })} error={getProperty(errors, `${objPath}.borderColor`) as FieldError | undefined} width='150px' type="text" />}
                />
            </Flex>
        </FormPropertyRow>
        <FormPropertyRow>
            <Controller
                name={`${objPath}.borderWidth` as Path<T>}
                control={methods.control}
                defaultValue={'1px' as PathValue<T, Path<T>>}
                render={({ field }) =>
                    <FormNumberInput label="Width" value={parsePx(field.value)} onChange={(e) => field.onChange({ target: { value: toPx(e.target.value) } })} error={getProperty(errors, `${objPath}.borderWidth`) as FieldError | undefined} width='5rem' type="number" />
                }
            />
        </FormPropertyRow>
        <FormPropertyRow>

            <Controller
                name={`${objPath}.borderRadius` as Path<T>}
                control={methods.control}
                defaultValue={'0px' as PathValue<T, Path<T>>}
                render={({ field }) =>
                    <FormNumberInput label="Radius" value={parsePx(field.value)} onChange={(e) => field.onChange({ target: { value: toPx(e.target.value) } })} error={getProperty(errors, `${objPath}.borderRadius`) as FieldError | undefined} width='6rem' type="number" />
                } />
        </FormPropertyRow>
    </Flex>
}

export default BorderForm;