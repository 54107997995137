import { FieldValues, Path } from "react-hook-form"
import BorderForm from "../BorderForm"
import DropShadowForm from "../DropShadow"
import ColorForm from "../ColorForm"

const CardForm = <T extends FieldValues>({ objPath }: { objPath: Path<T> }) => {
    return <>
        <DropShadowForm objPath={objPath} />
        <BorderForm objPath={objPath} />
        <ColorForm<FormData> name={`${objPath}.background`} withGradient />
    </>
}

export default CardForm;