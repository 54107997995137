import { Box } from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useAppSelector } from "@hooks";
import { reportPageValidator } from "common/validators";
import { FormProvider, useForm } from "react-hook-form";
import AutoSave from "src/blueprint/pages/editor/components/AutoSave";
import { useCanvas } from "src/blueprint/pages/editor/EditorContext";
import { useSetComponentConfig } from "src/hooks/useSetComponentConfig";
import { selectComponentById } from "src/redux/features/blueprint/bluePrintSlice";
import { z } from "zod";
import BorderForm from "../../forms/BorderForm";
import ColorForm from "../../forms/ColorForm";
import PositionForm from "../../forms/PositionForm";
import { deepMerge } from "src/utils/theme";
import BackgroundImageForm from "../../forms/BackgroundImageForm";

const formValidator = reportPageValidator.baseComponent.extend({
    properties: z.object({
        style: z.object({
            background: z.string(),
        }),
    }),
})

type FormData = z.infer<typeof formValidator>

const defaultValues: Pick<FormData, 'properties'> = {
    properties: {
        style: {
            background: 'black',
        }
    }
}

const RectangleStyle: React.FC = () => {
    const { state: { activeSelectedComponentId } } = useCanvas();
    const componentToEdit = useAppSelector(selectComponentById(activeSelectedComponentId));
    const setComponentConfig = useSetComponentConfig();

    const methods = useForm<FormData>({
        defaultValues: defaultValues,
        values: componentToEdit as any,
        resolver: zodResolver(formValidator),
        mode: 'onBlur',
    })
    const onSubmit = async () => {
        const values = methods.getValues();
        setComponentConfig(values as any);
    }

    return <Box>
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
                <PositionForm />
                <ColorForm<FormData> name={"properties.style.background"} />
                <BorderForm<FormData> objPath={'properties.style'} />
                <BackgroundImageForm<FormData> objPath={'properties.style'} />
                <input hidden type="submit" />
                <AutoSave onSubmit={onSubmit} defaultValues={deepMerge(defaultValues, componentToEdit)} />
            </form>
        </FormProvider>
    </Box>
}

export default RectangleStyle;