import {
    Avatar,
    Box,
    Flex,
    HStack,
    IconButton,
    Menu,
    MenuButton,
    MenuDivider,
    MenuItem,
    MenuList,
    Text,
    VStack,
    useColorModeValue
} from '@chakra-ui/react';
import { AuthContext } from 'src/AuthContext';
import { PathNames } from 'src/router/router';
import { AppBreadcrumbs } from '@tasklogy/zircon-ui-components';
import { useContext } from 'react';
import { FiBell, FiChevronDown } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { redirectToLogout } from 'src/auth/authService';
import Notifications from './Notifications';

const Header = () => {
    const { user } = useContext(AuthContext);
    const navigate = useNavigate();

    const isTeamAdmin = user?.role === 'team-admin';

    return (
        <Flex
            px={{ base: 4, md: 8 }}
            height="20"
            alignItems="center"
            bg="white"
            w="100%"
            borderBottomWidth="1px"
            borderBottomColor="gray.100"
            boxShadow="0px 4px 5px #00000012"
            justifyContent={{ base: 'space-between', md: 'space-between' }}
        >
            <AppBreadcrumbs />
            <HStack spacing={{ base: '0', md: '6' }}>
                <Notifications />
                {user?.team?.name && (
                    <HStack spacing="8px">
                        <Avatar name={`${user?.team?.name}`} />
                        <VStack alignItems="flex-start" spacing="0px">
                            <Text fontSize="0.65rem">{`Team:`}</Text>
                            <Text fontWeight="bold">{`${user?.team?.name}`} </Text>
                        </VStack>
                    </HStack>
                )}

                <Flex alignItems={'center'}>
                    <Menu>
                        <MenuButton
                            py={2}
                            transition="all 0.3s"
                            _hover={{
                                borderColor: 'transparent'
                            }}
                            _focus={{ boxShadow: 'none', outline: 'none' }}
                        >
                            <HStack spacing="8px">
                                <Avatar name={`${user?.firstName} ${user?.lastName}`} />
                                <VStack
                                    display={{ base: 'none', md: 'flex' }}
                                    alignItems="flex-start"
                                    spacing="0px"
                                >
                                    <Text fontSize="xs" fontWeight="700">
                                        {`${user?.firstName} ${user?.lastName}` ?? ''}
                                    </Text>
                                    <Text fontSize="xs">
                                        {user?.roleDisplayname ?? ''}
                                    </Text>
                                </VStack>
                                <Box
                                    borderRadius="50%"
                                    border="1px solid"
                                    borderColor="gray.400"
                                    p="1px"
                                    display={{ base: 'none', md: 'flex' }}
                                >
                                    <FiChevronDown />
                                </Box>
                            </HStack>
                        </MenuButton>
                        <MenuList
                            w="14rem"
                            borderColor={useColorModeValue('gray.200', 'gray.700')}
                        >
                            <MenuItem
                                onClick={() => {
                                    navigate(PathNames.USER_SETTINGS_PERSONAL_DATA);
                                }}
                            >
                                User settings
                            </MenuItem>
                            {isTeamAdmin && (
                                <MenuItem
                                    onClick={() => {
                                        navigate(PathNames.USER_SETTINGS_TEAM_MEMBERS);
                                    }}
                                >
                                    Team settings
                                </MenuItem>
                            )}
                            <MenuDivider />
                            <MenuItem onClick={() => redirectToLogout()}>
                                Log out
                            </MenuItem>
                        </MenuList>
                    </Menu>
                </Flex>
            </HStack>
        </Flex>
    );
};

export default Header;
