import { Flex, FormControl, FormErrorMessage, IconButton } from '@chakra-ui/react'
import { getProperty } from 'dot-prop'
import { Controller, FieldError, FieldValues, useFormContext } from "react-hook-form"
import { GrTextAlignCenter, GrTextAlignLeft, GrTextAlignRight } from 'react-icons/gr'
import ReactSelect from 'react-select'
import ColorPickerInput from "../inputs/ColorPickerInput"
import FormPropertyRow from "../layout/FormPropertyRow"
import FormPropertyTitle from "../layout/FormPropertyTitle"
import FormTitle from "../layout/FormTitle"
import { fonts } from 'src/fonts'
import { useId } from 'react'
import { StyledSelect } from '@tasklogy/zircon-ui-components'

const FONTS_HELMET = Object.keys(fonts).map((font: string) => ({ value: fonts[font as keyof typeof fonts].name, label: fonts[font as keyof typeof fonts].name }))

const FONTS = [
    ...FONTS_HELMET,
    { value: 'Arial', label: 'Arial' }
]

const FONTS_WEIGHTS = [
    { value: 100, label: 'Thin' },
    { value: 200, label: 'Extra Light' },
    { value: 300, label: 'Light' },
    { value: 400, label: 'Normal' },
    { value: 500, label: 'Medium' },
    { value: 600, label: 'Semi Bold' },
    { value: 700, label: 'Bold' },
    { value: 800, label: 'Extra Bold' },
]

const FONT_SIZES = [
    { value: '12px', label: '12px' },
    { value: '14px', label: '14px' },
    { value: '16px', label: '16px' },
    { value: '18px', label: '18px' },
    { value: '20px', label: '20px' },
    { value: '24px', label: '24px' },
    { value: '32px', label: '32px' },
    { value: '48px', label: '48px' },
    { value: '64px', label: '64px' },
    { value: '72px', label: '72px' },
]

const TextForm = <T extends FieldValues>({ objPath, withTextAlign = true }: { objPath: string, withTextAlign?: boolean }) => {
    const methods = useFormContext<T>();
    const { errors } = methods.formState;
    const id = useId();

    return <Flex flexDir='column'>
        <Flex my={4}>
            <FormTitle title="Text" />
        </Flex>
        <FormPropertyRow>
            <Flex gap={'1rem'} alignItems={'center'}>
                <FormPropertyTitle title='Fill' />
                <Controller
                    name={`${objPath}.color`}
                    defaultValue={'black'}
                    render={({ field }) => (
                        <ColorPickerInput id={id} {...field} error={getProperty(errors, `${objPath}.color`) as FieldError | undefined} width='150px' type="text" />
                    )}
                />
            </Flex>
        </FormPropertyRow>
        <FormPropertyRow>
            <Controller
                name={`${objPath}.fontFamily`}
                defaultValue={FONTS[0].value}
                render={({ field }) => (
                    <FormControl isInvalid={Boolean((getProperty(errors, `${objPath}.fontFamily`) as FieldError | undefined)?.message)}>
                        <StyledSelect
                            variant='editor'
                            value={FONTS.find(font => font.value === field.value)}
                            options={FONTS}
                            onChange={(option: any) => field.onChange(option.value)}
                        />
                        <FormErrorMessage>{(getProperty(errors, `${objPath}.fontFamily`) as FieldError | undefined)?.message}</FormErrorMessage>
                    </FormControl>
                )}
            />
        </FormPropertyRow>
        <FormPropertyRow>
            <Flex alignItems={'center'} justifyContent='space-between' width='100%'>
                <Controller
                    name={`${objPath}.fontSize`}
                    defaultValue={FONT_SIZES[0].value}
                    render={({ field }) => (
                        <FormControl style={{ width: '150px' }} isInvalid={Boolean((getProperty(errors, `${objPath}.fontSize`) as FieldError | undefined)?.message)}>
                            <StyledSelect
                                variant='editor'
                                value={FONT_SIZES.find(font => font.value === field.value)}
                                options={FONT_SIZES}
                                onChange={(option: any) => field.onChange(option.value)}
                            />
                            <FormErrorMessage>{(getProperty(errors, `${objPath}.fontSize`) as FieldError | undefined)?.message}</FormErrorMessage>
                        </FormControl>
                    )}
                />
                <Controller
                    name={`${objPath}.fontWeight`}
                    render={({ field }) => (
                        <FormControl style={{ width: '150px' }} isInvalid={Boolean((getProperty(errors, `${objPath}.fontWeight`) as FieldError | undefined)?.message)}>
                            <StyledSelect
                                variant='editor'
                                value={FONTS_WEIGHTS.find(font => font.value === field.value)}
                                options={FONTS_WEIGHTS}
                                onChange={(option: any) => field.onChange(option.value)}
                            />
                            <FormErrorMessage>{(getProperty(errors, `${objPath}.fontWeight`) as FieldError | undefined)?.message}</FormErrorMessage>
                        </FormControl>
                    )}
                />
            </Flex>
        </FormPropertyRow>
        {withTextAlign && <FormPropertyRow>
            <FormPropertyTitle title='Text Align' />
            <Flex>
                <Controller
                    name={`${objPath}.textAlign`}
                    defaultValue={'left'}
                    render={({ field }) => (
                        <IconButton variant='ghost' backgroundColor={field.value !== 'center' && field.value !== 'right' ? 'gray.200' : 'transparent'} onClick={() => field.onChange({
                            target: { value: 'left' }
                        })} aria-label="Align Left" icon={<GrTextAlignLeft />} />
                    )}
                />
                <Controller
                    name={`${objPath}.textAlign`}
                    defaultValue={'center'}
                    render={({ field }) => (
                        <IconButton variant='ghost' backgroundColor={field.value === 'center' ? 'gray.200' : 'transparent'} onClick={() => field.onChange({
                            target: { value: 'center' }
                        })} aria-label="Align Center" icon={<GrTextAlignCenter />} />
                    )}
                />
                <Controller
                    name={`${objPath}.textAlign`}
                    defaultValue={'right'}
                    render={({ field }) => (
                        <IconButton variant='ghost' backgroundColor={field.value === 'right' ? 'gray.200' : 'transparent'} onClick={() => field.onChange({
                            target: { value: 'right' }
                        })} aria-label="Align Right" icon={<GrTextAlignRight />} />
                    )}
                />
            </Flex>
        </FormPropertyRow>}

    </Flex>
}

export default TextForm;