import {
    Box,
    Button,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Grid,
    GridItem,
    Input,
    Text
} from '@chakra-ui/react';
import { teamValidator } from 'common/validators';
import { Controller, useForm } from 'react-hook-form';
import { getUserInfo } from '@auth';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useGetTeamQuery, useUpdateTeamMutation } from '@api';
import { toast } from 'react-toastify';
import { HappyModal, StyledSelect } from '@tasklogy/zircon-ui-components';
import { CountryCodes } from 'common/enums';
import { useModal } from 'src/hooks/useModal';

type FormData = z.infer<typeof teamValidator.update>;

const countryOptions = [
    { value: CountryCodes.CZ, label: 'Czech Republic' },
    { value: CountryCodes.SK, label: 'Slovakia' }
];

export const CompanyData = () => {
    const userInfo = getUserInfo();
    const { open, close, isOpen } = useModal();
    const [updateTeam, { isLoading: isUpdating }] = useUpdateTeamMutation();
    const { data } = useGetTeamQuery(
        {
            teamId: userInfo?.teamId as number
        },
        {
            skip: !userInfo?.teamId
        }
    );
    const {
        register,
        handleSubmit,
        control,
        formState: { errors }
    } = useForm<FormData>({
        resolver: zodResolver(teamValidator.update),
        values: {
            id: userInfo?.teamId as number,
            businessName: data?.businessName,
            street: data?.street,
            streetNumber: data?.streetNumber,
            city: data?.city,
            zipCode: data?.zipCode,
            ico: data?.ico,
            dic: data?.dic,
            countryCode: data?.countryCode
        },
        mode: 'onBlur'
    });

    const isInvalid = Object.keys(errors).length > 0;

    const handleSubmit_ = handleSubmit(async (values) => {
        try {
            await updateTeam(values).unwrap();
            toast.success('Company data has been updated');
            close();
        } catch (error) {
            toast.error('Error while updating company data');
            console.error(error);
        }
    });

    return (
        <div>
            <Text variant="title" mb="40px">
                Company data
            </Text>
            <Box>
                <form>
                    <Box>
                        <Grid
                            w="50%"
                            templateColumns="repeat(8, 1fr)"
                            rowGap="40px"
                            columnGap="30px"
                            mb="40px"
                        >
                            <GridItem colSpan={5}>
                                <FormControl color="#525A66">
                                    <FormLabel>Business name</FormLabel>
                                    <Input
                                        type="text"
                                        placeholder=""
                                        {...register('businessName')}
                                    />
                                </FormControl>
                            </GridItem>

                            <GridItem colSpan={5}>
                                <FormControl color="#525A66">
                                    <FormLabel>Street</FormLabel>
                                    <Input
                                        type="email"
                                        placeholder=""
                                        {...register('street')}
                                    />
                                </FormControl>
                            </GridItem>
                            <GridItem colSpan={3}>
                                <FormControl color="#525A66">
                                    <FormLabel>House number</FormLabel>
                                    <Input
                                        type="text"
                                        placeholder=""
                                        {...register('streetNumber')}
                                    />
                                </FormControl>
                            </GridItem>
                            <GridItem colSpan={5}>
                                <FormControl color="#525A66">
                                    <FormLabel>City</FormLabel>
                                    <Input
                                        type="email"
                                        placeholder=""
                                        {...register('city')}
                                    />
                                </FormControl>
                            </GridItem>
                            <GridItem colSpan={3}>
                                <FormControl
                                    color="#525A66"
                                    isInvalid={Boolean(errors.zipCode)}
                                >
                                    <FormLabel>ZIP code</FormLabel>
                                    <Input
                                        type="text"
                                        placeholder=""
                                        {...register('zipCode')}
                                    />
                                    <FormErrorMessage>
                                        {errors?.zipCode?.message}
                                    </FormErrorMessage>
                                </FormControl>
                            </GridItem>
                            <GridItem colSpan={3}>
                                <FormControl
                                    color="#525A66"
                                    isInvalid={Boolean(errors.zipCode)}
                                >
                                    <FormLabel>Country</FormLabel>
                                    <Controller
                                        control={control}
                                        name="countryCode"
                                        render={({ field }) => (
                                            <StyledSelect
                                                placeholder="Select country"
                                                options={countryOptions}
                                                value={countryOptions.find(
                                                    (option) =>
                                                        option.value === field.value
                                                )}
                                                onChange={(option: any) => {
                                                    field.onChange(option.value);
                                                }}
                                            />
                                        )}
                                    />

                                    <FormErrorMessage>
                                        {errors?.zipCode?.message}
                                    </FormErrorMessage>
                                </FormControl>
                            </GridItem>
                            <GridItem colSpan={5} />
                            <GridItem colSpan={3}>
                                <FormControl
                                    color="#525A66"
                                    isInvalid={Boolean(errors.ico)}
                                >
                                    <FormLabel>IČO</FormLabel>
                                    <Input
                                        type="text"
                                        placeholder=""
                                        {...register('ico')}
                                    />
                                    <FormErrorMessage>
                                        {errors?.ico?.message}
                                    </FormErrorMessage>
                                </FormControl>
                            </GridItem>
                            <GridItem colSpan={3}>
                                <FormControl
                                    color="#525A66"
                                    isInvalid={Boolean(errors.dic)}
                                >
                                    <FormLabel>DIČ</FormLabel>
                                    <Input
                                        type="text"
                                        placeholder=""
                                        {...register('dic')}
                                    />
                                    <FormErrorMessage>
                                        {errors?.dic?.message}
                                    </FormErrorMessage>
                                </FormControl>
                            </GridItem>
                        </Grid>

                        <Flex justifyContent="start" mt="3rem" gap="20px">
                            <Button variant="solid" isDisabled={isInvalid} onClick={open}>
                                Save changes
                            </Button>
                        </Flex>
                        <HappyModal isOpen={isOpen} onClose={close}>
                            <HappyModal.Title>Attention</HappyModal.Title>
                            <HappyModal.Body>
                                Are you sure you want to save the changes?
                            </HappyModal.Body>
                            <HappyModal.Footer>
                                <Button variant="outline" onClick={() => close()}>
                                    No
                                </Button>
                                <Button
                                    variant="solid"
                                    isLoading={isUpdating}
                                    onClick={handleSubmit_}
                                >
                                    Yes
                                </Button>
                            </HappyModal.Footer>
                        </HappyModal>
                    </Box>
                </form>
            </Box>
        </div>
    );
};
