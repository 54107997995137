import { ScopePermissions } from '../router/types';
import { getUserInfo } from './getUserInfo';
import { hasScopePermissions } from './hasScopePermissions';

interface AuthorizedWithScopePermissionsProps {
    children: React.ReactNode;
    scopePermissions: ScopePermissions;
    display?: boolean;
}

const AuthorizedWithScopePermissions: React.FC<AuthorizedWithScopePermissionsProps> = ({
    children,
    scopePermissions,
    display = true
}) => {
    const user = getUserInfo();
    const hasPermissions = hasScopePermissions(scopePermissions);
    if (!user || !hasPermissions) {
        return (
            <div style={{ visibility: 'hidden', display: display ? 'none' : 'unset' }}>
                {children}
            </div>
        );
    }
    return <>{children}</>;
};

export default AuthorizedWithScopePermissions;
