import { hasRoles, hasScopePermissions } from '@auth';
import { Flex } from '@chakra-ui/react';
import { CollapseSidebar } from '@tasklogy/zircon-ui-components';
import { matchPath, Outlet, useLocation } from 'react-router-dom';
import privateRoutes from 'src/router/private';
import { PathNames } from '../router/router';
import { Route } from '../router/types';
import Header from './components/Header';
import { DASHBOARD_PATH, DASHBOARD_REPORTS_PATH } from '@constants';

const Blueprint = () => {
    const location = useLocation();

    const appPrivateRoutes = privateRoutes();
    const withScopePermissionsRoutes = appPrivateRoutes[0]?.children?.filter(
        (route: Route) => {
            const authPermissions = [];
            if (route.handle?.auth?.withScopePermissions) {
                authPermissions.push(
                    hasScopePermissions(route.handle.auth.withScopePermissions)
                );
            }
            if (route.handle?.auth?.hasRoles) {
                authPermissions.push(hasRoles(route.handle.auth.hasRoles));
            }

            if (authPermissions.length === 0) {
                return false;
            }

            return authPermissions.some(Boolean);
        }
    );

    const privateNavRoutes = appPrivateRoutes;
    privateNavRoutes[0].children = withScopePermissionsRoutes;

    return (
        <Flex flexDirection="row">
            <CollapseSidebar
                isCollapsed={
                    location.pathname.includes(PathNames.USER_SETTINGS) ||
                    location.pathname.includes(
                        `${DASHBOARD_PATH}/${DASHBOARD_REPORTS_PATH}/`
                    ) ||
                    Boolean(matchPath(PathNames.CLIENT_CREATE_REPORT_SIMPLE, location.pathname))
                    ||
                    Boolean(matchPath(PathNames.CLIENT_CREATE_REPORT_SIMPLE_TEMPLATE_REPORT_VIEW, location.pathname))
                }
                routes={privateNavRoutes as any}
            />
            <Flex flex="1" flexDirection="column" overflow="auto">
                <Header />
                <Outlet />
            </Flex>
        </Flex>
    );
};

export default Blueprint;
