import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const waiting = defineStyle({
    background: '#EFC325',
    color: '#fff',
    fontSize: '13px',
    fontWeight: '400',
    padding: '8px 10px',
    textTransform: 'normal',
    borderRadius: '10px',
    boxShadow: '1px 2px 3px #4676fb78'
});

const success = defineStyle({
    background: '#1BC30C',
    color: '#fff',
    fontSize: '13px',
    fontWeight: '400',
    padding: '8px 10px',
    textTransform: 'normal',
    borderRadius: '10px',
    boxShadow: '1px 2px 3px #4676fb78'
});

const error = defineStyle({
    background: '#F13939',
    color: '#fff',
    fontSize: '13px',
    fontWeight: '400',
    padding: '8px 10px',
    textTransform: 'normal',
    borderRadius: '10px',
    boxShadow: '1px 2px 3px #4676fb78'
});

const successTable = defineStyle({
    background: '#1BC30C',
    color: '#fff',
    fontSize: '13px',
    fontWeight: '400',
    padding: '8px 10px',
    textTransform: 'normal',
    borderRadius: '10px',
    boxShadow: '1px 2px 3px #4676fb78'
});

const brand = defineStyle(({ colorScheme: c }) => {
    return {
        background: `${c}.50`,
        color: `${c}.100`,
        fontSize: '13px',
        fontWeight: '400',
        padding: '8px 10px',
        textTransform: 'normal',
        borderRadius: '10px',
        boxShadow: '1px 2px 3px #4676fb78'
    };
});

const xs = defineStyle({
    fontSize: '7px'
});

const sm = defineStyle({
    fontSize: '8px'
});

const md = defineStyle({
    fontSize: '9px'
});

const lg = defineStyle({
    fontSize: '12px'
});

const xl = defineStyle({
    fontSize: '13px'
});

const sizes = {
    xs,
    sm,
    md,
    lg,
    xl
};

export const badgeTheme = defineStyleConfig({
    variants: { waiting, success, successTable, error, brand },
    sizes,
    defaultProps: {
        size: 'md',
        variant: 'success',
        colorScheme: 'default'
    }
});
