const defaultSingleValue = {
    properties: {
        label: {
            color: 'rgb(112, 126, 174)',
            fontSize: '16px',
            lineHeight: '20px'
        },
        style: {
            gap: 0,
            display: 'flex',
            padding: '0.875rem 0.75rem 0.875rem 0.75rem',
            borderRadius: '20px',
            flexDirection: 'column',
            paddingInline: '1.25rem',
            backgroundColor: 'white'
        },
        value: {
            color: 'rgb(43, 54, 116)',
            fontSize: '20px',
            marginTop: '0.25rem',
            fontWeight: '400',
            lineHeight: '36px',
            whiteSpace: 'nowrap'
        }
    }
};
export default defaultSingleValue;
