import defaultTable from './table.js';
import defaultDonutChart from './donut_chart.js';
import defaultMultiSelect from './multi_select.js';
import defaultSingleValue from './single_value.js';
import defaultDateRange from './date_range.js';
import defaultPieChart from './pie_chart.js';
import defaultLineChart from './line_chart.js';
import defaultAreaChart from './area_chart.js';
import { ComponentType } from '../../enums.js';
import defaultRectangle from './rectangle.js';
import defaultText from './text.js';
import defaultNavigationButton from './navigation_button.js';
import defaultImage from './image.js';
const defaultTheme = {
    components: {
        [ComponentType.TABLE]: defaultTable,
        [ComponentType.DONUT_CHART]: defaultDonutChart,
        [ComponentType.MULTI_SELECT]: defaultMultiSelect,
        [ComponentType.SINGLE_VALUE]: defaultSingleValue,
        [ComponentType.DATE_RANGE]: defaultDateRange,
        [ComponentType.RECTANGLE]: defaultRectangle,
        [ComponentType.TEXT]: defaultText,
        [ComponentType.NAVIGATION_BUTTON]: defaultNavigationButton,
        [ComponentType.PIE_CHART]: defaultPieChart,
        [ComponentType.LINE_CHART]: defaultLineChart,
        [ComponentType.AREA_CHART]: defaultAreaChart,
        [ComponentType.IMAGE]: defaultImage
    }
};
export default defaultTheme;
