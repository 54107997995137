const defaultAreaChart = {
    properties: {
        style: {
            width: '100%',
            height: '100%',
            padding: '1rem',
            boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
            borderRadius: '20px',
            backgroundColor: 'white'
        },
        component: {
            tooltip: {
                style: {
                    padding: '0.5rem',
                    borderRadius: 5,
                    backgroundColor: 'white'
                },
                textStyle: {
                    color: 'black',
                    fontWeight: 'bolder',
                    textTransform: 'uppercase'
                }
            },
            embedded: {
                title: null
            },
            formatType: 'PERCENTAGE',
            cartesianGrid: {
                stroke: '#E7E7E7',
                opacity: 1,
                strokeDasharray: '3 3'
            },
            linearGradients: [
                {
                    x1: '3',
                    x2: '0',
                    y1: '3',
                    y2: '0',
                    stops: [
                        {
                            offset: '0%',
                            stopColor: 'rgb(166,36,117)',
                            stopOpacity: 1
                        },
                        {
                            offset: '50%',
                            stopColor: 'rgba(199,66,149,1)',
                            stopOpacity: 1
                        },
                        {
                            offset: '100%',
                            stopColor: 'rgba(233,133,195,1)',
                            stopOpacity: 0
                        }
                    ],
                    legendSymbol: 'square',
                    legendSymbolFillColor: 'rgb(166,36,117)',
                    legendSymbolTextColor: 'white'
                },
                {
                    x1: '1',
                    x2: '0',
                    y1: '0',
                    y2: '3',
                    stops: [
                        {
                            offset: '0%',
                            stopColor: 'rgb(252,187,91)',
                            stopOpacity: 1
                        },
                        {
                            offset: '50%',
                            stopColor: 'rgba(52,122,131,1)',
                            stopOpacity: 1
                        },
                        {
                            offset: '100%',
                            stopColor: 'rgba(142,201,208,1)',
                            stopOpacity: 0
                        }
                    ],
                    legendSymbolFillColor: '#39D7EC',
                    legendSymbolTextColor: 'white'
                },
                {
                    x1: '3',
                    x2: '0',
                    y1: '0',
                    y2: '0',
                    stops: [
                        {
                            offset: '0%',
                            stopColor: 'rgba(252,187,91, 1)',
                            stopOpacity: 1
                        },
                        {
                            offset: '50%',
                            stopColor: 'rgba(52,122,131,1)',
                            stopOpacity: 1
                        },
                        {
                            offset: '100%',
                            stopColor: 'rgba(142,201,208,1)',
                            stopOpacity: 0
                        }
                    ],
                    legendSymbolFillColor: '#FCBB5B',
                    legendSymbolTextColor: 'white'
                }
            ]
        }
    }
};
export default defaultAreaChart;
